import { useReducer, useCallback } from 'react';

const initialState = {
    isEditLanguageModalOpen: false,
    isNavbarExtended: false,
    isEditUserModalOpen: false
};

type StateType = typeof initialState;
type StateKey = keyof StateType;

const useNavbarReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const toggle = useCallback((action: StateKey) => dispatch(action), []);

    return { ...state, toggle };
};

const reducer = (state: StateType, action: StateKey) => {
    return { ...state, [action]: !state[action] };
};

export { useNavbarReducer };
