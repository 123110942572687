import { BUILDING_HEIGHT, REGIONI_LAYER, REGION_NAMES, SELECTED_FEATURE_LAYER } from 'helpers';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { featuresActions, layerActions, popupInfoActions } from 'store';
import * as turf from '@turf/turf';
import { useNavigate } from 'react-router-dom';

export const SELECTED_LAYER_LINECOLOR = [69, 209, 255, 255];
export const SELECTED_OSM_BUILDING_LINECOLOR = [255, 255, 100, 255];
export const SELECTED_LAYER_COLOR = [69, 209, 255, 130];
export const SELECTED_OSM_BUILDING_COLOR = [255, 255, 100, 255];

const LAYERS_NO_HIGHLIGHT = [REGIONI_LAYER, ...REGION_NAMES];

const useOnClickLayerHandler = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handlePowerPlantRouting = useCallback(
        (info: any) => {
            if (!!info.osm_id && info.layerName === 'power_plant') navigate(`/power-plant/${info.osm_id}`);
            else navigate('/');
        },
        [navigate]
    );

    const onClickHandler = useCallback(
        (event: any) => {
            const feature = event.object;
            if (!feature) return;
            const isHeightLayer = event.layer.props.id === BUILDING_HEIGHT;
            const collection = { type: 'FeatureCollection', features: [feature] };
            dispatch(layerActions.deleteLayer(SELECTED_FEATURE_LAYER));
            if (!LAYERS_NO_HIGHLIGHT.includes(event.layer.id))
                dispatch(
                    layerActions.addGeoJsonLayer({
                        id: SELECTED_FEATURE_LAYER,
                        data: collection,
                        getLineColor: isHeightLayer ? SELECTED_OSM_BUILDING_LINECOLOR : SELECTED_LAYER_LINECOLOR,
                        extruded: true,
                        getElevation: (p: any) => p?.properties?.height,
                        getFillColor: isHeightLayer ? SELECTED_OSM_BUILDING_COLOR : SELECTED_LAYER_COLOR,
                        getPointRadius: event.layer.props.getPointRadius ?? 1,
                        tab: event.layer.props.tab
                    })
                );

            dispatch(featuresActions.setClickedFeature(feature.id));
            dispatch(popupInfoActions.updatePopupInfo(event));
            if (event.zoom)
                dispatch(layerActions.zoomDispatch({ bbox: turf.bbox(feature), tab: event.layer.props.tab, zoomOffset: 10 }));
            handlePowerPlantRouting(feature.properties);
        },
        [dispatch, handlePowerPlantRouting]
    );
    return onClickHandler;
};

export { useOnClickLayerHandler };

