export const NotifyNewArea = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="notifica_nuova_area" transform="translate(-174.543 -658)">
                <rect
                    id="Rectangle_1688"
                    data-name="Rectangle 1688"
                    width="16"
                    height="16"
                    rx="6"
                    transform="translate(174.543 658)"
                    fill="#008753"
                />
                <path
                    id="marker_full"
                    d="M4.13,0A4.135,4.135,0,0,0,0,4.13C0,7.2,3.734,11.038,3.893,11.2a.333.333,0,0,0,.474,0c.159-.162,3.893-4,3.893-7.07A4.135,4.135,0,0,0,4.13,0m0,5.652A1.521,1.521,0,1,1,5.652,4.13,1.523,1.523,0,0,1,4.13,5.652"
                    transform="translate(178.413 660.35)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};
