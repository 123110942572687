import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Download: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="download" xmlns="http://www.w3.org/2000/svg" width="27.1" height="22.178" viewBox="0 0 27.1 22.178">
            <path
                id="Path_250"
                data-name="Path 250"
                d="M915.511,194.262h-.019a6.156,6.156,0,0,0-3.886-5.056,8.678,8.678,0,0,0-8.62-8.295A8.574,8.574,0,0,0,894.8,186.8h-.018a6.216,6.216,0,0,0-4.164,1.608,6.566,6.566,0,0,0-2.172,4.872v2.171a2.718,2.718,0,0,0,2.715,2.715h7.1v-1.495h-7.1a1.222,1.222,0,0,1-1.22-1.22v-2.171a5.055,5.055,0,0,1,1.676-3.758,4.65,4.65,0,0,1,3.645-1.2.727.727,0,0,0,.812-.559,7.132,7.132,0,0,1,14.039,1.8.6.6,0,0,1,0,.084.748.748,0,0,0,.543.814,4.667,4.667,0,0,1,3.4,4.489v.5a1.222,1.222,0,0,1-1.221,1.22h-7.1v1.495h7.1a2.718,2.718,0,0,0,2.715-2.715v-.985Z"
                transform="translate(-888.45 -180.911)"
                fill={fill}
            />
            <path
                id="Path_251"
                data-name="Path 251"
                d="M902.75,200.946v-8.654h-1.5v8.654l-1.959-1.3-.828,1.243,3.119,2.077a.734.734,0,0,0,.415.127.743.743,0,0,0,.415-.126l3.122-2.078-.829-1.243Z"
                transform="translate(-888.45 -180.911)"
                fill={fill}
            />
        </svg>
    );
};
