import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerConverter: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="powerConverter"
                d="M6.75,0A6.75,6.75,0,1,0,13.5,6.75,6.76,6.76,0,0,0,6.75,0ZM1.5,6.75A5.262,5.262,0,0,1,6.75,1.5,5.18,5.18,0,0,1,9.88,2.56L2.56,9.88A5.18,5.18,0,0,1,1.5,6.75ZM6.75,12a5.18,5.18,0,0,1-3.13-1.06l7.32-7.32A5.18,5.18,0,0,1,12,6.75,5.262,5.262,0,0,1,6.75,12Z"
                fill={fill}
            />
        </svg>
    );
};
