import { createContext, FC, PropsWithChildren } from 'react';
import { env } from 'environments';
import axios from 'axios';

interface PhotonApi {
    getSearchedValue: (value: string) => Promise<any>;
    reverseGeocoding: (coordinates: any) => Promise<any>;
    reverseGeocodingPhoton: (coordinates: any) => Promise<any>;
}

const PhotonContext = createContext<PhotonApi>({} as PhotonApi);

const PhotonProvider: FC<PropsWithChildren> = ({ children }) => {
    const getSearchedValue = async (q: string) => {
        const res = await axios.get(`${env.photonApiUrl}/api/`, { params: { q } }).catch(err => console.log(err));
        return res?.data;
    };

    const reverseGeocoding = async (coords: any) => {
        const res = await axios
            .get(`${env.photonApiUrl}/reverse`, {
                params: { lon: +coords[0], lat: +coords[1], radius: 20, limit: 20 }
            })
            .catch(err => console.log(err));
        return res?.data;
    };

    const reverseGeocodingPhoton = async (coords: any) => {
        const res = await axios
            .get(`${env.photonApiUrl}/reverse`, { params: { lon: +coords[0], lat: +coords[1], radius: 20, limit: 20 } })
            .catch(err => console.log(err));
        return res?.data;
    };

    return (
        <PhotonContext.Provider value={{ getSearchedValue, reverseGeocoding, reverseGeocodingPhoton }}>
            {children}
        </PhotonContext.Provider>
    );
};

export { PhotonContext, PhotonProvider };
