import { CompositeLayer, GeoJsonLayer, IconLayer, Layer, UpdateParameters } from 'deck.gl/typed';
import Supercluster from 'supercluster';
import { getGeometryAnchor, setInitialColor, isBuilding, SUPERCLUSTER_BBOX } from 'helpers';

export function getIconName(size: any) {
    if (size === 0) {
        return '';
    }
    if (size <= 5) {
        return `marker-${size}`;
    }

    switch (true) {
        case size < 10:
            return `marker-5`;
        case size < 20:
            return `marker-10`;
        case size < 50:
            return `marker-20`;
        case size < 100:
            return `marker-50`;
        case size < 200:
            return `marker-100`;
        case size < 500:
            return `marker-200`;
        case size < 1000:
            return `marker-500`;
        case size < 2000:
            return `marker-1000`;
        case size < 5000:
            return `marker-2000`;
        case size < 10000:
            return `marker-5000`;
        case size < 20000:
            return `marker-10000`;
        default:
            return 'marker-20000';
    }
}

export function getIconSize(size: any) {
    return Math.min(100, size) / 100 + 1;
}

export function getColorProp(d: any, checked: boolean, colorProp: number[]) {
    if (checked) return setInitialColor(d, colorProp);
    else if (isBuilding(d)) return [...colorProp, 250];
    else if (d.geometry.type.includes('Polygon')) return [...colorProp, 100];
    else return colorProp;
}
export class IconCLusterLayer extends CompositeLayer<any> {
    index: any;
    newFeatures;
    minZoom = 11.5;
    zoomThreshold = 16;
    hasBuildings;
    clusterizedFeatures: any[] = [];
    freeFeatures: any[] = [];
    lastZoom = 0;

    constructor(data: any) {
        super(data);
        this.index = new Supercluster();
        this.newFeatures = this.props.data.features.map((feature: any) => {
            const { geometry }: any = getGeometryAnchor(feature);
            return { ...feature, geometry, originalGeometry: feature.geometry };
        });
        this.index?.load(this.newFeatures);
        this.hasBuildings = this.props.data.features.some(
            (feature: any) => feature.properties?.height || feature.properties?.ALT
        );
    }

    shouldUpdateState(params: UpdateParameters<Layer<any>>): boolean {
        if (!params.changeFlags.somethingChanged) return false;
        const shouldUpdate =
            params.changeFlags.propsOrDataChanged ||
            (params.changeFlags.viewportChanged && this.context.viewport.zoom !== this.lastZoom);
        this.lastZoom = this.context.viewport.zoom;
        return shouldUpdate;
    }

    updateState({ props, oldProps, changeFlags }: any) {
        const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;
        const z = Math.floor(this.context.viewport.zoom);
        if (rebuildIndex || z !== this.state.z) {
            this.setState({
                data: this.index.getClusters(SUPERCLUSTER_BBOX, z),
                z
            });
        }
    }

    renderLayers(): any {
        const { data } = this.state;
        const { iconAtlas, iconMapping, sizeScale, id, onClick, pickable, getPointRadius, getInitialColor } = this.props;

        this.clusterizedFeatures = [];
        this.freeFeatures = [];

        data.forEach((feature: any) => {
            if (
                this.context.viewport.zoom < this.minZoom ||
                (this.context.viewport.zoom < this.zoomThreshold && feature.properties.cluster)
            )
                this.clusterizedFeatures.push(feature);
            else this.freeFeatures.push(feature);
        });

        this.freeFeatures.forEach((feature, index) => {
            const newFeature = JSON.parse(JSON.stringify(feature));
            newFeature.geometry = newFeature.originalGeometry;
            this.freeFeatures[index] = newFeature;
        });

        const clusterLayer = new IconLayer(
            this.getSubLayerProps({
                id: 'icon-cluster-layer' + id,
                data: this.clusterizedFeatures,
                iconAtlas,
                iconMapping,
                sizeScale,
                onClick: onClick,
                getPosition: (d: any) => {
                    const geometry = d.geometry.type === 'Point' ? d.geometry : getGeometryAnchor(d).geometry;
                    return geometry.coordinates;
                },
                getIcon: (d: any) => getIconName(d.properties.cluster ? d.properties.point_count : 1),
                getSize: (d: any) => getIconSize(d.properties.cluster ? d.properties.point_count : 1)
            })
        );
        const freeLayer = new GeoJsonLayer({
            id: 'geojson-icon-layer' + id,
            data: this.context.viewport.zoom > this.zoomThreshold ? this.props.data : this.freeFeatures,
            sizeScale,
            pickable,
            onClick,
            wireframe: this.hasBuildings,
            extruded: this.hasBuildings,
            getElevation: (p: any) => {
                return this.hasBuildings ? p.properties?.height || p.properties?.ALT : 0;
            },
            getPosition: (d: any) => d.geometry.coordinates,
            lineWidthScale: 0.2 * Math.pow(1, this.props.getPointRadius),
            pointRadiusScale: 1 * Math.pow(1, this.context.viewport.zoom - this.zoomThreshold),
            getPointRadius,
            getLineColor: (d: any) =>
                this.props.color !== getInitialColor ? this.props.color : setInitialColor(d, getInitialColor),
            getFillColor: (d: any) => getColorProp(d, this.props.checked, this.props.color),
            updateTriggers: {
                getLineColor: [this.props.color, getInitialColor],
                getFillColor: [this.props.color, this.props.checked]
            }
        });
        return [clusterLayer, freeLayer];
    }
}
