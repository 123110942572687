import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerLine: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4">
            <rect id="powerLine" width="12" height="4" fill={fill} />
        </svg>
    );
};
