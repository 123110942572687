import { FC, PropsWithChildren, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import classes from './Modal.module.scss';
import { IconButton } from 'components/buttons';
import { Close } from 'components/icons';
import colors from 'styles/modules/colors.module.scss';

type ModalProps = FC<
    PropsWithChildren<{
        hasHeader?: boolean;
        customHeaderButtons?: ReactElement[];
        customClasses?: string;
        handleClose: (...data: any) => any;
        isOpen: boolean;
        title?: string;
        icon?: any;
        scrollable?: boolean;
        reduced?: boolean;
    }>
>;

const Modal: ModalProps = ({
    icon,
    children,
    isOpen,
    handleClose,
    title,
    customClasses,
    hasHeader = true,
    customHeaderButtons = [],
    reduced,
    scrollable = true
}) => {
    if (!isOpen) return <></>;

    return createPortal(
        <div className={`${classes.backdrop} ${reduced ? classes.reduced : ''}`}>
            <div className={hasHeader ? classes.modal : classes.notifyModal}>
                {hasHeader ? (
                    <div className={icon ? classes.iconHeader : classes.header}>
                        <div className={classes.iconTitle}>
                            {icon && <div className={classes.icon}>{icon}</div>}
                            <p>
                                <b>{title}</b>
                            </p>
                        </div>
                        <div className={`${classes.buttons}`}>
                            {customHeaderButtons}
                            <IconButton
                                isTooltipNecessary={false}
                                onClick={handleClose}
                                className="light"
                                icon={<Close fill={colors.grayDark} />}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className={`${scrollable ? classes.scrollable : ''} ${customClasses}`}>{children}</div>
            </div>
        </div>,
        document.getElementById('modal-root') as Element
    );
};

export default Modal;
