import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Share: SvgIconProps = ({ fill = colors.gray100, zoom = 1 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.2" height="24.9" viewBox="0 0 22.2 24.9" style={{ zoom }}>
            <path
                id="share"
                d="M812.421,146.859a4.312,4.312,0,0,0-3.27,1.511l-6.176-3.7a4.243,4.243,0,0,0,0-3.114l6.176-3.7a4.312,4.312,0,0,0,3.27,1.511,4.35,4.35,0,1,0-4.35-4.35,4.261,4.261,0,0,0,.3,1.557l-6.175,3.7a4.35,4.35,0,1,0,0,5.678l6.175,3.7a4.265,4.265,0,0,0-.3,1.557,4.35,4.35,0,1,0,4.35-4.35Zm0,7.2a2.85,2.85,0,1,1,2.85-2.85A2.853,2.853,0,0,1,812.421,154.059Zm0-21.9a2.85,2.85,0,1,1-2.85,2.85A2.854,2.854,0,0,1,812.421,132.159Zm-13.5,13.8a2.85,2.85,0,1,1,2.85-2.85A2.853,2.853,0,0,1,798.921,145.959Z"
                transform="translate(-794.571 -130.659)"
                fill={fill}
            />
        </svg>
    );
};
