import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const RingbellOff: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.006" height="19.932" viewBox="0 0 26.006 19.932">
            <path
                id="ringbell_off"
                d="M1488.479,197.4v-4.886a10.478,10.478,0,0,0-20.956,0V197.4H1465v1.5h9.271a3.8,3.8,0,0,0,7.466,0H1491v-1.5Zm-8.274,1.5a2.326,2.326,0,0,1-4.4,0Zm-2.2-15.365a8.994,8.994,0,0,1,8.984,8.984V197.4l-17.967,0v-4.886A8.994,8.994,0,0,1,1478,183.528Z"
                transform="translate(-1464.997 -182.034)"
                fill={fill}
            />
        </svg>
    );
};
