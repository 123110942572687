import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const StarFull: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.787" height="25.476" viewBox="0 0 26.787 25.476">
            <path
                id="star_full"
                d="M1059.08,140.034h-10.232l-3.161-9.731-3.162,9.731h-10.232l8.278,6.014-3.162,9.731,8.278-6.014,8.277,6.014-3.162-9.731Z"
                transform="translate(-1032.293 -130.303)"
                fill={fill}
            />
        </svg>
    );
};
