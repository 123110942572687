import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const SearchBuilding: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35.42" height="39.203" viewBox="0 0 35.42 39.203">
            <path
                id="searchBuilding"
                d="M28.165,33.008a10.747,10.747,0,1,1,1.061-1.061l6.195,6.194L34.359,39.2ZM12.061,25a9.158,9.158,0,0,0,9.146,9.148h0A9.149,9.149,0,1,0,12.061,25ZM0,28.274v-1.5H4V11.324a.751.751,0,0,1,.751-.751H9V.75A.75.75,0,0,1,10.125.1l10,5.773a.754.754,0,0,1,.375.65v6.424a12.088,12.088,0,0,0-1.5.176V6.956L10.5,2.048v8.525h4.25a.751.751,0,0,1,.75.751v3.027a12.1,12.1,0,0,0-1.5.972V12.074H5.5v14.7H9.275a11.962,11.962,0,0,0,.318,1.5Z"
                transform="translate(0 0)"
                fill={fill}
            />
        </svg>
    );
};
