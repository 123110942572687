import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ErasePolygon: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30.494" height="25.071" viewBox="0 0 30.494 25.071">
            <defs>
                <clipPath id="erasePolygonClipPath">
                    <rect
                        id="Rectangle_764"
                        data-name="Rectangle 764"
                        width="30.494"
                        height="25.071"
                        transform="translate(0 0)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g id="erase_polygon" transform="translate(0 0)">
                <g id="Group_463" data-name="Group 463" clipPath="url(#erasePolygonClipPath)">
                    <path
                        id="Path_1011"
                        data-name="Path 1011"
                        d="M28.108,12.023a3.752,3.752,0,0,0,0-5.3L22.451,1.063a3.841,3.841,0,0,0-5.3,0L1.591,16.619a3.757,3.757,0,0,0,0,5.3L3.24,23.571H0v1.5H28v-1.5H16.559Zm-9.9-9.9a2.3,2.3,0,0,1,3.181,0L27.046,7.78a2.248,2.248,0,0,1,0,3.182l-4.81,4.811L13.4,6.934ZM14.439,23.571H5.361L2.652,20.862a2.254,2.254,0,0,1,0-3.183l9.685-9.685,8.838,8.84Z"
                        fill={fill}
                    />
                    <path
                        id="Path_1012"
                        data-name="Path 1012"
                        d="M29.254,20.235a1.241,1.241,0,1,0,1.24,1.24,1.242,1.242,0,0,0-1.24-1.24"
                        fill={fill}
                    />
                    <path
                        id="Path_1013"
                        data-name="Path 1013"
                        d="M29.254,15.578a1.241,1.241,0,1,0,1.24,1.24,1.242,1.242,0,0,0-1.24-1.24"
                        fill={fill}
                    />
                    <path
                        id="Path_1014"
                        data-name="Path 1014"
                        d="M24.918,19.147a1.24,1.24,0,1,0,1.24-1.24,1.243,1.243,0,0,0-1.24,1.24"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};
