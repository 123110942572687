import { useForm } from 'react-hook-form';
import { FC, memo, useCallback } from 'react';
import classes from './Form.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'helpers';
import Multiselect from 'components/multiselect/Multiselect';

type FormProps = FC<{ fields: FieldProps[]; handleSubmitFunction: (value: any) => void; buttonLabel: string }>;

const Form: FormProps = ({ fields, handleSubmitFunction, buttonLabel }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({ mode: 'onChange' });

    const fieldHandler = useCallback(
        (field: FieldProps) => {
            if (field.options) {
                return (
                    <div className={classes.select}>
                        <select defaultValue={field.value} {...register(field.name, { ...field.validators })}>
                            {field.options.map((currentOption: any) => (
                                <option key={currentOption} value={currentOption}>
                                    {t(currentOption)}
                                </option>
                            ))}
                        </select>
                        <span>
                            <ArrowDropDownIcon />
                        </span>
                    </div>
                );
            } else if (field.multiSelect) {
                return <Multiselect value={field} register={register} />;
            } else {
                return (
                    <input
                        defaultValue={field.value}
                        id={field.name}
                        className={errors[field.name]?.type && 'inputError'}
                        disabled={field.disabled}
                        {...register(field.name, { ...field.validators })}
                    />
                );
            }
        },
        [errors, register, t]
    );

    const fieldGenerator = fields.map((field: FieldProps) => {
        return (
            <div className={classes.field} key={field.name}>
                <label htmlFor={field.name}>{t(('form:' + field.name) as any)}</label>
                {fieldHandler(field)}
                <div className={classes.invalid}>{t(('error:' + errors[field.name]?.type) as any)}</div>
            </div>
        );
    });

    return (
        <form onSubmit={handleSubmit(handleSubmitFunction)} className={classes.form}>
            <div className={classes.fields}>{fieldGenerator}</div>
            <div className={classes.buttons}>
                <button disabled={!isValid} type="submit" className="text primary">
                    {t(buttonLabel as any)}
                </button>
            </div>
        </form>
    );
};

export default memo(Form);
