import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Upload: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.899" height="18.602" viewBox="0 0 23.899 18.602">
            <g id="upload" transform="translate(-1273.729 -133.808)">
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M1284.929,136.12v11.29h1.5V136.12l6.777,5.421.939-1.172-7.995-6.4-.2-.134-.013.015a.751.751,0,0,0-.726.119l-7.995,6.4.938,1.172Z"
                    fill={fill}
                />
                <rect
                    id="Rectangle_20"
                    data-name="Rectangle 20"
                    width="23.899"
                    height="1.5"
                    transform="translate(1273.729 150.91)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
