import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantWind: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="powerPlantWind"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM11.3,6.75,12,4.89V8.62l-.7-1.86ZM11.67,12H9.33L10.5,8.89ZM10.5,4.61,9.33,1.5h2.34ZM7.73,1.5,9.42,6H1.5V1.5H7.73ZM1.5,7.5H9.42L7.73,12H1.5Z"
                fill={fill}
            />
        </svg>
    );
};
