import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'models';
interface FeatureCollectionFound {
    tabId: string;
    featureCollections: FeatureCollection[];
}

export interface ShapeColumns {
    shapeId: string;
    columns: string[];
}

export interface Dragged {
    shapeType: string;
    keyType: string;
}

export interface UploadState {
    prevDragged: Record<string, Dragged[]>;
    dragged: Record<string, Dragged[]>;
    prevSelectedType: Record<string, string>;
    selectedType: Record<string, string>;
    featureCollectionsFoundList: FeatureCollectionFound[];
    shapeColumns: ShapeColumns[];
    selectedShapeColumns: Record<string, string[]>;
    files: any[];
    columnsCounting: number;
    isLeftSidebarOpen: boolean;
    isRightSidebarOpen: boolean;
}

export enum StringType {
    NotCategorized = 'NotCategorized'
}

const name = 'upload';
const initialState: UploadState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const uploadActions = { ...slice.actions };
export const uploadReducer = slice.reducer;

function createInitialState() {
    return {
        prevDragged: {},
        dragged: {},
        selectedType: {},
        prevSelectedType: {},
        featureCollectionsFoundList: [],
        shapeColumns: [],
        selectedShapeColumns: {},
        files: [],
        columnsCounting: 0,
        isLeftSidebarOpen: true,
        isRightSidebarOpen: true
    };
}

function createReducers() {
    return {
        setFeatureCollectionsFound,
        setShapeColumns,
        initSelectedShapeColumns,
        handleSingleSelection,
        handleSelectAll,
        setSelectedType,
        setColumnsCounting,
        setDragged,
        updateDragged,
        setPrevDragged,
        setPrevSelectedType,
        updateShapeColumns,
        clearShapeColumn,
        setIsLeftSidebarOpen,
        setIsRightSidebarOpen
    };

    function setPrevSelectedType(state: UploadState, action: PayloadAction<{ selectedTab: string; selectedType: string }>) {
        state.prevSelectedType[action.payload.selectedTab] = action.payload.selectedType;
    }

    function setPrevDragged(
        state: UploadState,
        action: PayloadAction<{ selectedTab: string; columns: Dragged[]; selectedType: string }>
    ) {
        state.prevDragged[action.payload.selectedTab] = action.payload.columns;
        state.selectedType[action.payload.selectedTab] = action.payload.selectedType;
    }

    function updateDragged(
        state: UploadState,
        action: PayloadAction<{ selectedTab: string; shapeType: string; keyType: string }>
    ) {
        const draggedIndex = state.dragged[action.payload.selectedTab]?.findIndex(
            dragged => dragged.shapeType === action.payload.shapeType
        );
        if (draggedIndex !== -1) {
            state.dragged[action.payload.selectedTab][draggedIndex] = {
                ...state.dragged[action.payload.selectedTab][draggedIndex],
                keyType: action.payload.keyType
            };
        }
    }

    function setDragged(state: UploadState, action: PayloadAction<{ selectedTab: string; dragged: Dragged[] }>) {
        state.dragged[action.payload.selectedTab] = action.payload.dragged;
    }

    function setColumnsCounting(state: UploadState, action: PayloadAction<number>) {
        state.columnsCounting = action.payload;
    }

    function setFeatureCollectionsFound(state: UploadState, action: PayloadAction<any>) {
        state.featureCollectionsFoundList = [...state.featureCollectionsFoundList, action.payload];
    }

    function setShapeColumns(state: UploadState, action: PayloadAction<ShapeColumns>) {
        state.shapeColumns = [...state.shapeColumns, action.payload];
    }

    function initSelectedShapeColumns(state: UploadState, action: PayloadAction<string>) {
        state.selectedShapeColumns[action.payload] = [];
    }

    function handleSingleSelection(state: UploadState, action: PayloadAction<{ selectedTab: string; columnName: string }>) {
        const columnIndex = state.selectedShapeColumns[action.payload.selectedTab].findIndex(
            columnName => columnName === action.payload.columnName
        );
        if (columnIndex === -1) {
            state.selectedShapeColumns[action.payload.selectedTab].push(action.payload.columnName);
            return;
        }
        state.selectedShapeColumns[action.payload.selectedTab].splice(columnIndex, 1);
    }

    function handleSelectAll(state: UploadState, action: PayloadAction<string>) {
        const columns = state.shapeColumns.find(column => column.shapeId === action.payload)?.columns || [];
        if (state.selectedShapeColumns[action.payload].length === columns.length) {
            state.selectedShapeColumns[action.payload] = [];
            return;
        }
        state.selectedShapeColumns[action.payload] = columns;
    }

    function updateShapeColumns(state: UploadState, action: PayloadAction<{ columns: any; shapeId: string }>) {
        const shapeIndex = state.shapeColumns.findIndex(shape => shape.shapeId === action.payload.shapeId);
        if (shapeIndex !== -1) {
            state.shapeColumns[shapeIndex] = {
                ...state.shapeColumns[shapeIndex],
                columns: action.payload.columns
            };
        }
    }

    function clearShapeColumn(state: UploadState) {
        state.selectedShapeColumns = {};
        state.shapeColumns = [];
        state.dragged = {};
        state.prevDragged = {};
    }

    function setSelectedType(state: UploadState, action: PayloadAction<{ selectedTab: string; selectedType: string }>) {
        state.selectedType[action.payload.selectedTab] = action.payload.selectedType;
    }

    function setIsLeftSidebarOpen(state: UploadState, action: PayloadAction<boolean>) {
        state.isLeftSidebarOpen = action.payload;
    }

    function setIsRightSidebarOpen(state: UploadState, action: PayloadAction<boolean>) {
        state.isRightSidebarOpen = action.payload;
    }
}
