import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const CloudUpload: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28.4" height="22.664" viewBox="0 0 28.4 22.664">
            <g id="cloud_upload" transform="translate(-839.8 -180.668)">
                <path
                    id="Path_304"
                    data-name="Path 304"
                    d="M868.159,194.668h-.018a6.445,6.445,0,0,0-4.079-5.3,9.091,9.091,0,0,0-9.029-8.7,8.985,8.985,0,0,0-8.575,6.172h-.029a6.587,6.587,0,0,0-4.357,1.686,6.869,6.869,0,0,0-2.272,5.1V195.9a2.842,2.842,0,0,0,2.839,2.838h7.43v-1.548h-7.43a1.292,1.292,0,0,1-1.291-1.29v-2.276a5.314,5.314,0,0,1,1.76-3.949,4.883,4.883,0,0,1,3.833-1.257.782.782,0,0,0,.836-.579,7.478,7.478,0,0,1,7.256-5.624,7.388,7.388,0,0,1,5.26,2.181,7.623,7.623,0,0,1,2.226,5.415.776.776,0,0,0,.56.855,4.908,4.908,0,0,1,3.573,4.718v.517a1.291,1.291,0,0,1-1.291,1.289h-7.43v1.548h7.43A2.842,2.842,0,0,0,868.2,195.9v-1.033Z"
                    fill={fill}
                />
                <path
                    id="Path_305"
                    data-name="Path 305"
                    d="M853.569,192.16l-3.264,2.175.858,1.289,2.063-1.375v9.083h1.548v-9.083l2.061,1.375.857-1.289-3.262-2.174A.77.77,0,0,0,853.569,192.16Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
