import { DEFAULT_TAB_ID } from 'helpers';
import { FeatureCollection } from 'models';
import { useDispatch } from 'react-redux';
import { featuresActions, layerActions } from 'store';
import { useOnClickLayerHandler } from './useOnClickLayerHandler';

const useAddIconClusterLayer = () => {
    const dispatch = useDispatch();
    const onClickHandler = useOnClickLayerHandler();
    const handleFeatures = (features: FeatureCollection) => {
        if (!features) return;
        dispatch(featuresActions.setSelectedProject(features.name));
        dispatch(
            layerActions.addIconClusterLayer({
                id: features.name,
                data: features,
                tab: DEFAULT_TAB_ID,
                onClick: onClickHandler
            })
        );
    };
    return handleFeatures;
};

export { useAddIconClusterLayer };

