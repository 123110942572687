import { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { env } from 'environments';
import { authActions, AuthState, noticesActions, RootState } from 'store';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';

interface AxiosApi {
    default: AxiosInstance;
    getRequest: (url: string, params?: any) => Promise<AxiosResponse>;
    postRequest: (url: string, body?: any) => Promise<AxiosResponse>;
    putRequest: (url: string, body?: any) => Promise<AxiosResponse>;
    deleteRequest: (url: string, body?: any) => Promise<AxiosResponse>;
}

export interface DefaultResponse {
    message: string;
}

const AxiosContext = createContext<AxiosApi>({ default: axios.create() } as AxiosApi);

const AxiosProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<string[]>([]);
    const { token } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();
    const { t } = useTranslation('notice');

    const api = useMemo(() => {
        const baseURL = env.reactAppSocketUrl + env.apiUrl;
        return axios.create({
            baseURL,
            headers: { Authorization: token || '' }
        });
    }, [token]);

    const removeIsLoading = (res: any) => {
        setIsLoading(prev => {
            const isLoading = [...prev];
            const index = isLoading.findIndex(url => url === res.config.url);
            if (index !== -1) isLoading.splice(index, 1);
            return isLoading;
        });
    };

    useEffect(() => {
        const reqFullFilledInterceptor = (req: any) => {
            setIsLoading(prev => prev.concat([req.url]));
            return req;
        };
        const resFullFilledInterceptor = (res: any) => {
            removeIsLoading(res);
            return res;
        };
        const rejInterceptor = (res: any) => {
            removeIsLoading(res);
            if (res?.response?.status === 498) return dispatch(authActions.logout());
            if (res?.response?.status === 304) return { status: res?.response?.status, data: res?.response?.data };
            console.error(res);
            if (res?.response?.data?.details)
                dispatch(noticesActions.setError(t(res?.response?.data?.message) + res?.response?.data?.details));
            else dispatch(noticesActions.setError(res?.response?.data?.message ?? 'internal-server-error'));
            return res?.response?.data;
        };
        const reqInterceptors = api.interceptors.request.use(reqFullFilledInterceptor, rejInterceptor);
        const resInterceptors = api.interceptors.response.use(resFullFilledInterceptor, rejInterceptor);
        return () => {
            api.interceptors.request.eject(reqInterceptors);
            api.interceptors.response.eject(resInterceptors);
        };
    }, [api.interceptors.request, api.interceptors.response, dispatch, token]);

    const getRequest = useCallback(async (url: string, params?: any) => api.get(url, { params }), [api]);
    const postRequest = useCallback(async (url: string, body?: any) => api.post(url, body), [api]);
    const putRequest = useCallback(async (url: string, body?: any) => api.put(url, body), [api]);
    const deleteRequest = useCallback(async (url: string, body?: any) => api.delete(url, { data: body }), [api]);

    return (
        <AxiosContext.Provider value={{ default: api, getRequest, postRequest, putRequest, deleteRequest }}>
            {children}
            <Spinner isLoading={isLoading.length > 0} />
        </AxiosContext.Provider>
    );
};

export { AxiosContext, AxiosProvider };
