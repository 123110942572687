import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const DeleteFile: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.314" height="11.314" viewBox="0 0 11.314 11.314">
            <path
                id="elimina_file"
                d="M-129.75,3241v-3.25H-133v-1.5h3.25V3233h1.5v3.25H-125v1.5h-3.25V3241Z"
                transform="translate(2385.779 -2192.031) rotate(45)"
                fill={fill}
            />
        </svg>
    );
};
