import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Swap: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
            <path
                id="swap"
                d="M11.734,32.7,4.7,25.6l7.034-7.1,1.244,1.255L8.042,24.7H20.594v1.8H8.042l4.935,4.941Zm15.933-9.8-1.244-1.255L31.358,16.7H18.806V14.9H31.358l-4.935-4.98L27.666,8.7,34.7,15.8Z"
                transform="translate(-4.7 -8.7)"
                fill={fill}
            />
        </svg>
    );
};
