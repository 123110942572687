import { createPortal } from 'react-dom';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { NoticeState, RootState } from 'store';
import classes from './Notice.module.scss';
import NoticeItem from './noticeItem/NoticeItem';

const Notice = () => {
    const { messages } = useSelector<RootState>(x => x.notices) as NoticeState;

    if (!messages || messages.length === 0) return <></>;

    return createPortal(
        <div className={classes.backdrop}>
            {messages.map(message => (
                <NoticeItem message={message} />
            ))}
        </div>,
        document.getElementById('modal-root')!
    );
};

export default memo(Notice);
