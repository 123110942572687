import { Routes, Route, Navigate } from 'react-router-dom';
import { ExportFileProvider, UserProvider, GroupProvider, FeatureCollectionProvider } from 'context';
import { lazy } from 'react';

const SsoRedirect = lazy(() => import('views/login/SsoRedirect'));
const Backoffice = lazy(() => import('views/backoffice/Backoffice'));
const Login = lazy(() => import('views/login/Login'));
const SsoLogin = lazy(() => import('views/login/ssoLogin/SsoLogin'));
const PrivateRoute = lazy(() => import('views/privateRoute/PrivateRoute'));
const Home = lazy(() => import('views/map/Home'));

const Routing = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route
                path="/backoffice"
                element={
                    <PrivateRoute>
                        <UserProvider>
                            <GroupProvider>
                                <FeatureCollectionProvider>
                                    <ExportFileProvider>
                                        <Backoffice />
                                    </ExportFileProvider>
                                </FeatureCollectionProvider>
                            </GroupProvider>
                        </UserProvider>
                    </PrivateRoute>
                }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/auth/:loginToken" element={<SsoLogin />} />
            <Route path="/sso" element={<SsoRedirect />} />
            <Route
                path="/power-plant/:plantId"
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
export default Routing;

