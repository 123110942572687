const text_paint = {
  'text-halo-width': 4,
  'text-halo-blur': 2,
  'text-halo-color': "rgba(230, 230, 230, 1)",
}

const operator_text = ["step", ["zoom"],
  ['get', 'name'],
  14, ["case", ['has', 'operator'],
    ["concat", ['get', 'name'], ' (', ['get', 'operator'], ')'],
    ['get', 'name']
  ]
];

const underground_p = ["any",
  ['==', ['get', 'location'], 'underground'],
  ['==', ['get', 'location'], 'underwater'],
  ['==', ['get', 'tunnel'], true],
  ['all', // Power cables are underground by default
    ['==', ['get', 'type'], 'cable'],
    ['==', ['get', 'location'], '']
  ]
];

const font = ['Noto Sans Regular'];

export { text_paint, operator_text, underground_p, font };
