import { SvgIconProps } from 'helpers';

export const RenamedAreaFull: SvgIconProps = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <defs>
                <clipPath id="renamedFullClipPath">
                    <rect id="Rectangle_1686" data-name="Rectangle 1686" width="9.527" height="9.527" fill="#f7f9f9" />
                </clipPath>
            </defs>
            <g id="notifica_rinominazione" transform="translate(-664.543 -836.787)">
                <rect
                    id="Rectangle_1688"
                    data-name="Rectangle 1688"
                    width="16"
                    height="16"
                    rx="6"
                    transform="translate(664.543 836.787)"
                    fill="#1a2526"
                />
                <g id="text_full" transform="translate(667.544 840.024)">
                    <g id="Group_560" data-name="Group 560" clipPath="url(#renamedFullClipPath)">
                        <path
                            id="Path_1349"
                            data-name="Path 1349"
                            d="M5.562.711H8.187l.39,1.82h.95L9.391,0H.136L0,2.531H.95L1.34.711H3.965C3.994,1.942,4,3.179,4,4.408v.711C4,6.364,3.994,7.593,3.965,8.8l-1.744.156v.569H7.306V8.958L5.562,8.8c-.03-1.227-.036-2.459-.036-3.683V4.408c0-1.25.007-2.484.036-3.7"
                            fill="#f7f9f9"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
