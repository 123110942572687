import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Check: SvgIconProps = ({ fill = colors.gray900 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.648" height="10.601" viewBox="0 0 14.648 10.601">
            <path
                fill={fill}
                id="check_28x28"
                d="M68.007,47.025a.744.744,0,0,1-.53-.22L63.211,42.54l1.061-1.061,3.735,3.735,8.791-8.79,1.061,1.061-9.322,9.32a.744.744,0,0,1-.53.22"
                transform="translate(-63.211 -36.424)"
            />
        </svg>
    );
};
