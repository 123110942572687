import { createContext, FC, PropsWithChildren, useContext, useCallback } from 'react';
import { AxiosContext } from './axiosContext';
import { FeatureCollection, FeatureCollectionProject } from 'models';
import { Polygon } from 'helpers';
import { bbox, bboxPolygon } from '@turf/turf';
import { useDispatch, useSelector } from 'react-redux';
import { AreasState, AuthState, RootState, StringType } from 'store';
import 'helpers/arrayExtension';
import { noticesActions } from 'store/notice.slice';
import { env } from 'environments';

export interface RenameAreaRequest {
    newName: string;
    featureCollectionId: string;
    notificationId?: string;
    note?: string;
}

export interface DeleteAreaRequest {
    featureCollectionsId?: string[];
    notificationId?: string;
    note?: string;
}

interface FeatureCollectionApi {
    getFeatureCollectionById: (id: string) => Promise<FeatureCollectionProject>;
    getAllFeatureCollectionsByGroup: () => Promise<FeatureCollectionProject[]>;
    getFeatureCollectionsByMunicipality: (name: string) => Promise<FeatureCollection[]>;
    getFeatureCollectionsByCoordinates: (coordinates: number[][][]) => Promise<FeatureCollection[]>;
    getFeatureCollectionFeaturesCountById: (
        id: string,
        groupName: string,
        layerName: string,
        cqlAttribute: string
    ) => Promise<number>;
    getFeatureCollectionWithFeaturesById: (id: string) => Promise<FeatureCollection>;
    getFeatureCollectionsByPolygon: (polygon: Polygon) => Promise<FeatureCollection[]>;
    getFeatureCollectionsByBbox: (bbox: Polygon) => Promise<FeatureCollection[]>;
    renameFeatureCollectionById: (areaToRename: RenameAreaRequest, optionalUsers?: string[]) => Promise<FeatureCollection>;
    deleteProjectsById: (areaToDelete: DeleteAreaRequest, optionalUsers?: string[]) => Promise<string>;
}

const FeatureCollectionContext = createContext<FeatureCollectionApi>({} as FeatureCollectionApi);

const FeatureCollectionProvider: FC<PropsWithChildren> = ({ children }) => {
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const { areas } = useSelector<RootState>(x => x.areas) as AreasState;
    const { getRequest, deleteRequest, putRequest } = useContext(AxiosContext);
    const dispatch = useDispatch();

    const getFeatureCollectionById = useCallback(
        async (id: string) => {
            const { data } = await getRequest(`/feature-collections/${id}`);
            return data;
        },
        [getRequest]
    );

    const getAllFeatureCollectionsByGroup = useCallback(async () => {
        const { data } = await getRequest(`/feature-collections/by-group`);
        return data;
    }, [getRequest]);

    const getFeatureCollectionsByMunicipality = useCallback(
        async (name: string) => {
            const { data } = await getRequest(`/feature-collections/by-project/${name}`);
            return data;
        },
        [getRequest]
    );

    const getFeatureCollectionsByCoordinates = useCallback(
        async (coordinates: number[][][]) => {
            const { data } = await getRequest(`/feature-collections/by-coordinates/${coordinates}`);
            return data;
        },
        [getRequest]
    );

    const getFeaturesCountById = useCallback(
        async (id: string, groupName: string, layerName: string, cqlAttribute: string) => {
            const params = {
                request: 'GetFeature',
                VERSION: '1.1.0',
                typeName: `${groupName}:${layerName}`,
                outputFormat: 'text/xml',
                cql_filter: `${cqlAttribute}='${id}'`,
                resultType: 'hits'
            };
            const { data } = await getRequest(`${env.reactAppSocketUrl}/geoserver/wfs`, params);
            const parser = new DOMParser();
            const xml = parser.parseFromString(data, 'text/xml');
            const numberOfFeatures = +xml.getElementsByTagName('wfs:FeatureCollection')[0].getAttribute('numberOfFeatures')!;
            return numberOfFeatures;
        },
        [getRequest]
    );

    const getFeatureCollectionWithFeaturesById = useCallback(
        async (id: string) => {
            let featureCollection = areas.getItemById(id);
            if (!featureCollection) featureCollection = await getFeatureCollectionById(id);
            const groupName = featureCollection.groupName.toLowerCase();
            let layerName: string;
            let cqlAttribute: string;
            if (featureCollection.dataType.toLowerCase() === StringType.NotCategorized.toLowerCase()) {
                layerName = 'project_features';
                cqlAttribute = 'feature_collection_id';
            } else {
                layerName = featureCollection.dataType.toLowerCase().concat('s');
                cqlAttribute = 'featureCollectionId';
            }
            const params = {
                request: 'GetFeature',
                VERSION: '2.0.0',
                typeName: `${groupName}:${layerName}`,
                outputFormat: 'application/json',
                cql_filter: `${cqlAttribute}='${id}'`
            };
            const numberOfFeatures = await getFeaturesCountById(id, groupName, layerName, cqlAttribute);
            const FEATURES_PER_REQUEST = 5000;
            const numberOfRequests = Math.ceil(numberOfFeatures / FEATURES_PER_REQUEST);
            const requests = [];
            for (let i = 0; i < numberOfRequests; i++) {
                requests.push(
                    getRequest(`${env.reactAppSocketUrl}/geoserver/wfs`, {
                        ...params,
                        startIndex: i * FEATURES_PER_REQUEST,
                        count: FEATURES_PER_REQUEST
                    })
                );
            }
            const responses = await Promise.all(requests);
            const data: { features: any[] } = { features: [] };
            responses.forEach(res => {
                data.features.push(...res.data.features);
            });
            const features = data.features.map((f: any) => {
                return { ...f, properties: f.properties.properties ? JSON.parse(f.properties.properties) : f.properties };
            });
            const bounds = bbox({ type: 'FeatureCollection', features });
            const boundsPolygon = bboxPolygon(bounds);
            return { ...featureCollection, features, bbox: boundsPolygon.geometry as any } as FeatureCollection;
        },
        [areas, getFeatureCollectionById, getFeaturesCountById, getRequest]
    );

    const getFeatureCollectionsByPolygon = useCallback(
        async (polygon: Polygon) => {
            const { data } = await getRequest(`/feature-collections/by-polygon`, {
                polygon: JSON.stringify(polygon)
            });
            return data;
        },
        [getRequest]
    );

    const getFeatureCollectionsByBbox = useCallback(
        async (bbox: Polygon) => {
            const { data } = await getRequest(`/feature-collections/by-bbox`, { bbox: JSON.stringify(bbox) });
            return data;
        },
        [getRequest]
    );

    const deleteProjectsById = useCallback(
        async (areaToDelete: DeleteAreaRequest, optionalUsers: string[] = []) => {
            const { data } = await deleteRequest(`/feature-collections/${user?.role}`, {
                ...areaToDelete,
                optionalUsers
            });
            if (!data) return;
            dispatch(noticesActions.setSuccess(data?.message));
            return data?.message;
        },
        [deleteRequest, dispatch, user?.role]
    );

    const renameFeatureCollectionById = useCallback(
        async (areaToRename: RenameAreaRequest, optionalUsers: string[] = []) => {
            const { data } = await putRequest(`/feature-collections/${user?.role}`, {
                ...areaToRename,
                optionalUsers
            });
            if (!data) return;
            dispatch(noticesActions.setSuccess(data?.message));
            return data?.message;
        },
        [putRequest, user?.role, dispatch]
    );

    return (
        <FeatureCollectionContext.Provider
            value={{
                getFeatureCollectionById,
                getFeatureCollectionFeaturesCountById: getFeaturesCountById,
                getFeatureCollectionWithFeaturesById,
                getFeatureCollectionsByPolygon,
                getFeatureCollectionsByBbox,
                getFeatureCollectionsByMunicipality,
                getFeatureCollectionsByCoordinates,
                getAllFeatureCollectionsByGroup,
                renameFeatureCollectionById,
                deleteProjectsById
            }}
        >
            {children}
        </FeatureCollectionContext.Provider>
    );
};

export { FeatureCollectionContext, FeatureCollectionProvider };
