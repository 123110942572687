import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerTransitionTower: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.49" viewBox="0 0 13.5 13.49">
            <path
                id="powerTransitionTower"
                d="M13.49.7a.584.584,0,0,0-.04-.18L13.42.43a.56.56,0,0,0-.1-.15c-.02-.02-.03-.04-.05-.06,0,0,0-.01-.02-.02a.571.571,0,0,0-.21-.13h0a.751.751,0,0,0-.26-.05H.73A.857.857,0,0,0,.46.07h0A1.2,1.2,0,0,0,.25.2S.25.21.23.22.2.26.18.28a.537.537,0,0,0-.1.16L.05.53A1.163,1.163,0,0,0,.01.71C.01.73,0,.74,0,.76v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75S13.5.71,13.49.7ZM6.75,5.54,8.84,6.9,6.75,11.07,4.66,6.89,6.74,5.54Zm-2.76,0L2.66,2.88,5.37,4.64l-1.38.9Zm4.14-.9,2.72-1.77L9.52,5.54Zm-1.38-.9L3.28,1.5h6.94L6.75,3.75ZM1.5,3.91l4.04,8.07H1.5V3.93Zm6.46,8.07L12,3.93V12H7.96Z"
                transform="translate(0 -0.02)"
                fill={fill}
            />
        </svg>
    );
};
