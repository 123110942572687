import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const TelecomsTower: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.685" height="17.28" viewBox="0 0 9.685 17.28">
            <g id="telecomsTower" transform="translate(-0.002)">
                <path
                    id="Path_1437"
                    data-name="Path 1437"
                    d="M6.21,5.38A2.387,2.387,0,1,0,2.45,3.43,2.361,2.361,0,0,0,3.47,5.38L.19,16.31a.754.754,0,0,0,.71.97.767.767,0,0,0,.53-.22l3.4-3.4,3.4,3.4a.767.767,0,0,0,.53.22.73.73,0,0,0,.38-.11.755.755,0,0,0,.33-.86L6.19,5.37ZM4.84,11.54,3.83,10.53,4.89,9.47,5.9,10.48,4.84,11.54ZM4.55,7.01l.29-.98.31,1.04-.27.27L4.54,7Zm.29-4.47a.89.89,0,1,1-.89.89A.892.892,0,0,1,4.84,2.54ZM2.48,13.9l.6-2,.7.7-1.3,1.3ZM5.9,12.6l.7-.7.6,2Z"
                    fill={fill}
                />
                <path
                    id="Path_1438"
                    data-name="Path 1438"
                    d="M2.48,5.79a3.356,3.356,0,0,1,0-4.73L1.42,0a4.847,4.847,0,0,0,0,6.85Z"
                    fill={fill}
                />
                <path
                    id="Path_1439"
                    data-name="Path 1439"
                    d="M7.21,5.79,8.27,6.85A4.847,4.847,0,0,0,8.27,0L7.21,1.06a3.356,3.356,0,0,1,0,4.73Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
