import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const TransformOperate: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.824" height="22" viewBox="0 0 21.824 22">
            <path
                id="transform_operate"
                d="M14.595,27.2a10.463,10.463,0,0,1-3.686-1.258,10.814,10.814,0,0,1-2.926-2.414,11.117,11.117,0,0,1-1.931-3.32,11.321,11.321,0,0,1-.7-4.008,10.273,10.273,0,0,1,1.185-4.8,13.066,13.066,0,0,1,3.35-4.066H6.2V6.019h6.056v6.056H10.908V8.243a10.81,10.81,0,0,0-3.116,3.6A9.208,9.208,0,0,0,6.7,16.2,9.7,9.7,0,0,0,8.9,22.548a9.2,9.2,0,0,0,5.69,3.306Zm0-6.319-4.242-4.242.936-.936,3.306,3.306,6.641-6.641.936.936Zm5.676,5.5V20.325h1.346v3.832a10.81,10.81,0,0,0,3.116-3.6,9.208,9.208,0,0,0,1.1-4.359A9.7,9.7,0,0,0,23.62,9.852a9.2,9.2,0,0,0-5.69-3.306V5.2a10.562,10.562,0,0,1,6.626,3.672A10.892,10.892,0,0,1,27.174,16.2a10.36,10.36,0,0,1-1.185,4.812,12.693,12.693,0,0,1-3.35,4.052h3.686v1.316Z"
                transform="translate(-5.35 -5.2)"
                fill={fill}
            />
        </svg>
    );
};
