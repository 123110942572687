import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Search: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.859" height="24.857" viewBox="0 0 24.859 24.857">
            <path
                id="search"
                d="M283.846,100.016a10.765,10.765,0,1,0-1.062,1.061l6.2,6.194,1.061-1.061Zm1.134-6.954a9.16,9.16,0,0,1-9.149,9.15h0a9.149,9.149,0,1,1,9.153-9.15Z"
                transform="translate(-265.181 -82.414)"
                fill={fill}
            />
        </svg>
    );
};
