import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerTransformer: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.84" height="15.5" viewBox="0 0 10.84 15.5">
            <path
                id="powerTransformer"
                d="M10.83,5.42A5.415,5.415,0,1,0,0,5.42,5.322,5.322,0,0,0,.54,7.75a5.415,5.415,0,1,0,10.3,2.33,5.322,5.322,0,0,0-.54-2.33,5.388,5.388,0,0,0,.54-2.33ZM5.42,1.5A3.926,3.926,0,0,1,9.34,5.42a3.77,3.77,0,0,1-.09.83,5.408,5.408,0,0,0-7.64,0,3.837,3.837,0,0,1-.09-.83A3.926,3.926,0,0,1,5.44,1.5ZM8.55,7.75A3.873,3.873,0,0,1,5.42,9.33,3.915,3.915,0,0,1,2.29,7.75a3.89,3.89,0,0,1,6.26,0ZM5.42,14A3.926,3.926,0,0,1,1.5,10.08a3.77,3.77,0,0,1,.09-.83,5.408,5.408,0,0,0,7.64,0,3.837,3.837,0,0,1,.09.83A3.926,3.926,0,0,1,5.4,14Z"
                fill={fill}
            />
        </svg>
    );
};
