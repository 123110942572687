import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Cross: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.061" height="17.061" viewBox="0 0 17.061 17.061">
            <path
                id="annulla_36x36"
                d="M106.094,10.53l-1.061-1.061-7.469,7.47-7.47-7.47L89.033,10.53,96.5,18l-7.47,7.469,1.061,1.061,7.47-7.47,7.469,7.47,1.061-1.061L98.624,18Z"
                transform="translate(-89.033 -9.469)"
                fill={fill}
            />
        </svg>
    );
};
