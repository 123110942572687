import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { AxiosContext } from './axiosContext';

interface DockerApi {
    startContainer: (groupName: string) => Promise<string>;
    resetContainerUp: () => void;
    isContainerUp: boolean;
}

const DockerContext = createContext<DockerApi>({} as DockerApi);

const DockerProvider: FC<PropsWithChildren> = ({ children }) => {
    const { postRequest } = useContext(AxiosContext);
    const [isContainerUp, setIsContainerUp] = useState(false);

    const startContainer = useCallback(
        async (name: string) => {
            const { data } = await postRequest(`/docker/${name.toLowerCase()}`);
            if (data) setIsContainerUp(() => true);
            return data;
        },
        [postRequest]
    );

    const resetContainerUp = useCallback(() => setIsContainerUp(false), [setIsContainerUp]);

    return (
        <DockerContext.Provider value={{ startContainer, resetContainerUp, isContainerUp }}>{children}</DockerContext.Provider>
    );
};

export { DockerContext, DockerProvider };

