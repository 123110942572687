import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Viewfinder: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23.898" height="23.898" viewBox="0 0 23.898 23.898">
            <path
                id="centra_marker"
                d="M573.55,94.358a7.941,7.941,0,0,0-7.128-7.128V83.158h-1.5v4.07a7.947,7.947,0,0,0-7.131,7.13h-4.069v1.5h4.069a7.942,7.942,0,0,0,7.131,7.129v4.069h1.5v-4.069a7.941,7.941,0,0,0,7.128-7.129h4.07v-1.5Zm-8.628,4.1v3.025a6.38,6.38,0,0,1-5.625-5.625h3.025v-1.5H559.3a6.376,6.376,0,0,1,5.625-5.622v3.022h1.5V88.736a6.374,6.374,0,0,1,5.622,5.622h-3.022v1.5h3.024a6.379,6.379,0,0,1-5.624,5.623V98.458Z"
                transform="translate(-553.722 -83.158)"
                fill={fill}
            />
        </svg>
    );
};
