import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ResizeSmall: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.1" height="27.102" viewBox="0 0 27.1 27.102">
            <g id="resizeSmall" transform="translate(205.55 -1416.449) rotate(90)">
                <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M1416.919,188.222v1.494h10.049a.748.748,0,0,0,.747-.747V178.92h-1.494v8.244l-8.714-8.714-1.058,1.058,8.714,8.714Z"
                    fill={fill}
                />
                <path
                    id="Path_224"
                    data-name="Path 224"
                    d="M1433.779,205.083v-8.247l8.717,8.714,1.055-1.055-8.714-8.714h8.244v-1.5h-10.049a.748.748,0,0,0-.747.747v10.05Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
