import { FC, PropsWithChildren } from 'react';
import { CSSPosition, HTMLButtonType } from 'helpers';
import Tooltip from 'components/tooltip/Tooltip';

type ButtonProps = FC<
    PropsWithChildren<{
        disabled?: boolean;
        onClick?: () => any;
        type?: HTMLButtonType;
        className: string;
        isTooltipNecessary?: boolean;
        tooltipMessage?: string;
        tooltipPosition?: CSSPosition;
    }>
>;

const Button: ButtonProps = ({
    children,
    onClick,
    className,
    type,
    disabled,
    tooltipMessage,
    isTooltipNecessary = false,
    tooltipPosition
}) => {
    return (
        <Tooltip condition={isTooltipNecessary} message={tooltipMessage!} tooltipPosition={tooltipPosition}>
            <button type={type} className={className} onClick={onClick} disabled={disabled}>
                {children}
            </button>
        </Tooltip>
    );
};

export default Button;
