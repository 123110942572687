import { layerActions, useGetPowerElementsQuery, useGetPlantQuery, oimActions } from 'store';
import { useParams } from 'react-router-dom';
import { useMount, useOnClickLayerHandler } from 'hook';
import { Feature, bbox, bboxPolygon, featureCollection, lineString, point, polygon } from '@turf/turf';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_TAB_ID, PowerElementTypes, PowerNode, SELECTED_FEATURE_LAYER } from 'helpers';

export const usePowerPlant = () => {
    const onClickLayerHandler = useOnClickLayerHandler();
    const { plantId } = useParams();
    const [objectType, setObjectType] = useState<PowerElementTypes | undefined>(undefined);
    const { data: powerElements } = useGetPowerElementsQuery(
        { objectType, plantId: +plantId! },
        { skip: !objectType || !plantId || isNaN(+plantId) }
    );
    const { data: plant } = useGetPlantQuery(+plantId!, { skip: !plantId });
    const dispatch = useDispatch();

    useMount(() => {
        if (!plantId || isNaN(+plantId)) return;
        onClickLayerHandler({
            object: {
                id: plantId,
                properties: {
                    osm_id: plantId,
                    layerName: 'power_plant'
                }
            },
            layer: { props: { tab: DEFAULT_TAB_ID } }
        });
        dispatch(layerActions.updateMapStyle({ tabId: DEFAULT_TAB_ID, mapStyle: 'MAP_STYLE_OPEN_INFRA_MAP' }));
        dispatch(oimActions.setVisibility(true));
    });

    useEffect(() => {
        if (!plant) return;
        setObjectType(() => (plant.links.OpenStreetMap.includes('way') ? 'way' : 'relation'));
    }, [dispatch, plant]);

    useEffect(() => {
        if (!powerElements) return;
        const nodes = new Map<number, PowerNode>();

        const lines: Feature[] = [];
        const polygons: Feature[] = [];
        const points: Feature[] = [];
        const matchingPoints = new Set<number>();

        for (const elem of powerElements) {
            if (elem.type === 'node') {
                nodes.set(elem.id, elem);
                continue;
            }
            if (elem.type === 'way') {
                const wayNodes: PowerNode[] = [];
                for (const nodeId of elem.nodes) {
                    if (!nodes.has(nodeId)) continue;
                    wayNodes.push(nodes.get(nodeId)!);
                }
                wayNodes.forEach(({ id }) => matchingPoints.add(id));
                if (wayNodes[0].id === wayNodes[wayNodes.length - 1].id)
                    polygons.push(polygon([wayNodes.map(n => [n.lon, n.lat])]));
                else lines.push(lineString(wayNodes.map(n => [n.lon, n.lat])));
            }
        }

        nodes.forEach(node => {
            if (matchingPoints.has(node.id)) return;
            points.push(point([node.lon, node.lat]));
        });

        const collection = featureCollection([...lines, ...polygons, ...points]);
        collection.bbox = bboxPolygon(bbox(collection)).geometry as any;

        dispatch(layerActions.deleteLayer(SELECTED_FEATURE_LAYER));
        dispatch(
            layerActions.addGeoJsonLayer({
                id: SELECTED_FEATURE_LAYER,
                tab: DEFAULT_TAB_ID,
                data: collection,
                getFillColor: [0, 143, 193, 60],
                getLineColor: [0, 143, 193, 255],
                getLineWidth: (d: Feature) => {
                    if (d.geometry.type === 'Point') return 0.1;
                    return 1;
                },
                getPointRadius: 1
            })
        );
    }, [dispatch, plant, powerElements]);
};
