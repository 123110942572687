import { FC, Dispatch, SetStateAction } from 'react';
import { MapStyle } from 'helpers';

export type SetState<S> = Dispatch<SetStateAction<S>>;

export enum SearchType {
    geocoding = 'geocoding',
    reverseGeocoding = 'reverseGeocoding'
}

export enum ToggleTypes {
    areas = 'areas',
    groups = 'groups',
    users = 'users'
}

export enum NotificationFilterTypes {
    added = 'added',
    rename = 'rename',
    delete = 'delete',
    renamed = 'renamed',
    deleted = 'deleted',
    shareFavorite = 'shareFavorite',
    downloaded = 'downloaded'
}

export interface Notification {
    id: string;
    title: string;
    description: string;
    note: string;
    newName: string;
    action: NotificationFilterTypes;
    status: string;
    sender: string;
    featureCollectionId: string;
    favoritePolygonId: string;
    fileId: string;
    UserNotification: UserNotification;
    createdAt: Date;
    expand?: boolean;
}

export interface UserNotification {
    status: string;
    notificationId?: string;
    featureCollectionId?: string;
}

export enum FavoriteReducerCases {
    toggleFavoritesModal = 'isFavoriteModalOpen',
    toggleEditModal = 'isEditModalOpen',
    toggleShareModal = 'isShareModalOpen',
    toggleDeleteModal = 'isDeleteModalOpen',
    selectedFavorite = 'selectedFavorite',
    selectedFavorites = 'selectedFavorites',
    favoritePolygonsList = 'favoritePolygonsList',
    favoritePolygonsListFiltered = 'favoritePolygonsListFiltered'
}

export enum FileStatus {
    notStarted = 'notStarted',
    downloading = 'downloading',
    downloaded = 'downloaded'
}

export enum GeocodingTypes {
    geocoding = 'geocoding',
    reverseGeocoding = 'reverseGeocoding'
}

export enum UserNotificationStatus {
    notRead = 'notRead',
    read = 'read',
    archived = 'archived',
    unarchive = 'notRead'
}

export type HTMLButtonType = 'button' | 'submit' | 'reset';

export type SvgIconProps = FC<{ fill?: string; zoom?: number; isFull?: boolean }>;

export type GeoBoundingBox = { west: number; north: number; east: number; south: number };

export type CSSPosition = {
    top?: string | number;
    right?: string | number;
    bottom?: string | number;
    left?: string | number;
};

export type TableButtonProps = {
    tooltipMessage?: string;
    icon: JSX.Element;
    action: (item: any) => any;
    customClasses: string;
    id: string;
    disabled?: ((item: any) => boolean) | boolean;
};

export interface Crs {
    type: string;
    properties: {
        name: string;
    };
}

export interface EPSGInterface {
    code: number;
    name: string;
    unit: string;
    area: string;
    accuracy: number | null;
}

export interface Point {
    type: 'Point';
    coordinates: number[];
    crs: any;
}
export interface LineString {
    type: 'LineString';
    coordinates: number[][];
    crs: any;
}

export interface Polygon {
    type: 'Polygon';
    coordinates: number[][][];
    crs: any;
}

export interface MultiPolygon {
    type: 'MultiPolygon';
    coordinates: number[][][][];
    crs: any;
}

export type Geometry = Point | LineString | Polygon | MultiPolygon;

export type Feature<T = Geometry> = { properties: any; geometry: T; crs?: any };

export type MapStyleType = keyof typeof MapStyle;

export interface FieldProps {
    name: string;
    validators?: any;
    errors?: any;
    options?: any[];
    value?: any;
    disabled?: boolean;
    multiSelect?: any;
}

export enum DrawSearchToggleTypes {
    region = 'region',
    polygon = 'polygon',
    recents = 'recents'
}

export enum Emphasizers {
    END = 'END_EMPHASIS',
    START = 'START_EMPHASIS'
}

export interface CustomTable {
    id: string;
    columns: string[];
    name: string;
}

export interface Nation {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
}

export interface PowerLines {
    id: number;
    nationId: number;
    voltage: string;
    length: string;
    percentage: string;
    createdAt: string;
    updatedAt: string;
}

export interface PowerPlants {
    id: number;
    nationId: number;
    source: string;
    output: string;
    count: string;
    createdAt: string;
    updatedAt: string;
    nation?: Nation;
}

export interface Generator {
    id: number;
    plantId: number;
    source: string;
    output: string;
    count: string;
    totalOutput: string;
    createdAt: string;
    updatedAt: string;
}

export interface Plant {
    id: number;
    nationId: number;
    name: string;
    link: string;
    englishName: string;
    operator: string;
    output: string;
    source: string;
    method: string;
    wikidata: string;
    links: Record<string, string>;
    generators: Generator[];
    createdAt: string;
    updatedAt: string;
}

export enum PowerStatsToggleType {
    powerPlants = 'power-plants',
    powerLines = 'power-lines'
}

export interface PowerNode {
    type: 'node';
    id: number;
    lat: number;
    lon: number;
}

export interface PowerWay {
    type: 'way';
    id: number;
    nodes: number[];
    tags: {
        alt_name: string;
        landuse: string;
        name: string;
        'name:en': string;
        operator: string;
        'plant:method': string;
        'plant:output:electricity': string;
        'plant:source': string;
        'plant:type': string;
        power: string;
        'ref:EU:ENTSOE_EIC': string;
        wikidata: string;
    };
}
export interface PowerRelation {
    type: 'relation';
    id: number;
    members: PowerElement[];
    tags: {
        name: string;
        operator: string;
        'plant:method': string;
        'plant:output:electricity': string;
        'plant:source': string;
        power: string;
        start_date: string;
        type: string;
    };
}

export type PowerElement = PowerNode | PowerWay | PowerRelation;
export type PowerElementTypes = 'node' | 'way' | 'relation';

