import { useCallback, useReducer } from 'react';
import { Crop, PixelCrop } from 'react-image-crop';

const Actions = {
    toggleModal: toggleModal,
    setValue: setValue
};

const initialState = {
    isModalOpen: false,
    doneCrop: undefined as PixelCrop | undefined,
    crop: undefined as Crop | undefined,
    imgSrc: ''
};

type ActionsType = keyof typeof Actions;
type StateType = typeof initialState;
type StateKey = keyof StateType;

const useCropImageReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const setValue = useCallback((key: StateKey, value: any) => dispatch({ action: 'setValue', data: { key, value } }), []);
    const toggleModal = useCallback(() => dispatch({ action: 'toggleModal' }), []);

    return { ...state, setValue, toggleModal };
};

const reducer = (state: StateType, action: { action: ActionsType; data?: { key: StateKey; value: any } }) => {
    const reducer = Actions[action.action](state, action.data);
    return reducer;
};

function setValue(state: StateType, action: any) {
    return { ...state, [action.key]: action.value };
}

function toggleModal(state: StateType) {
    return { ...state, isModalOpen: !state.isModalOpen };
}

export { useCropImageReducer };
