import { IconButton } from 'components/buttons';
import { Close, SnackbarError, SnackbarSuccess } from 'components/icons';
import { useMount } from 'hook';
import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { noticesActions } from 'store';
import colors from 'styles/modules/colors.module.scss';
import classes from './NoticeItem.module.scss';

type NoticeItemProps = FC<{
    message: {
        id: string;
        text: string;
        type: string;
    };
}>;

const NoticeItem: NoticeItemProps = ({ message }) => {
    const dispatch = useDispatch();
    const closeNoticeHandler = useCallback((message: string) => dispatch(noticesActions.removeMessage(message)), [dispatch]);
    const { t } = useTranslation('notice');

    useMount(() => {
        setTimeout(() => {
            closeNoticeHandler(message.id);
        }, 6000);
    });

    return (
        <div key={message.id} className={classes.notice}>
            <div className={classes.icon}>
                {message.type === 'error' ? <SnackbarError /> : <SnackbarSuccess />}
                <div className={classes.message}>{t(message.text as any)}</div>
            </div>
            <div className={classes.buttons}>
                <IconButton
                    isTooltipNecessary={false}
                    icon={<Close fill={colors.gray900} />}
                    className="light"
                    onClick={() => closeNoticeHandler(message.id)}
                />
            </div>
        </div>
    );
};

export default memo(NoticeItem);
