import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const SnackbarSuccess: SvgIconProps = ({ fill = colors.primary600 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="Snackbar_-_positivo" data-name="Snackbar - positivo" transform="translate(-1368 -926)">
                <path
                    id="Subtraction_27"
                    data-name="Subtraction 27"
                    d="M7848,2099a7,7,0,1,1,7-7A7.008,7.008,0,0,1,7848,2099Zm-3.184-7.69-.826.826,2.527,2.523a.572.572,0,0,0,.412.172.579.579,0,0,0,.412-.172l4.666-4.666-.824-.824-4.254,4.254Z"
                    transform="translate(-6473 -1159)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
