import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TextButton } from 'components/buttons';
import classes from './ChangeLanguage.module.scss';
import Modal from 'components/modal/Modal';
import { Translate } from 'components/icons';
import { RIGHT_TOOLTIP } from 'helpers';

type ChangeLanguageProps = FC<{ toggle: () => any; isOpen: boolean }>;

const ChangeLanguage: ChangeLanguageProps = ({ toggle, isOpen }) => {
    const { t, i18n } = useTranslation('navbar');

    const onChangeLanguage = useCallback((language: string) => () => i18n.changeLanguage(language), [i18n]);

    return (
        <>
            <IconButton
                tooltipMessage={'changeLang'}
                tooltipPosition={RIGHT_TOOLTIP}
                className="transparent"
                onClick={toggle}
                icon={<Translate />}
            />
            <Modal isOpen={isOpen} handleClose={toggle} title={t('changeLang.title')}>
                <div className={classes.container}>
                    <TextButton
                        className={i18n.language === 'it' ? classes.selected : classes.unselected}
                        onClick={onChangeLanguage('it')}
                    >
                        {t('changeLang.it')}
                    </TextButton>
                    <TextButton
                        className={i18n.language === 'en' ? classes.selected : classes.unselected}
                        onClick={onChangeLanguage('en')}
                    >
                        {t('changeLang.en')}
                    </TextButton>
                </div>
            </Modal>
        </>
    );
};

export default memo(ChangeLanguage);
