import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FavoritePolygon } from 'models';

interface ModalsInitialState {
    isFavoriteModalOpen: boolean;
    isEditModalOpen: boolean;
    isDeleteModalOpen: boolean;
    isShareModalOpen: boolean;
}

export type FavoriteModalTypes = keyof ModalsInitialState;

interface FavoritesInitialState {
    selectedFavorite: FavoritePolygon;
    selectedFavorites: string[];
    favoritePolygonsList: FavoritePolygon[];
    filteredFavoritePolygonsList: FavoritePolygon[];
}

type FavoriteActions = keyof FavoritesInitialState;

export interface FavoriteState extends FavoritesInitialState, ModalsInitialState {}

const name = 'favorites';
const initialState: FavoriteState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const favoritesActions = { ...slice.actions };
export const favoritesReducer = slice.reducer;

function createInitialState() {
    return {
        isFavoriteModalOpen: false,
        isEditModalOpen: false,
        isDeleteModalOpen: false,
        isShareModalOpen: false,
        selectedFavorite: {} as FavoritePolygon,
        selectedFavorites: [],
        favoritePolygonsList: [],
        filteredFavoritePolygonsList: []
    };
}

function createReducers() {
    return {
        toggleModal,
        setValue,
        setFavoritePolygonsLists,
        addFavoritePolygon,
        updateFavoritePolygon
    };

    function toggleModal(state: FavoriteState, action: PayloadAction<FavoriteModalTypes>) {
        state[action.payload] = !state[action.payload];
    }

    function setValue(state: FavoriteState, action: PayloadAction<{ key: FavoriteActions; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function setFavoritePolygonsLists(state: FavoriteState, action: PayloadAction<FavoritePolygon[]>) {
        state.favoritePolygonsList = action.payload;
        state.filteredFavoritePolygonsList = action.payload;
        state.selectedFavorites = [];
    }

    function addFavoritePolygon(state: FavoriteState, action: PayloadAction<FavoritePolygon>) {
        state.favoritePolygonsList.push(action.payload);
    }

    function updateFavoritePolygon(state: FavoriteState, action: PayloadAction<FavoritePolygon>) {
        state.favoritePolygonsList.splice(state.favoritePolygonsList.getIndexById(action.payload.id), 1, action.payload);
    }
}
