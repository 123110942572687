import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum SidebarActions {
    isSidebarOpen = 'isSidebarOpen'
}

export interface SidebarState {
    isSidebarOpen: boolean;
}

const name = 'sidebar';
const initialState: SidebarState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const sidebarActions = { ...slice.actions };
export const sidebarReducer = slice.reducer;

function createInitialState() {
    return {
        isSidebarOpen: false
    } as SidebarState;
}

function createReducers() {
    return {
        toggleSidebar,
        setIsSidebarOpen
    };

    function toggleSidebar(state: SidebarState, action: PayloadAction<SidebarActions>) {
        state[action.payload] = !state[action.payload];
    }

    function setIsSidebarOpen(state: SidebarState, action: PayloadAction<boolean>) {
        state.isSidebarOpen = action.payload;
    }
}
