import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Translate: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="26.5" viewBox="0 0 29.25 26.5">
            <path
                id="translate"
                d="M19.917,33.25l6.041-15.917h2.709L34.75,33.25H32l-1.417-4.083H24.167L22.708,33.25Zm5.041-6.417h4.709l-2.334-6.5h-.041Zm-15.5,2.459L7.583,27.375l6.709-6.708a23.542,23.542,0,0,1-2.167-2.875,23.758,23.758,0,0,1-1.667-3.084H13.25a15.8,15.8,0,0,0,1.25,2.1,24.766,24.766,0,0,0,1.583,1.98,20.188,20.188,0,0,0,2.479-3.25,19.2,19.2,0,0,0,1.646-3.5H5.5V9.375h9.292V6.75h2.625V9.375h9.291v2.667H22.833a23.621,23.621,0,0,1-1.854,4.52A19.4,19.4,0,0,1,18,20.708l3.208,3.25-1,2.709-4.083-4Z"
                transform="translate(-5.5 -6.75)"
                fill={fill}
            />
        </svg>
    );
};
