import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeleteAreaRequest, RenameAreaRequest } from 'context';
import { Notification } from 'helpers';
import 'helpers/arrayExtension';

enum ToggleModalActions {
    isNotificationsModalOpen = 'isNotificationsModalOpen',
    isUsersToNotifyModalOpen = 'isUsersToNotifyModalOpen'
}

export interface NotificationState {
    isNotificationsModalOpen: boolean;
    isUsersToNotifyModalOpen: boolean;
    notifications: Notification[];
    notificationsToArchive: Notification[];
    filteredNotifications: Notification[];
    areaToRename: RenameAreaRequest;
    areaToDelete: DeleteAreaRequest;
    isNotificationArchived: boolean;
}

export type FilteredNotificationType = Pick<NotificationState, 'filteredNotifications'>;

const MS_IN_3_DAY = 1000 * 3600 * 24 * 3;
const name = 'notification';
const initialState: NotificationState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const notificationActions = { ...slice.actions };
export const notificationReducer = slice.reducer;

function createInitialState() {
    return {
        isNotificationsModalOpen: false,
        isUsersToNotifyModalOpen: false,
        notifications: [],
        notificationsToArchive: [],
        filteredNotifications: [],
        areaToRename: {} as RenameAreaRequest,
        areaToDelete: {} as DeleteAreaRequest,
        isNotificationArchived: false
    };
}

function createReducers() {
    return {
        toggleModal,
        setItems,
        toggleNotifications,
        initializeNotifications,
        addNotification,
        replaceNotification,
        updateUserNotificationStatus,
        archiveNotification,
        unarchiveNotification,
        clear,
        deleteNotifications
    };

    function toggleModal(state: NotificationState, action: PayloadAction<keyof typeof ToggleModalActions>) {
        state[action.payload] = !state[action.payload];
    }

    function setItems(state: NotificationState, action: PayloadAction<{ key: keyof NotificationState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function toggleNotifications(state: NotificationState, action: PayloadAction<boolean>) {
        state.filteredNotifications = [];
        state.areaToDelete = {};
        state.isNotificationArchived = action.payload;
    }

    function initializeNotifications(state: NotificationState, action: PayloadAction<Notification[]>) {
        if (!action.payload) return;
        state.notifications = action.payload;
        checkOutdatedNotifications(state);
    }

    function addNotification(state: NotificationState, action: PayloadAction<Notification>) {
        if (state.notifications.find(n => n.id === action.payload.id)) return;
        const notifications = [...state.notifications];
        notifications.unshift(updateNotificationStatus(action.payload, 'notRead'));
        state.notifications = notifications;
    }

    function replaceNotification(state: NotificationState, action: PayloadAction<Notification>) {
        const index = state.notifications.getIndexById(action.payload.id);
        if (index === -1) {
            addNotification(state, action);
            return;
        }
        state.notifications.splice(index, 1, updateNotificationStatus(action.payload, 'read'));
    }

    function updateUserNotificationStatus(state: NotificationState, action: PayloadAction<Notification[]>) {
        const notifications = [...state.notifications];
        action.payload.forEach(userNotification => {
            const index = notifications.getIndexById(userNotification.id!);
            notifications.splice(index, 1, updateNotificationStatus(notifications[index], 'read'));
        });
        state.notifications = notifications;
    }

    function archiveNotification(state: NotificationState, action: PayloadAction<Notification[]>) {
        const notifications = [...state.notifications];
        action.payload.forEach(notification => {
            const index = notifications.getIndexById(notification.id);
            notifications.splice(index, 1, updateNotificationStatus(notifications[index], 'archived'));
            state.notificationsToArchive.push(notifications[index]);
        });
        state.notifications = notifications;
    }

    function unarchiveNotification(state: NotificationState, action: PayloadAction<Notification[]>) {
        const notifications = [...state.notifications];
        action.payload.forEach(notification => {
            const index = notifications.getIndexById(notification.id);
            notifications.splice(index, 1, updateNotificationStatus(notifications[index], 'notRead'));
            if (state.notificationsToArchive.getIndexById(notification.id) !== -1) state.notificationsToArchive.splice(index);
        });
        state.notifications = notifications;
    }

    function clear(state: NotificationState) {
        state.areaToDelete = {} as DeleteAreaRequest;
        state.areaToRename = {} as RenameAreaRequest;
        state.isUsersToNotifyModalOpen = false;
    }

    function checkOutdatedNotifications(state: NotificationState) {
        const notificationsToArchive = state.notifications.filter(
            notification =>
                new Date(notification.createdAt).getTime() <= Date.now() - MS_IN_3_DAY &&
                notification.UserNotification.status === 'read'
        );
        archiveNotification(state, { payload: notificationsToArchive, type: '' });
    }

    function deleteNotifications(state: NotificationState, action: PayloadAction<Notification[]>) {
        state.notifications = state.notifications.filter(notification => !action.payload.find(nt => nt.id === notification.id));
    }
}

function updateNotificationStatus(notification: Notification, status: string) {
    return { ...notification, UserNotification: { status, notificationId: notification.id } };
}

