import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Layer: SvgIconProps = ({ isFull }) => {
    return (
        <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 24.01">
            <path
                fill={isFull ? colors.primary200 : 'transparent'}
                d="m12.66.12c-.12-.08-.27-.12-.41-.12s-.29.04-.41.12L.34,7.62c-.21.14-.34.38-.34.63s.13.49.34.63l4.79,3.12h0s6.71,4.38,6.71,4.38c.12.08.27.12.41.12s.29-.04.41-.12l6.71-4.38h0l4.79-3.12c.21-.14.34-.38.34-.63s-.13-.49-.34-.63L12.66.12Z"
            />
            <path
                fill={isFull ? colors.primary200 : 'transparent'}
                d="m20.75,12.9l-7.27,4.74c-.37.24-.79.36-1.23.36s-.86-.13-1.23-.37l-7.27-4.74L.34,15.13c-.21.14-.34.37-.34.63s.13.49.34.63l11.5,7.5c.12.08.27.12.41.12s.29-.04.41-.12l11.5-7.5c.21-.14.34-.38.34-.63s-.13-.49-.34-.63l-3.41-2.23Z"
            />

            <path
                fill={isFull ? 'transparent' : colors.gray100}
                id="Path_1383"
                data-name="Path 1383"
                d="m.11,8.64c.05.07.11.13.17.19.02.02.03.04.05.05l11.5,7.5c.25.16.57.16.82,0l11.5-7.5.05-.05c.07-.06.13-.12.18-.19.01-.04.03-.07.05-.11.03-.07.05-.14.05-.21v-.12c0-.08-.02-.16-.06-.23,0-.03-.02-.06-.04-.09-.05-.1-.13-.19-.23-.25L12.66.12c-.25-.16-.57-.16-.82,0L.34,7.62c-.1.06-.18.15-.23.25-.02.03-.02.06-.04.09-.03.07-.05.15-.06.23v.12c0,.07.02.14.05.2.02.04.03.08.05.12H.11ZM12.25,1.65h0l10.13,6.6-10.13,6.61L2.12,8.25,12.25,1.65Z"
            />
            <path
                fill={isFull ? 'transparent' : colors.gray100}
                id="Path_1384"
                data-name="Path 1384"
                d="m24.38,15.37c-.05-.08-.11-.14-.18-.2-.02-.01-.03-.03-.05-.05l-1.95-1.27-1.47-.96-1.37.9,3,1.96-10.13,6.61L2.12,15.75l3-1.96-1.37-.89-2.7,1.76-.71.47-.04.04c-.07.05-.13.12-.18.2-.01.04-.03.07-.05.11-.03.07-.05.14-.05.21v.12c0,.08.02.16.06.23,0,.03.02.06.04.09.05.1.13.19.23.25l11.5,7.5c.25.16.57.16.82,0l11.5-7.5c.1-.06.18-.15.23-.25.02-.03.02-.06.04-.08.03-.07.05-.15.06-.23v-.12c0-.07-.02-.14-.05-.2-.02-.04-.03-.08-.05-.12h-.02Z"
            />
        </svg>
    );
};
