import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { AxiosContext } from './axiosContext';
import { env } from 'environments';
import { bbox, bboxPolygon, featureCollection } from '@turf/turf';

type LayerName = 'italy_regions' | 'italy_provinces' | 'italy_municipalities';

interface EnvironmentLayerApi {
    getRegions: () => Promise<any>;
    getProvinces: (region: string) => Promise<any>;
    getMunicipalities: (province: string) => Promise<any>;
}

const EnvironmentLayerContext = createContext<EnvironmentLayerApi>({} as EnvironmentLayerApi);

const EnvironmentLayerProvider: FC<PropsWithChildren> = ({ children }) => {
    const { getRequest } = useContext(AxiosContext);

    const getCollectionFromGeoserver = useCallback(
        async (layerName: LayerName, cql_filter?: string) => {
            const workspace = 'shared';
            let params: Record<string, string> = {
                request: 'GetFeature',
                VERSION: '2.0.0',
                typeName: `${workspace}:${layerName}`,
                outputFormat: 'application/json'
            };
            if (cql_filter) params.cql_filter = cql_filter;
            const { data } = await getRequest(`${env.reactAppSocketUrl}/geoserver/wfs`, params);
            const bounds = bbox(data);
            const boundsPolygon = bboxPolygon(bounds);
            const collection = { ...data, bbox: boundsPolygon.geometry as any };
            return collection;
        },
        [getRequest]
    );

    const getRegions = async () => {
        return getCollectionFromGeoserver('italy_regions');
    };
    const getProvinces = (region: string) => {
        return getCollectionFromGeoserver('italy_provinces', `cod_reg='${region}'`);
    };
    const getMunicipalities = (province: string) => {
        return getCollectionFromGeoserver('italy_municipalities', `cod_prov='${province}'`);
    };

    return (
        <EnvironmentLayerContext.Provider value={{ getRegions, getProvinces, getMunicipalities }}>
            {children}
        </EnvironmentLayerContext.Provider>
    );
};

export { EnvironmentLayerContext, EnvironmentLayerProvider };
