import { createSlice } from '@reduxjs/toolkit';
export interface TerrainState {
    isTerrain3D: boolean;
}

const name = 'terrain';
const initialState: TerrainState = { isTerrain3D: false };
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const terrainActions = { ...slice.actions };
export const terrainReducer = slice.reducer;

function createReducers() {
    return {
        toggle
    };
}

function toggle(state: TerrainState) {
    state.isTerrain3D = !state.isTerrain3D;
}
