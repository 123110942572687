import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantGeothermal: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="powerPlantGeothermal" xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <rect
                id="Rectangle_1848"
                data-name="Rectangle 1848"
                width="8"
                height="1.5"
                transform="translate(2.75 7.5)"
                fill={fill}
            />
            <rect
                id="Rectangle_1849"
                data-name="Rectangle 1849"
                width="4"
                height="1.5"
                transform="translate(4.75 9.75)"
                fill={fill}
            />
            <path
                id="Path_1424"
                data-name="Path 1424"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,1.5V5.25H1.5V1.5ZM1.5,12V6.75H12V12Z"
                fill={fill}
            />
        </svg>
    );
};
