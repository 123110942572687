import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const User: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.816" height="29.4" viewBox="0 0 16.816 29.4">
            <g id="user2" transform="translate(-749.592 -177.3)">
                <path
                    id="Path_307"
                    data-name="Path 307"
                    d="M758,190.832a6.766,6.766,0,1,0-6.766-6.766A6.773,6.773,0,0,0,758,190.832Zm0-12.038a5.271,5.271,0,1,1-5.271,5.272A5.277,5.277,0,0,1,758,178.794Z"
                    fill={fill}
                />
                <path
                    id="Path_308"
                    data-name="Path 308"
                    d="M758,193.715a8.418,8.418,0,0,0-8.408,8.408V206.7h1.495v-4.577a6.913,6.913,0,1,1,13.826,0V206.7h1.495v-4.577A8.418,8.418,0,0,0,758,193.715Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
