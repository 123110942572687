import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Roles, RolesHierarchy } from 'models';
import { AuthState, RootState } from 'store';

export const withUserPermission =
    <T extends {}>(Component: FC<T>, requiredPermissions: string[]) =>
    (props: T) => {
        const { permissions } = useSelector<RootState>(x => x.auth) as AuthState;

        if (!permissions || requiredPermissions.some(requiredPermission => !permissions.includes(requiredPermission)))
            return null;

        return <Component {...props} />;
    };

export const usePermissions = (requiredPermissions: string[] = []) => {
    const { permissions } = useSelector<RootState>(x => x.auth) as AuthState;
    const hasPermissions = useMemo(
        () => !!permissions && !requiredPermissions.some(requiredPermission => !permissions.includes(requiredPermission)),
        [permissions, requiredPermissions]
    );

    const checkPermission = useCallback((permission: string) => !!permissions && permissions.includes(permission), [permissions]);
    return { hasPermissions, checkPermission };
};

export const checkRole = (role: Roles, roleToCheck: keyof typeof Roles = 'admin') => {
    return RolesHierarchy[role] >= RolesHierarchy[roleToCheck];
};
