import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Home: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.916" height="21.189" viewBox="0 0 26.916 21.189">
            <g id="home" transform="translate(-1032.542 -181.406)">
                <path
                    id="Path_275"
                    data-name="Path 275"
                    d="M1046.507,181.6a.756.756,0,0,0-1.015,0l-12.95,11.955,1.015,1.1,3.817-3.524v10.719a.748.748,0,0,0,.747.747h15.758a.748.748,0,0,0,.747-.747V191.129l3.817,3.524,1.015-1.1Zm6.625,8.148V201.1h-14.264V189.751l7.132-6.585Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
