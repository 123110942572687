import { IconButton } from 'components/buttons';
import { Home, Work } from 'components/icons';
import { RIGHT_TOOLTIP, TOP_RIGHT_TOOLTIP, history } from 'helpers';
import { Roles } from 'models';
import { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AuthState, RootState } from 'store';

type AdminButtonsProps = FC<{ reducer: any }>;

const AdminButtons: AdminButtonsProps = ({ reducer }) => {
    const { user: authUser } = useSelector<RootState>(x => x.auth) as AuthState;
    const isAllowed: boolean = authUser?.role === Roles.superAdmin || authUser?.role === Roles.admin;

    const navigate = useCallback(
        (path: string) => () => {
            history.navigate(path);
            reducer.toggle('isNavbarExtended');
        },
        [reducer]
    );

    return (
        <>
            {isAllowed &&
                (history.location.pathname !== '/backoffice' ? (
                    <IconButton
                        tooltipMessage={'backoffice'}
                        tooltipPosition={RIGHT_TOOLTIP}
                        className="transparent"
                        onClick={navigate('/backoffice')}
                        icon={<Work />}
                    />
                ) : (
                    <IconButton
                        tooltipMessage={'home'}
                        tooltipPosition={TOP_RIGHT_TOOLTIP}
                        className="transparent"
                        onClick={navigate('/')}
                        icon={<Home />}
                    />
                ))}
        </>
    );
};

export default memo(AdminButtons);
