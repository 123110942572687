import { ShapeFile } from './upload/shapefile';
import * as turf from '@turf/turf';
import jszip from 'jszip';
import { v4 } from 'uuid';
import { getFileExtension } from './function';

const getFileNameFromZip = (name: string) => {
    const splitted = name.split('/');
    return splitted[splitted.length - 1];
};

export const getBaseFileName = (name: string) => {
    return name.substring(0, name.lastIndexOf('.'));
};

const mergeFeatureCollections = (featureCollection1: any, featureCollection2: any, name: string) => {
    const geojson = turf.featureCollection([...featureCollection1.features, ...featureCollection2.features]);
    const columns = new Set<string>();
    Object.keys(featureCollection1.features[0].properties).forEach(key => columns.add(key));
    Object.keys(featureCollection2.features[0].properties).forEach(key => columns.add(key));
    const shapeColumns: any[] = [];
    columns.forEach(value => shapeColumns.push(value));
    return {
        ...geojson,
        id: v4(),
        fileName: name,
        bbox: turf.bboxPolygon(turf.bbox(geojson)).geometry,
        centroid: turf.centroid(geojson).geometry,
        shapeColumns
    };
};

const mergeNT2 = async (shapeObjects: ShapeFile[]) => {
    const NODES_STRING = '_nodi_ln';
    const TRENCHES_STRING = '_tratte_ln';
    const shapes: any[] = [];
    const nodesShapes = shapeObjects.filter(shape => shape.name.endsWith(NODES_STRING));
    for (const nodes of nodesShapes) {
        const trenches = shapeObjects.find(shape => shape.name === nodes.name.replace(NODES_STRING, TRENCHES_STRING));
        if (trenches) {
            shapeObjects.splice(shapeObjects.indexOf(nodes), 1);
            shapeObjects.splice(shapeObjects.indexOf(trenches), 1);
            const name = nodes.name.replace(NODES_STRING, '');
            const merged = mergeFeatureCollections(await nodes.getGeoJSON(), await trenches.getGeoJSON(), name);
            shapes.push({ ...merged, fileName: getFileNameFromZip(name) });
        }
    }
    Promise.all(
        shapeObjects.map(async obj => shapes.push({ ...(await obj.getGeoJSON()), fileName: getFileNameFromZip(obj.name) }))
    );
    return shapes;
};

export const findShapeFilesInZip = async (zipFile: File) => {
    let { files: zipEntriesDict } = await jszip.loadAsync(zipFile.arrayBuffer());
    const zipEntries: jszip.JSZipObject[] = [];
    for (const key in zipEntriesDict) zipEntries.push(zipEntriesDict[key]);
    const shpFiles = zipEntries.filter(entry => entry.name.toLowerCase().endsWith('.shp'));
    const shapeObjects: ShapeFile[] = [];
    const errors: jszip.JSZipObject[] = [];
    for (const shp of shpFiles) {
        const name = getBaseFileName(shp.name);
        const dbf = zipEntries.find(entry => entry.name.toLowerCase() === `${name.toLowerCase()}.dbf`);
        const shx = zipEntries.find(entry => entry.name.toLowerCase() === `${name.toLowerCase()}.shx`);
        const prj = zipEntries.find(entry => entry.name.toLowerCase() === `${name.toLowerCase()}.prj`);
        const cpg = zipEntries.find(entry => entry.name.toLowerCase() === `${name.toLowerCase()}.cpg`);

        if (!dbf || !shx) {
            errors.push(shp);
            continue;
        }
        const shape = new ShapeFile({
            name,
            shp: await shp.async('arraybuffer'),
            shx: await shx.async('arraybuffer'),
            dbf: await dbf.async('arraybuffer'),
            prj: prj && (await prj.async('arraybuffer')),
            cpg: cpg && (await cpg.async('arraybuffer'))
        });
        await shape.getGeoJSON();
        shapeObjects.push(shape);
    }
    const shapes = await mergeNT2(shapeObjects);
    return { shapes, errors };
};

export const findShapeFiles = async (files: File[]) => {
    const shpFiles = files.filter(file => file.name.toLowerCase().endsWith('.shp'));
    const shapeObjects: ShapeFile[] = [];
    const errors: { file: File; type: 'missingShp' | 'missingRelated' }[] = [];
    for (const shp of shpFiles) {
        files.splice(files.indexOf(shp), 1);
        const name = getBaseFileName(shp.name);

        const dbf = files.find(file => file.name.toLowerCase() === `${name.toLowerCase()}.dbf`);
        dbf && files.splice(files.indexOf(dbf), 1);

        const shx = files.find(file => file.name.toLowerCase() === `${name.toLowerCase()}.shx`);
        shx && files.splice(files.indexOf(shx), 1);

        const prj = files.find(file => file.name.toLowerCase() === `${name.toLowerCase()}.prj`);
        prj && files.splice(files.indexOf(prj), 1);

        const cpg = files.find(file => file.name.toLowerCase() === `${name.toLowerCase()}.cpg`);
        cpg && files.splice(files.indexOf(cpg), 1);

        if (!dbf || !shx) {
            errors.push({ file: shp, type: 'missingRelated' });
            continue;
        }
        const shape = new ShapeFile({
            name,
            shp: await shp.arrayBuffer(),
            shx: await shx.arrayBuffer(),
            dbf: await dbf.arrayBuffer(),
            prj: prj && (await prj.arrayBuffer()),
            cpg: cpg && (await cpg.arrayBuffer())
        });
        await shape.getGeoJSON();
        shapeObjects.push(shape);
    }
    const shapes = await mergeNT2(shapeObjects);
    const missingShpErrors = files.filter(file => ['shx', 'dbf', 'prj', 'cpg'].includes(getFileExtension(file.name)));
    missingShpErrors.forEach(file => errors.push({ file, type: 'missingShp' }));
    return { shapes, errors };
};

export const loadGeoJSON = async (jsonFile: File) => {
    const name = getBaseFileName(jsonFile.name);
    let res;
    try {
        const geojson = JSON.parse(await jsonFile.text());
        res = {
            ...geojson,
            id: v4(),
            fileName: name,
            sourceFileName: jsonFile.name,
            bbox: (turf.bboxPolygon(turf.bbox(geojson)) as any).geometry,
            centroid: turf.centroid(geojson as any).geometry,
            shapeColumns: Object.keys((geojson as any).features[0].properties)
        };
    } catch (error) {
        return { error: true, source: jsonFile };
    }
    return res;
};
