export const env = {
    apiUrl: process.env.REACT_APP_API_URL || '/api/v1',
    production: process.env.REACT_APP_PRODUCTION === 'true' || false,
    ssoUrl: process.env.REACT_APP_SSO_URL || 'https://sso.gymoo.it',
    reactAppSocketUrl: process.env.REACT_APP_SOCKET_URL || window.location.origin,
    photonApiUrl: process.env.REACT_APP_PHOTON_API_URL || 'http://10.1.1.215:2322',
    wfsUrl: process.env.REACT_APP_WFS_URL || 'http://localhost:8080/geoserver/wfs',
    elasticSearchUrl: process.env.REACT_APP_ELASTIC_SEARCH_URL || 'http://10.1.1.221:9200',
    eagleId: process.env.REACT_APP_EAGLE_ID || '9ec85c48-336d-4076-a7bd-3b69bc1ac8d7'
};
