import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_LAYER_DATA, DEFAULT_TAB_ID, DRAW_LAYER } from 'helpers';
import { DrawPolygonMode, EditableGeoJsonLayer, ViewMode } from 'nebula.gl';
import * as turf from '@turf/turf';
import { FeatureCollection } from 'models';

export interface DrawState {
    isEditMode: boolean;
    drawPolygonLayer: any;
    drawPolygonData: any;
    showClusterSelectModal: boolean;
    clusterSelectFeatureCollections: FeatureCollection[];
    foundFeatureCollections: FeatureCollection[];
}

const name = 'draw';
const initialState: DrawState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const drawActions = { ...slice.actions };
export const drawReducer = slice.reducer;

function createInitialState(): DrawState {
    const drawPolygonLayer = new (EditableGeoJsonLayer as any)({
        id: DRAW_LAYER,
        data: DEFAULT_LAYER_DATA,
        mode: ViewMode,
        tab: DEFAULT_TAB_ID,
        editHandlePointRadiusScale: 4,
        editHandlePointStrokeWidth: 0,
        getEditHandlePointColor: [0, 87, 107],
        getEditHandlePointOutlineColor: [0, 0, 255],
        getEditHandlePointRadius: 10,
        getFillColor: (feature: any) => (feature.geometry.type === 'Point' ? [0, 87, 107] : [0, 143, 193, 50]),
        getLineColor: (feature: any) => (feature.geometry.type === 'Point' ? [0, 87, 107] : [0, 143, 193]),
        getLineWidth: (feature: any) => (feature.geometry.type === 'Point' ? 20 : 1),
        getTentativeFillColor: [0, 143, 193, 50],
        getTentativeLineColor: [0, 143, 193]
    });

    return {
        isEditMode: false,
        drawPolygonData: DEFAULT_LAYER_DATA,
        drawPolygonLayer,
        showClusterSelectModal: false,
        clusterSelectFeatureCollections: [],
        foundFeatureCollections: []
    } as DrawState;
}

function createReducers() {
    return {
        toggleEditMode,
        setDrawPolygonLayer,
        setDrawPolygonData,
        updateLayer,
        toggleVisibility,
        setDrawPolygonVertices,
        toggleClusterSelect,
        setClusterSelectFeatureCollections,
        setFoundFeatureCollections
    };

    function toggleEditMode(state: DrawState, action: PayloadAction<boolean | undefined>): void {
        state.isEditMode = action.payload ?? !state.isEditMode;
        state.drawPolygonLayer = state.drawPolygonLayer.clone({
            mode: state.isEditMode ? DrawPolygonMode : ViewMode,
            pickable: state.isEditMode
        });
    }

    function toggleVisibility(state: DrawState): void {
        state.drawPolygonLayer = state.drawPolygonLayer.clone({ visible: !state.drawPolygonLayer.props.visible });
    }

    function toggleClusterSelect(state: DrawState): void {
        state.showClusterSelectModal = !state.showClusterSelectModal;
    }

    function setDrawPolygonLayer(state: DrawState, action: PayloadAction<any>): void {
        state.drawPolygonLayer = action.payload;
    }

    function setFoundFeatureCollections(state: DrawState, action: PayloadAction<any>): void {
        state.foundFeatureCollections = action.payload;
    }

    function setDrawPolygonData(state: DrawState, action: PayloadAction<any>): void {
        state.drawPolygonData = action.payload;
        state.drawPolygonLayer = state.drawPolygonLayer.clone({ data: action.payload });
        if (action.payload.features.length === 0) state.foundFeatureCollections = [];
    }

    function updateLayer(state: DrawState, action: PayloadAction<any>): void {
        state.drawPolygonLayer = state.drawPolygonLayer.clone({ ...action.payload });
    }

    function setDrawPolygonVertices(state: DrawState, action: PayloadAction<any>): void {
        if (action.payload.features === 0) return;
        const points: any[] = [...action.payload.features[0].geometry.coordinates[0]];
        points.splice(-1);
        const pointsFeat = points.map(point => turf.point(point));
        const features = [action.payload.features[0], ...pointsFeat];
        state.drawPolygonLayer = state.drawPolygonLayer.clone({ data: { ...state.drawPolygonLayer.props.data, features } });
    }

    function setClusterSelectFeatureCollections(state: DrawState, action: PayloadAction<string[]>) {
        const featureCollections = state.foundFeatureCollections.filter(v => action.payload.includes(v.name));
        state.clusterSelectFeatureCollections = featureCollections;
    }
}

