import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NoticeState {
    messages: { id: string; text: string; type: string }[];
}

const name = 'notices';
const initialState: NoticeState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const noticesActions = { ...slice.actions };
export const noticesReducer = slice.reducer;

function createInitialState(): NoticeState {
    return { messages: [] } as NoticeState;
}

function createReducers() {
    return {
        removeMessage,
        setError,
        setSuccess
    };

    function removeMessage(state: NoticeState, action: PayloadAction<string>) {
        const messageIndex = state.messages.findIndex(message => message.id === action.payload);
        if (messageIndex !== -1) state.messages.splice(messageIndex, 1);
    }

    function setError(state: NoticeState, action: PayloadAction<string>) {
        state.messages = [...state.messages, { id: `${action.payload}-${Date.now()}`, text: action.payload, type: 'error' }];
    }

    function setSuccess(state: NoticeState, action: PayloadAction<string>) {
        state.messages = [...state.messages, { id: `${action.payload}-${Date.now()}`, text: action.payload, type: 'success' }];
    }
}
