import { useState, FC, useContext, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMount, useUnmount } from 'hook';
import { UserContext } from 'context';
import { socketActions } from 'store';
import { Person } from 'components/icons';
import { createImageFromInitials, getRandomColor, getImageFromArrayBuffer, getArrayBufferFromBase64 } from 'helpers';
import { dexieDB } from 'dexieDB';
import classes from './ProfilePicture.module.scss';
import SparkMD5 from 'spark-md5';

type ProfilePictureProps = FC<{ id: string }>;

const ProfilePicture: ProfilePictureProps = ({ id }) => {
    const mountTime = Date.now();
    const [imageSrc, setImageSrc] = useState('');
    const { t } = useTranslation();
    const { getUserProfilePicById } = useContext(UserContext);
    const dispatch = useDispatch();

    const loadProfilePicture = async () => {
        if (!id) return;
        const cachedImage = await dexieDB.profilePictures.get(id);
        let hash: string | null = null;
        if (cachedImage) hash = SparkMD5.ArrayBuffer.hash(cachedImage.data);
        const response = await getUserProfilePicById(id, hash);
        if (!response) return;
        let arrayBuffer: ArrayBuffer;
        if (response.status === 206) {
            const responseJsonData = JSON.parse(new TextDecoder().decode(response.data));
            const defaultImage = createImageFromInitials(
                500,
                responseJsonData.firstName as string,
                responseJsonData.lastName as string,
                getRandomColor(responseJsonData.id)
            );
            arrayBuffer = await getArrayBufferFromBase64(defaultImage);
        } else if (cachedImage && response.status === 304) {
            arrayBuffer = cachedImage.data;
        } else {
            arrayBuffer = response.data;
        }
        const base64Image = getImageFromArrayBuffer(arrayBuffer);
        setImageSrc(base64Image);
        if (hash === SparkMD5.ArrayBuffer.hash(arrayBuffer)) return;
        dexieDB.profilePictures.put({
            id,
            data: arrayBuffer
        });
    };

    const createListener = useCallback(() => {
        const listener = (event: any) => {
            if (event.payload.id === id) loadProfilePicture();
        };
        dispatch(
            socketActions.createTaggedListener({
                topic: 'profilePictureChanged',
                tag: mountTime,
                listener,
                socketType: 'socketController'
            })
        );
    }, [dispatch]);

    const removeListener = useCallback(() => {
        dispatch(
            socketActions.removeTaggedListener({
                topic: 'profilePictureChanged',
                tag: mountTime,
                socketType: 'socketController'
            })
        );
    }, [dispatch]);

    useMount(() => {
        loadProfilePicture();
        createListener();
    });

    useUnmount(() => {
        removeListener();
    });

    return imageSrc === '' ? (
        <div className={classes.placeholder_svg}>
            <Person />
        </div>
    ) : (
        <img src={imageSrc} alt={`${t('notifications:senderImgAlt')} ${id}`} id={id} />
    );
};

export default memo(ProfilePicture);
