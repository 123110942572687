import { useMemo, useState } from 'react';

export interface ToggleHandler {
    toggle: () => void;
    on: () => void;
    off: () => void;
}

function useToggle(initialValue = false): [toggle: boolean, handlers: ToggleHandler] {
    const [toggle, setToggle] = useState(initialValue);

    const handlers = useMemo(
        () => ({
            toggle: () => setToggle(toggle => !toggle),
            on: () => setToggle(true),
            off: () => setToggle(false)
        }),
        []
    );

    return [toggle as boolean, handlers as ToggleHandler];
}

export { useToggle };
