import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Topography: SvgIconProps = ({ isFull }) => {
    return (
        <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.5 21.5">
            <path
                d="m28.75,21.5H.75c-.25,0-.49-.13-.63-.34-.14-.21-.16-.48-.05-.72L9.14.44c.11-.25.35-.41.62-.44.27-.03.53.1.68.32l4.01,5.88,3.85.78c.18.04.34.14.46.29l4.99,6.75,1.44-.21c.3-.04.61.1.76.38l3.46,6.19c.13.23.13.52,0,.75s-.38.37-.65.37Z"
                fill={isFull ? colors.primary200 : 'transparent'}
            />
            <path
                d="m28.75,21.5H.75c-.25,0-.49-.13-.63-.34-.14-.21-.16-.48-.05-.72L9.14.44c.11-.25.35-.41.62-.44.27-.03.53.1.68.32l4.01,5.88,3.85.78c.18.04.34.14.46.29l4.99,6.75,1.44-.21c.3-.04.61.1.76.38l3.46,6.19c.13.23.13.52,0,.75s-.38.37-.65.37Zm-26.84-1.5h25.56l-2.58-4.62-1.38.2c-.28.04-.55-.07-.71-.3l-5.08-6.88-3.87-.78c-.19-.04-.36-.15-.47-.31l-3.43-5.03L1.91,20Z"
                fill={isFull ? 'transparent' : colors.gray100}
            />
        </svg>
    );
};
