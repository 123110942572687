import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'models';
import { DrawSearchToggleTypes } from 'helpers';

export interface FeaturesState {
    selectedProject: string;
    selectedFeatureGroup: DrawSearchToggleTypes;
    clickedFeature: string;
    regionProjects: FeatureCollection[];
}

const name = 'features';
const initialState: FeaturesState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const featuresActions = { ...slice.actions };
export const featuresReducer = slice.reducer;

function createInitialState(): FeaturesState {
    return {
        selectedProject: '',
        clickedFeature: '',
        regionProjects: [],
        selectedFeatureGroup: DrawSearchToggleTypes.recents
    } as FeaturesState;
}

function createReducers() {
    return {
        setSelectedProject,
        setSelectedFeatureGroup,
        setClickedFeature,
        setRegionProjects
    };

    function setSelectedProject(state: FeaturesState, action: PayloadAction<string>): void {
        state.selectedProject = action.payload;
    }

    function setSelectedFeatureGroup(state: FeaturesState, action: PayloadAction<DrawSearchToggleTypes>): void {
        state.selectedFeatureGroup = action.payload;
    }

    function setClickedFeature(state: FeaturesState, action: PayloadAction<any>): void {
        state.clickedFeature = action.payload;
    }

    function setRegionProjects(state: FeaturesState, action: PayloadAction<any>): void {
        state.regionProjects = action.payload;
    }
}
