import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './auth.slice';
import { drawReducer } from './draw.slice';
import { favoritesReducer } from './favorites.slice';
import { layerReducer } from './layer.slice';
import { notificationReducer } from './notifications.slice';
import { oimReducer } from './oimMap.slice';
import { popupInfoReducer } from './popupInfo.slice';
import { socketReducer } from './socketIO.slice';
import { terrainReducer } from './terrain.slice';
import { uploadReducer } from './upload.slice';
import { featuresReducer } from './features.slice';
import { areasReducer } from './areas.slice';
import { noticesReducer } from './notice.slice';
import { sidebarReducer } from './sidebar.slice';
import { infrastructuresReducer } from './infrastructures.slice';
import { infrastructuresApi } from './rtk/infrastructure.service';
import { customTablesApi } from './rtk/customTables.service';

export * from './sidebar.slice';
export * from './auth.slice';
export * from './draw.slice';
export * from './favorites.slice';
export * from './layer.slice';
export * from './notifications.slice';
export * from './oimMap.slice';
export * from './popupInfo.slice';
export * from './socketIO.slice';
export * from './terrain.slice';
export * from './upload.slice';
export * from './features.slice';
export * from './areas.slice';
export * from './notice.slice';
export * from './infrastructures.slice';
export * from './rtk/infrastructure.service';
export * from './rtk/customTables.service';

const getStore = () =>
    configureStore({
        reducer: {
            sidebar: sidebarReducer,
            auth: authReducer,
            draw: drawReducer,
            favorites: favoritesReducer,
            layers: layerReducer,
            notification: notificationReducer,
            oim: oimReducer,
            popupInfo: popupInfoReducer,
            socketIO: socketReducer,
            terrain: terrainReducer,
            upload: uploadReducer,
            features: featuresReducer,
            areas: areasReducer,
            infrastructures: infrastructuresReducer,
            [infrastructuresApi.reducerPath]: infrastructuresApi.reducer,
            [customTablesApi.reducerPath]: customTablesApi.reducer,
            notices: noticesReducer
        },
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({ serializableCheck: false })
                .concat(customTablesApi.middleware)
                .concat(infrastructuresApi.middleware)
    });

export let store = getStore();

export type RootState = ReturnType<typeof store.getState>;

export const resetStore = () => {
    store = getStore();
};
