import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { AxiosContext, DefaultResponse } from './axiosContext';
import { Group, GroupCreation, GroupUpdate } from 'models';
import { useDispatch } from 'react-redux';
import { noticesActions } from 'store';

interface GroupApi {
    getAllGroups: () => Promise<void>;
    createGroup: (groupCreation: GroupCreation) => Promise<DefaultWithGroupRes>;
    updateGroup: (groupUpdate: GroupUpdate) => Promise<DefaultWithGroupRes>;
    deleteGroup: (groupsId: string[]) => Promise<DefaultResponse>;
    groups: Group[];
}

interface DefaultWithGroupRes extends DefaultResponse {
    group: Group;
}

const GroupContext = createContext<GroupApi>({} as GroupApi);

const GroupProvider: FC<PropsWithChildren> = ({ children }) => {
    const [groups, setGroups] = useState([] as Group[]);
    const { getRequest, postRequest, deleteRequest, putRequest } = useContext(AxiosContext);
    const dispatch = useDispatch();

    const getAllGroups = useCallback(async () => {
        const { data } = await getRequest(`/groups`);
        setGroups(data);
    }, [getRequest]);

    const createGroup = useCallback(
        async (groupCreation: GroupCreation) => {
            const res = await postRequest(`/groups`, { groupCreation });
            if (!res.data) return;
            setGroups((prev: Group[]) => [...prev, res.data.group]);
            dispatch(noticesActions.setSuccess(res.data?.message));
            return res.data?.message;
        },
        [postRequest, dispatch]
    );

    const updateGroup = useCallback(
        async (groupUpdate: GroupUpdate) => {
            const { data } = await putRequest(`/groups`, { groupUpdate });
            setGroups(prev => {
                const index = prev.findIndex(prev => prev.id === groupUpdate.id);
                prev.splice(index, 1, data.group);
                return [...prev];
            });
            dispatch(noticesActions.setSuccess(data?.message));
            return data;
        },
        [putRequest, dispatch]
    );

    const deleteGroup = useCallback(
        async (groupsId: string[]) => {
            const { data } = await deleteRequest(`/groups`, { groupsId });
            setGroups(groups.filter(group => !groupsId.includes(group.id!)));
            dispatch(noticesActions.setSuccess(data?.message));
            return data;
        },
        [deleteRequest, groups, dispatch]
    );

    return (
        <GroupContext.Provider value={{ getAllGroups, createGroup, deleteGroup, updateGroup, groups }}>
            {children}
        </GroupContext.Provider>
    );
};

export { GroupContext, GroupProvider };

