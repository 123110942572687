import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerTower: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="powerTower"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM5.69,6.75,1.5,10.94V2.56ZM2.56,1.5h8.38L6.75,5.69ZM6.75,7.81,10.94,12H2.56ZM7.81,6.75,12,2.56v8.38L7.81,6.75Z"
                fill={fill}
            />
        </svg>
    );
};
