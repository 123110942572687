import { FC, PropsWithChildren } from 'react';
import Button from '../button/Button';
import { HTMLButtonType } from 'helpers';

type IconTextButtonProps = FC<
    PropsWithChildren<{
        className: string;
        disabled?: boolean;
        icon: JSX.Element;
        onClick: (event?: any) => any;
        type?: HTMLButtonType;
    }>
>;

const IconTextButton: IconTextButtonProps = ({ children, disabled, icon, onClick, className, type }) => {
    return (
        <Button type={type} onClick={onClick} className={`text ${className}`} disabled={disabled}>
            {icon}
            {children}
        </Button>
    );
};

export default IconTextButton;
