import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const SnackbarError: SvgIconProps = ({ fill = colors.alert }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.001" viewBox="0 0 14 14.001">
            <path
                id="Snackbar_-_negativo"
                data-name="Snackbar - negativo"
                d="M7847,2100a7,7,0,1,1,7-7A7.008,7.008,0,0,1,7847,2100Zm0-5.939h0l2.467,2.469,1.063-1.062-2.472-2.468,2.472-2.47-1.062-1.062-2.468,2.47-2.47-2.47-1.062,1.063,2.472,2.47-2.472,2.468,1.062,1.063,2.47-2.469Z"
                transform="translate(-7840 -2086)"
                fill={fill}
            />
        </svg>
    );
};
