import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ZoomIn: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <path
                id="zoom_in"
                d="M-129.75,3248v-10.25H-140v-1.5h10.25V3226h1.5v10.25H-118v1.5h-10.25V3248Z"
                transform="translate(140 -3226)"
                fill={fill}
            />
        </svg>
    );
};
