import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const DottedLine: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4">
            <path
                id="dottedLine"
                d="M5480-4170v-4h2v4Zm-5,0v-4h2v4Zm-5,0v-4h2v4Z"
                transform="translate(-5470 4174)"
                fill={fill}
            />
        </svg>
    );
};
