import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const SnackbarAlert: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14.001" viewBox="0 0 14 14.001">
            <path
                id="SnackbarAlert"
                data-name="Snackbar_alert"
                d="M7847,2100a7,7,0,1,1,7-7A7.008,7.008,0,0,1,7847,2100Zm0-4.5a1,1,0,1,0,1,1A1,1,0,0,0,7847,2095.5Zm-.751-7v6h1.5v-6Z"
                transform="translate(-7840 -2086)"
                fill={fill}
            />
        </svg>
    );
};
