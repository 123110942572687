import { Crs, Point, Polygon } from 'helpers';
import { Project, ProjectFeature } from 'models';

export enum FeatureCollectionDataType {
    buildings = 'Buildings',
    geocoding = 'Geocoding',
    geoMarketing = 'GeoMarketing'
}

export interface FeatureCollectionRef {
    id: string;
    name: string;
    groupName: string;
    bbox: Polygon;
    centroid: Point;
    creator: string;
    dataType: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface FeatureCollection extends FeatureCollectionRef {
    type: 'FeatureCollection';
    crs: Crs;
    centroid: Point;
    bbox: Polygon;
    features: ProjectFeature[];
    creator: string;
    user: FeatureCollectionUser;
}

export interface FeatureCollectionUser {
    firstName: string;
    lastName: string;
    groupId: string;
}

export interface FeatureCollectionProject extends FeatureCollection {
    projects: Project[];
    projectName: string;
}

export interface FeatureCollectionGroupId extends FeatureCollectionProject {
    groupId?: string;
}

export interface GeoJson {
    fileName: string;
    type: 'FeatureCollection';
    features: ProjectFeature[];
}
