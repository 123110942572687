import { CompositeLayer, IconLayer, PickingInfo } from 'deck.gl/typed';
import Supercluster from 'supercluster';
import { getGeometryAnchor, SUPERCLUSTER_BBOX, getIconSize, PROJECTS_CLUSTER_LAYER } from 'helpers';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { drawActions, featuresActions } from 'store';

function getIconName(size: any) {
    if (size === 0) {
        return '';
    }
    if (size <= 10) {
        return `marker-${size}`;
    }

    switch (true) {
        case size < 15:
            return `marker-10`;
        case size < 20:
            return `marker-15`;
        case size < 25:
            return `marker-20`;
        case size < 50:
            return `marker-25`;
        case size < 75:
            return `marker-50`;
        case size < 100:
            return `marker-75`;
        case size < 200:
            return `marker-100`;
        case size < 250:
            return `marker-200`;
        case size < 500:
            return `marker-250`;
        case size < 1000:
            return `marker-500`;
        default:
            return 'marker-1000';
    }
}

export class ProjectsClusterLayer extends CompositeLayer<any> {
    public index: Supercluster;
    newFeatures;
    color;
    dispatch: Dispatch<AnyAction>;
    selectedTab: string;

    constructor(data: any) {
        super(data);
        this.index = new Supercluster();
        this.newFeatures = this.props.data.features.map((feature: any) => {
            const { geometry }: any = getGeometryAnchor(feature);
            return { ...feature, geometry };
        });
        this.index?.load(this.newFeatures);
        this.color = this.props.color();
        this.dispatch = data.dispatch;
        this.selectedTab = data.selectedTab;
    }

    shouldUpdateState({ changeFlags }: any) {
        return changeFlags.somethingChanged;
    }

    updateState({ props, oldProps, changeFlags }: any) {
        const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;
        const z = Math.floor(this.context.viewport.zoom);
        if (rebuildIndex || z !== this.state.z) {
            this.setState({
                data: this.index.getClusters(SUPERCLUSTER_BBOX as any, z),
                z
            });
        }
    }

    onClick(info: PickingInfo, ev: any) {
        if (typeof info.object.id === 'number') {
            const leaves = this.index.getLeaves(info.object.id);
            const selectOptions = leaves.map(v => v.properties.name);
            this.dispatch(drawActions.setClusterSelectFeatureCollections(selectOptions));
            this.dispatch(drawActions.toggleClusterSelect());
        } else {
            const selected = info.object.properties.name;
            this.dispatch(featuresActions.setSelectedProject(selected));
        }
        return true;
    }

    renderLayers(): any {
        const { data } = this.state;
        const { projectsIconAtlas, projectsIconMapping, sizeScale } = this.props;
        return new IconLayer(
            this.getSubLayerProps({
                id: PROJECTS_CLUSTER_LAYER,
                data,
                iconAtlas: projectsIconAtlas,
                iconMapping: projectsIconMapping,
                sizeScale,
                onClick: this.onClick,
                getPosition: (d: any) => d.geometry.coordinates,
                getIcon: (d: any) => getIconName(d.properties?.cluster ? d.properties.point_count : 1),
                getSize: (d: any) => getIconSize(d.properties?.cluster ? d.properties.point_count : 1)
            })
        );
    }
}
