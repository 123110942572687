import { env } from 'environments';
import map_style from './style.json';
import style_base from './style_base';
import style_labels from './style_labels';
import style_oim_petroleum from './style_oim_petroleum';
import style_oim_power from './style_oim_power';
import style_oim_power_heatmap from './style_oim_power_heatmap';
import style_oim_telecoms from './style_oim_telecoms';
import style_oim_water from './style_oim_water';

var oim_layers = style_oim_power.concat(
    style_oim_power_heatmap as any,
    style_oim_petroleum as any,
    style_oim_telecoms as any,
    style_oim_water as any
);

oim_layers.sort((a, b) => {
    if (a['zorder'] < b['zorder']) return -1;
    if (a['zorder'] > b['zorder']) return 1;
    return 0;
});

map_style.sources.openinframap.url = env.reactAppSocketUrl + env.apiUrl + '/oim/config.json';
map_style.sprite = env.reactAppSocketUrl + env.apiUrl + '/oim/sprite';

map_style.layers = style_base.concat(oim_layers as any, style_labels as any) as any;

export { map_style };
