import { FC } from 'react';
import Lottie from 'lottie-react';
import classes from './Spinner.module.scss';
import spinnerAnimation from './spinner.json';

type SpinnerProps = FC<{ isLoading?: boolean }>;

const Spinner: SpinnerProps = ({ isLoading }) => {
    if (!isLoading) return <></>;
    return (
        <div className={classes.container}>
            <Lottie animationData={spinnerAnimation} loop={true} />
        </div>
    );
};

export default Spinner;

