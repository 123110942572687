import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const MeatballMenu: SvgIconProps = ({ fill = colors.epBlack }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="21" viewBox="0 0 5 21">
            <path
                id="meatball_menu"
                d="M0,18a2,2,0,1,1,2,2A2,2,0,0,1,0,18Zm0-8a2,2,0,1,1,2,2A2,2,0,0,1,0,10ZM0,2A2,2,0,1,1,2,4,2,2,0,0,1,0,2Z"
                transform="translate(0.5 0.5)"
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
                fill={fill}
            />
        </svg>
    );
};
