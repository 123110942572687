import { FC } from 'react';
import Button from '../button/Button';
import { CSSPosition, HTMLButtonType } from 'helpers';

type IconButtonProps = FC<{
    type?: HTMLButtonType;
    className: string;
    disabled?: boolean;
    icon: JSX.Element;
    onClick: (param?: any) => any;
    isTooltipNecessary?: boolean;
    tooltipMessage?: string;
    tooltipPosition?: CSSPosition | undefined;
}>;

const IconButton: IconButtonProps = ({
    icon,
    onClick,
    className,
    disabled,
    type = 'button',
    tooltipMessage,
    isTooltipNecessary = true,
    tooltipPosition
}) => {
    return (
        <Button
            tooltipPosition={tooltipPosition}
            tooltipMessage={tooltipMessage}
            isTooltipNecessary={isTooltipNecessary}
            type={type}
            onClick={onClick}
            className={`icon ${className}`}
            disabled={disabled}
        >
            {icon}
        </Button>
    );
};

export default IconButton;
