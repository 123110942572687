import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ResponseTopics, SocketState, socketActions } from 'store';

export const useSocketListener = (
    topics: ResponseTopics[],
    listener: (...args: any[]) => void,
    socketType: keyof SocketState
) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(socketActions.createListeners({ topics, listener, socketType }));
        return () => {
            dispatch(
                socketActions.removeListener({
                    responseTopics: topics,
                    socketType: socketType
                })
            );
        };
    }, [listener]);
};
