import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { EnvironmentalConstraints, GeoJson } from 'models';
import { AxiosContext } from './axiosContext';

interface EnvironmentalConstraintApi {
    getAllEnvironmentalConstraints: () => Promise<void>;
    getEnvironmentalConstraintsById: (environmentalConstraintId: string) => Promise<GeoJson>;
    environmentalConstraints: EnvironmentalConstraints[];
}

const EnvironmentalConstraintContext = createContext<EnvironmentalConstraintApi>({} as EnvironmentalConstraintApi);

const EnvironmentalConstraintProvider: FC<PropsWithChildren> = ({ children }) => {
    const [environmentalConstraints, setEnvironmentalConstraints] = useState<EnvironmentalConstraints[]>([]);
    const { getRequest } = useContext(AxiosContext);

    const getAllEnvironmentalConstraints = useCallback(async () => {
        const { data } = await getRequest(`/environmental-constraints`);
        setEnvironmentalConstraints(data);
    }, [getRequest]);

    const getEnvironmentalConstraintsById = useCallback(
        async (environmentalConstraintId: string) => {
            const { data } = await getRequest(`/environmental-constraints/${environmentalConstraintId}`);
            return data;
        },
        [getRequest]
    );

    return (
        <EnvironmentalConstraintContext.Provider
            value={{ getAllEnvironmentalConstraints, environmentalConstraints, getEnvironmentalConstraintsById }}
        >
            {children}
        </EnvironmentalConstraintContext.Provider>
    );
};

export { EnvironmentalConstraintContext, EnvironmentalConstraintProvider };
