import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Undo: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.975" height="27.507" viewBox="0 0 21.975 27.507">
            <path
                id="undo"
                d="M24.237,16.253H15.573l4.946-4.946-1.06-1.061-6.227,6.227a.748.748,0,0,0,0,1.06l6.227,6.226,1.06-1.06-4.946-4.946h8.664a9.25,9.25,0,1,1,0,18.5H15.762v1.5h8.475a10.75,10.75,0,1,0,0-21.5"
                transform="translate(-13.012 -10.246)"
                fill={fill}
            />
        </svg>
    );
};
