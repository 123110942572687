import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const DrawArea: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.577" height="25.324" viewBox="0 0 27.577 25.324">
            <path
                id="draw_area"
                d="M-136.857,3251.105a.758.758,0,0,1-.21-.654l1.494-8.962a.758.758,0,0,1,.21-.408l14.188-14.192a.768.768,0,0,1,1.062,0l7.47,7.468a.746.746,0,0,1,.219.531.744.744,0,0,1-.219.53l-14.192,14.192a.752.752,0,0,1-.406.21l-8.968,1.495a.736.736,0,0,1-.118.008h0A.753.753,0,0,1-136.857,3251.105Zm14.235-20.645-1.089,1.089-10.425,10.425-1.284,7.692,7.69-1.284,10.426-10.423h0l1.09-1.09,1.98-1.981h0l-6.408-6.406ZM-135.75,3236v-4.25H-140v-1.5h4.251V3226h1.5v4.251H-130v1.5h-4.251V3236Z"
                transform="translate(140 -3226)"
                fill={fill}
            />
        </svg>
    );
};
