import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const MarkerMap: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="25.136" viewBox="0 0 31 25.136">
            <path
                id="marker_map"
                d="M0,16.187l10.387-6,.75,1.3L3,16.187,15.5,23.4,28,16.187l-8.136-4.7.75-1.3,10.386,6L15.5,25.136ZM10.81,8.125a5.416,5.416,0,1,1,9.382,0L15.5,16.25Zm.774-2.708a3.93,3.93,0,0,0,.523,1.959L15.5,13.25l3.393-5.875a3.917,3.917,0,1,0-7.309-1.958Zm1.666.019a2.25,2.25,0,1,1,2.25,2.25A2.253,2.253,0,0,1,13.25,5.436Zm1.5,0a.75.75,0,1,0,.75-.75A.751.751,0,0,0,14.75,5.436Z"
                fill={fill}
            />
        </svg>
    );
};
