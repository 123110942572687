import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantBattery: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="powerPlantBattery" xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="Path_1421"
                data-name="Path 1421"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,12H7.5v-.75h3.25v-1h-8v1H6V12H1.5V1.5H6v.75H2.75v1h8v-1H7.5V1.5H12Z"
                fill={fill}
            />
            <rect
                id="Rectangle_1845"
                data-name="Rectangle 1845"
                width="1"
                height="1.5"
                transform="translate(6.25 6)"
                fill={fill}
            />
            <rect
                id="Rectangle_1846"
                data-name="Rectangle 1846"
                width="5"
                height="1.5"
                transform="translate(4.25 4)"
                fill={fill}
            />
            <rect
                id="Rectangle_1847"
                data-name="Rectangle 1847"
                width="5"
                height="1.5"
                transform="translate(4.25 8)"
                fill={fill}
            />
        </svg>
    );
};
