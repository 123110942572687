import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Table: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.501" viewBox="0 0 21.5 21.501">
            <path
                id="table"
                d="M603.308,588.353a.749.749,0,0,0,.692.465h20a.75.75,0,0,0,.75-.75v-20a.75.75,0,0,0-.75-.75H604a.75.75,0,0,0-.75.75Zm1.449-10.2h5.148v3.833h-5.148Zm6.648,0h5.162v3.833h-5.162Zm11.824-1.5h-5.162v-3.785h5.162Zm-6.662,0h-5.162v-3.785h5.162Zm1.5,1.5h5.162v3.833h-5.162Zm-8.162-5.285v3.785h-5.148v-3.785Zm-5.148,10.618h5.148V587.3h-5.148Zm6.648,0h5.162V587.3h-5.162Zm6.662,0h5.162V587.3h-5.162Zm5.183-14.667v2.5h-18.5v-2.5Z"
                transform="translate(-603.25 -567.317)"
                fill={fill}
            />
        </svg>
    );
};
