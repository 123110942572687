import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerLineRef: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17.497" height="9.5" viewBox="0 0 17.497 9.5">
            <path
                id="powerLineRef"
                d="M4.75,9.5a.749.749,0,0,1-.53-.22l-4-4a.754.754,0,0,1,0-1.06l4-4A.749.749,0,0,1,4.75,0h8a.749.749,0,0,1,.53.22l4,4a.748.748,0,0,1,0,1.06l-4,4a.749.749,0,0,1-.53.22ZM1.81,4.75,5.06,8h7.38l3.25-3.25L12.44,1.5H5.06L1.81,4.75Z"
                transform="translate(-0.003)"
                fill={fill}
            />
        </svg>
    );
};
