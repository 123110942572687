import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const NotifyArchive: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="18.484" viewBox="0 0 26 18.484">
            <defs>
                <clipPath id="notifyArchiveClipPath">
                    <rect id="Rectangle_1660" data-name="Rectangle 1660" width="26" height="18.484" fill="#000a0c" />
                </clipPath>
            </defs>
            <g id="archivio_notifiche" transform="translate(0 0)">
                <g id="Group_548" data-name="Group 548" transform="translate(0 0)" clipPath="url(#notifyArchiveClipPath)">
                    <path
                        id="Path_1339"
                        data-name="Path 1339"
                        d="M25.867,7.865a.777.777,0,0,0-.647-.346H22.244V3.6a.784.784,0,0,0-.783-.783H10.6L8.954.35A.784.784,0,0,0,8.3,0H.783A.784.784,0,0,0,0,.783V17.7l.005.1.01.015a.734.734,0,0,0,.121.332l0,0c.032.047.068.074.069.069a.962.962,0,0,0,.077.072.771.771,0,0,0,.5.2H21.458a.782.782,0,0,0,.727-.492l3.761-9.4a.792.792,0,0,0-.078-.727M4.543,7.519a.782.782,0,0,0-.727.492L1.566,13.632V1.566H7.882L9.53,4.037a.786.786,0,0,0,.652.349h10.5V7.519ZM24.064,9.085l-3.133,7.832H1.941L5.074,9.085Z"
                        transform="translate(0 0)"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};
