import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Italy: SvgIconProps = ({ isFull }) => {
    return (
        <>
            {isFull ? (
                <svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 31.72 36.06">
                    <g id="Icons">
                        <path
                            fill={colors.primary200}
                            d="m31.07,21.66l-2.66-1.86c-.09-.06-.18-.11-.28-.16l-1.72-.72c-.05-.02-.1-.06-.13-.11l-.19-.26c-.73-1-1.8-1.71-3-2.01l-1.03-.25c-1.01-.25-1.88-.9-2.39-1.81l-.73-1.3c-.35-.61-.8-1.14-1.35-1.57l-2.37-1.84-.37-1.01,2.92-2.92c.29-.29.45-.67.45-1.08v-.91c0-.4-.16-.8-.45-1.08L15.43.45c-.28-.28-.68-.45-1.08-.45h-3.09c-.4,0-.79.16-1.08.45l-1.23,1.22h-4.14c-.28,0-.55.08-.78.22L.74,3.85c-.46.27-.74.78-.74,1.31v3.69c0,.41.16.79.45,1.08l1.67,1.67c.49.48,1.24.58,1.83.25l2.1-1.18,2.33.78,1.18,2.87c.3.73.78,1.4,1.37,1.93l4.31,3.83c.58.52,1.28.9,2.03,1.12l.65.19c.4.12.78.3,1.12.54l3.91,2.76,1.33,2.57-.79,3.1c-.07.26,0,.53.2.72l.66.66c.14.14.33.22.53.22.02,0,.04,0,.05,0,.22-.02.42-.12.55-.3l2.65-3.54c.24-.32.36-.69.36-1.09v-.68c0-.69-.38-1.31-.99-1.62l-.2-.1c-.09-.04-.15-.12-.17-.22l-.09-.43c-.01-.07,0-.13.02-.19l.29-.65,3.28,1.74c.23.12.51.12.74-.02.23-.14.36-.38.36-.64v-1.26c0-.5-.24-.96-.65-1.25Z"
                        />
                        <path
                            fill={colors.primary200}
                            d="m20.24,29.58l-2.37.67-2.96-.4c-.81-.11-1.58.33-1.91,1.06-.38.85-.06,1.85.75,2.32l4.43,2.58c.21.12.44.19.69.2l1.15.06s.05,0,.08,0c.74,0,1.38-.54,1.5-1.28l.56-3.49c.08-.52-.1-1.03-.49-1.38-.39-.35-.92-.47-1.43-.33Z"
                        />
                        <path
                            fill={colors.primary200}
                            d="m8.06,20.63l-.45-.39c-.48-.42-1.16-.56-1.77-.36l-1.4.46c-.84.28-1.36,1.12-1.23,1.99l.43,2.95v1.67c0,.28.07.57.2.83l.58,1.15c.12.23.34.38.59.41.02,0,.05,0,.07,0,.23,0,.45-.1.59-.29l.67-.84c.06-.08.15-.12.25-.12h.68c.35,0,.66-.25.73-.59l.75-3.39c.04-.17.05-.35.04-.51l-.12-1.72c-.03-.49-.25-.93-.62-1.25Z"
                        />
                    </g>
                </svg>
            ) : (
                <svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 31.72 36.06">
                    <g id="Icons">
                        <path
                            fill="#fff"
                            d="m31.07,21.66l-2.66-1.86c-.09-.06-.18-.11-.28-.16l-1.72-.72c-.05-.02-.1-.06-.13-.11l-.19-.26c-.73-1-1.8-1.71-3-2.01l-1.03-.25c-1.01-.25-1.88-.9-2.39-1.81l-.73-1.3c-.34-.61-.8-1.14-1.35-1.57l-2.37-1.84-.37-1.01,2.92-2.92c.29-.29.45-.67.45-1.08v-.91c0-.41-.16-.79-.45-1.08L15.43.45c-.28-.28-.68-.45-1.08-.45h-3.09c-.4,0-.79.16-1.08.45l-1.23,1.22h-4.14c-.28,0-.55.07-.78.22L.74,3.85c-.46.27-.74.78-.74,1.31v3.69c0,.41.16.79.45,1.08l1.67,1.67h0c.48.48,1.24.58,1.83.25l2.1-1.18,2.33.78,1.18,2.87c.3.74.78,1.4,1.37,1.93l4.31,3.83c.58.52,1.28.9,2.03,1.12l.65.19c.4.12.78.3,1.12.54l3.91,2.76,1.33,2.57-.79,3.1c-.07.26,0,.53.2.72l.66.66c.14.14.33.22.53.22.02,0,.04,0,.05,0,.22-.02.42-.12.55-.3l2.65-3.54c.24-.32.36-.69.36-1.09v-.68c0-.69-.38-1.31-.99-1.62l-.2-.1c-.09-.04-.15-.12-.17-.22l-.09-.43c-.01-.07,0-.13.02-.19l.23-.67,3.34,1.77c.23.12.51.12.74-.02.23-.14.36-.38.36-.64v-1.26c0-.5-.24-.96-.65-1.25Zm-.85,1.27l-2.24-1.18c-.35-.19-.77-.22-1.15-.09s-.69.41-.85.78l-.31.7c-.16.35-.2.73-.12,1.1l.09.42c.11.55.46,1.01.96,1.26l.2.1c.11.05.17.17.17.29v.68c0,.07-.02.14-.06.19l-1.64,2.19.45-1.77c.09-.36.05-.75-.12-1.08l-1.33-2.55c-.11-.22-.28-.41-.48-.55l-3.9-2.75c-.48-.34-1-.59-1.56-.75l-.65-.19c-.53-.15-1.03-.43-1.45-.8l-4.31-3.83c-.42-.38-.76-.85-.98-1.38l-1.18-2.87c-.17-.41-.51-.73-.93-.87l-2.32-.77c-.16-.05-.32-.08-.48-.08-.26,0-.52.07-.75.2l-2.08,1.18s-.02,0-.03,0l-1.68-1.69v-3.71s3.31-1.97,3.31-1.97h4.14c.4,0,.79-.16,1.08-.45l1.23-1.22h3.11s2.35,2.37,2.35,2.37v.93s-2.92,2.92-2.92,2.92c-.42.43-.56,1.06-.34,1.62l.38.98c.1.26.27.49.49.66l2.36,1.83c.4.31.72.69.97,1.12l.73,1.3c.71,1.26,1.93,2.19,3.34,2.53l1.03.25c.86.21,1.62.72,2.15,1.44l.19.26c.2.27.46.48.76.6l1.72.72,2.67,1.88v.02Z"
                        />
                        <path
                            fill="#fff"
                            d="m20.24,29.58l-2.37.67-2.96-.4c-.79-.11-1.58.33-1.91,1.06-.38.85-.06,1.85.75,2.32l4.43,2.58c.21.12.44.19.68.2l1.15.06s.05,0,.08,0c.74,0,1.38-.54,1.5-1.28l.56-3.49c.08-.52-.1-1.03-.49-1.38-.39-.35-.92-.47-1.43-.33Zm-.15,4.99l-1.16-.06-4.43-2.58c-.14-.08-.2-.26-.13-.41.05-.12.17-.19.29-.19.01,0,.03,0,.04,0l2.96.4c.21.03.41.01.62-.04l2.38-.66s0,.01,0,.02l-.59,3.51Z"
                        />
                        <path
                            fill="#fff"
                            d="m8.06,20.63l-.45-.4c-.48-.42-1.16-.56-1.77-.36l-1.4.46c-.84.28-1.36,1.12-1.23,1.99l.43,2.95v1.67c0,.29.07.57.2.83l.58,1.15c.12.23.34.38.59.41.02,0,.05,0,.07,0,.23,0,.45-.1.59-.29l.67-.84c.06-.08.15-.12.25-.12h.68c.35,0,.66-.25.73-.59l.75-3.38c.04-.17.05-.34.04-.52l-.12-1.72c-.03-.49-.25-.93-.62-1.25Zm-1.39,5.95h-.07c-.53,0-1.02.22-1.37.62l-.06-.11s-.03-.09-.03-.14v-1.67c0-.09,0-.17-.02-.27l-.43-2.9c-.02-.16.07-.3.22-.35l1.4-.46s.07-.02.1-.02c.08,0,.15.03.21.08l.45.4c.06.06.1.13.11.22l.11,1.81-.62,2.79Z"
                        />
                    </g>
                </svg>
            )}
        </>
    );
};
