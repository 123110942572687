import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerTransitionPole: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="powerTransitionPole" xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="Path_1444"
                data-name="Path 1444"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM11.54,1.5,6.75,11.07,1.96,1.5h9.57ZM1.5,3.93,5.54,12H1.5ZM7.96,12,12,3.93V12Z"
                fill={fill}
            />
            <path
                id="Path_1445"
                data-name="Path 1445"
                d="M9,4.57A2.25,2.25,0,1,0,6.75,6.82,2.253,2.253,0,0,0,9,4.57Zm-3,0a.75.75,0,1,1,.75.75A.755.755,0,0,1,6,4.57Z"
                fill={fill}
            />
        </svg>
    );
};
