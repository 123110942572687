import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Compass: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.103"
            height="27.101"
            viewBox="0 0 27.103 27.101"
            style={{ transform: 'rotate(-45deg)' }}
        >
            <g id="compass" transform="translate(-1560.448 -178.45)" fill={fill}>
                <path
                    id="Path_244"
                    data-name="Path 244"
                    d="M1587.332,178.67a.743.743,0,0,0-.832-.156l-17.727,7.879a.735.735,0,0,0-.38.38l-7.879,17.728a.742.742,0,0,0,.154.832.756.756,0,0,0,.527.218.791.791,0,0,0,.305-.064l17.727-7.879a.733.733,0,0,0,.38-.379l7.879-17.729A.74.74,0,0,0,1587.332,178.67Zm-2,2-6.974,15.69-15.69,6.974,6.973-15.691Z"
                />
                <path
                    id="Path_245"
                    data-name="Path 245"
                    d="M1574,194.139a2.126,2.126,0,0,0,1.514-.626,2.141,2.141,0,1,0-3.028,0A2.123,2.123,0,0,0,1574,194.139Zm-.647-2.137a.643.643,0,0,1,.19-.459h0a.649.649,0,0,1,.912,0,.647.647,0,1,1-.915.914A.637.637,0,0,1,1573.352,192Z"
                />
            </g>
        </svg>
    );
};
