import { CSSPosition } from 'helpers';
import { FC, PropsWithChildren, useState } from 'react';
import classes from './Tooltip.module.scss';
import { useTranslation } from 'react-i18next';

type TooltipProps = FC<
    PropsWithChildren<{
        condition: boolean;
        message: string;
        tooltipPosition?: CSSPosition;
    }>
>;

const Tooltip: TooltipProps = ({ children, condition, message, tooltipPosition }) => {
    const { t } = useTranslation('tooltip');
    const [showTooltip, setShowTooltip] = useState(false);
    const handleMouseEnter = () => setTimeout(() => setShowTooltip(true), 1000);

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={() => setShowTooltip(false)} className={classes.container}>
            {children}
            {condition && showTooltip && (
                <div className={classes.tooltip} style={tooltipPosition}>
                    {t(message as any)}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
