import { EffectCallback, useEffect, useRef } from 'react';

export const useUnmount = (callback: EffectCallback) => {
    const callbackRef = useRef(callback);

    callbackRef.current = callback;

    useEffect(() => {
        return () => {
            callbackRef.current();
        };
    }, []);
};
