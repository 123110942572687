import { FC, memo, useCallback, useRef } from 'react';
import { IconButton } from 'components/buttons';
import classes from './UserEdit.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, AuthState, noticesActions, RootState, UpdateUserBody } from 'store';
import { Upload, Edit } from 'components/icons';
import { useTranslation } from 'react-i18next';
import CropImage from './cropImage/CropImage';
import Modal from 'components/modal/Modal';
import { useToggle } from 'hook';
import Form from 'components/form/Form';
import { FieldProps, NAME_PATTERN, RIGHT_TOOLTIP, TOP_LEFT_TOOLTIP } from 'helpers';
import ProfilePicture from 'components/profilePicture/ProfilePicture';
import { UserProvider } from 'context';
import { useCropImageReducer } from './cropImage/CropImage.reducer';

type UserEditProps = FC<{ toggle: () => any; isOpen: boolean }>;

const UserEdit: UserEditProps = ({ toggle, isOpen }) => {
    const { user: authUser } = useSelector<RootState>(x => x.auth) as AuthState;
    const { t } = useTranslation();
    const [isCropOpen, cropHandler] = useToggle(false);
    const cropImageReducer = useCropImageReducer();
    const inputRef = useRef<HTMLInputElement>();
    const dispatch = useDispatch<any>();
    const FIELDS: FieldProps[] = [
        { name: 'firstName', validators: { required: true, pattern: NAME_PATTERN }, value: authUser?.firstName },
        { name: 'lastName', validators: { required: true, pattern: NAME_PATTERN }, value: authUser?.lastName },
        { name: 'email', value: authUser?.email, disabled: true }
    ];

    const onUploadClick = () => {
        inputRef.current?.click();
    };

    const onDropFileHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const filesList = e.target.files;
            if (filesList && filesList.length > 0) {
                const file = filesList[0];
                cropImageReducer.setValue('crop', undefined);
                const reader = new FileReader();
                reader.addEventListener('load', () => cropImageReducer.setValue('imgSrc', reader.result?.toString() || ''));
                reader.readAsDataURL(file);
                cropHandler.on();
            }
        },
        [cropImageReducer, cropHandler]
    );

    const onSubmit = useCallback(
        async (data: UpdateUserBody) => {
            dispatch(authActions.updateUser(data));
            dispatch(noticesActions.setSuccess('user-edit-success'));
            toggle();
        },
        [dispatch, toggle]
    );

    return (
        <>
            <IconButton
                tooltipMessage={'userEdit'}
                tooltipPosition={RIGHT_TOOLTIP}
                className="transparent"
                onClick={toggle}
                icon={<Edit zoom={1.5} />}
            />
            <UserProvider>
                <Modal isOpen={isOpen} handleClose={toggle} title={t('navbar:edit')}>
                    <input
                        className={classes.noDisplay}
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={onDropFileHandler}
                        ref={inputRef as any}
                        placeholder="Upload"
                    />
                    <CropImage
                        isCropImageOpen={isCropOpen}
                        setIsCropImageOpen={cropHandler.off}
                        cropImageReducer={cropImageReducer}
                    />
                    <div className={classes.form}>
                        <div className={classes.form}>
                            <div className={classes.containerPhoto}>
                                <div className={classes.profilePic}>
                                    <ProfilePicture id={authUser!.id as string} />
                                </div>
                                <IconButton
                                    tooltipMessage={'upload'}
                                    tooltipPosition={TOP_LEFT_TOOLTIP}
                                    onClick={onUploadClick}
                                    className="grey"
                                    icon={<Upload fill={'black'} />}
                                />
                            </div>
                            <Form handleSubmitFunction={onSubmit} fields={FIELDS} buttonLabel={t('common:save')} />
                        </div>
                    </div>
                </Modal>
            </UserProvider>
        </>
    );
};

export default memo(UserEdit);
