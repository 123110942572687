import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { DockerProvider, AxiosProvider } from 'context';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <StrictMode>
        <Provider store={store}>
            <AxiosProvider>
                <BrowserRouter>
                    <DockerProvider>
                        <App />
                    </DockerProvider>
                </BrowserRouter>
            </AxiosProvider>
        </Provider>
    </StrictMode>
);
