import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Denied: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.102" height="27.102" viewBox="0 0 27.102 27.102">
            <path
                id="denied"
                d="M374,226.449A13.551,13.551,0,1,0,387.551,240,13.566,13.566,0,0,0,374,226.449Zm0,1.494a11.941,11.941,0,0,1,6.908,2.2l-15.533,18.275A12.049,12.049,0,0,1,374,227.943Zm0,24.114a12.062,12.062,0,0,1-7.518-2.64l15.6-18.346A12.041,12.041,0,0,1,374,252.057Z"
                transform="translate(-360.449 -226.449)"
                fill={fill}
            />
        </svg>
    );
};
