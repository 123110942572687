import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CustomTable } from 'helpers';
import { env } from 'environments';

export const customTablesApi = createApi({
    reducerPath: 'customTablesApi',
    keepUnusedDataFor: 3600,
    baseQuery: fetchBaseQuery({
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}/`,
        prepareHeaders: (headers, api) => {
            headers.set('Authorization', localStorage.getItem('token')!);
            return headers;
        }
    }),
    endpoints: builder => ({
        getCustomTables: builder.query<CustomTable[], void>({
            query: () => `custom-tables`
        })
    })
});

export const { useGetCustomTablesQuery } = customTablesApi;
