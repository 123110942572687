import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Copy: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="copy" xmlns="http://www.w3.org/2000/svg" width="20.4" height="24.9" viewBox="0 0 20.4 24.9">
            <path
                id="Path_86"
                data-name="Path 86"
                d="M767.121,130.659h-13.75v1.5h13v22.9h1.5v-23.65A.752.752,0,0,0,767.121,130.659Z"
                transform="translate(-747.471 -130.659)"
                fill={fill}
            />
            <path
                id="Path_87"
                data-name="Path 87"
                d="M763.521,134.259h-9.9l-.25.021v.034a.654.654,0,0,0-.281.167l-5.4,5.4a.634.634,0,0,0-.167.28h-.054v14.65a.75.75,0,0,0,.75.75h15.3a.751.751,0,0,0,.75-.75v-19.8A.752.752,0,0,0,763.521,134.259Zm-13.49,5.4,2.84-2.838v2.838Zm12.74-3.9v18.3h-13.8v-12.9h4.65a.751.751,0,0,0,.75-.75v-4.65Z"
                transform="translate(-747.471 -130.659)"
                fill={fill}
            />
        </svg>
    );
};
