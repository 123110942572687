import { GroupPermission } from 'models';

export enum Roles {
    user = 'user',
    admin = 'admin',
    superAdmin = 'superAdmin'
}

export enum RolesHierarchy {
    user,
    admin,
    superAdmin
}

export interface UserUpdate {
    id: string;
    firstName: string;
    lastName: string;
    role: Roles;
    groupId: string;
}

type PartialUser = Omit<UserUpdate, 'id'> & Partial<Pick<UserUpdate, 'id'>>;

export interface UserCreation extends PartialUser {
    email: string;
}

export interface UserUpdateGroupName extends User {
    groupName?: string;
}

export interface User extends UserCreation {
    status: 'active' | 'pending' | 'disabled';
    lastLogin: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface UserToken extends User {
    token: string;
    permissions: GroupPermission[];
    tokenExpiration: number;
    groupName: string;
}

export type SharableUser = Pick<UserCreation, 'email' | 'role'>;
