import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Checklist: SvgIconProps = ({ fill = colors.epWhite }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.457" height="22.5" viewBox="0 0 25.457 22.5">
            <g id="checklist" transform="translate(-1128.906 -131.86)">
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M1136.769,131.86v6.581h17.594V131.86Zm1.5,5.081V133.36h14.594v3.581Z"
                    fill={fill}
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M1136.769,146.441h17.594V139.86h-17.594Zm16.094-5.081v3.581h-14.594V141.36Z"
                    fill={fill}
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M1136.769,154.36h17.594v-6.5h-17.594Zm16.094-5v3.5h-14.594v-3.5Z"
                    fill={fill}
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M1131.148,135.436l-1.182-1.182-1.061,1.061,2.242,2.243,4.2-4.2-1.061-1.061Z"
                    fill={fill}
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M1131.148,143.834l-1.182-1.182-1.061,1.061,2.242,2.242,4.2-4.2-1.061-1.061Z"
                    fill={fill}
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M1131.148,151.792l-1.182-1.182-1.061,1.061,2.242,2.242,4.2-4.2-1.061-1.061Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
