import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { AuthState, RootState } from 'store';
import classes from './UserInfo.module.scss';
import { useTranslation } from 'react-i18next';
import ProfilePicture from 'components/profilePicture/ProfilePicture';
import { UserProvider } from 'context';

const UserInfo: FC = () => {
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const { t } = useTranslation();
    if (!user) return <></>;

    return (
        <div className={classes.card}>
            <div className={classes.picture}>
                <UserProvider>
                    <ProfilePicture id={user.id as string} />
                </UserProvider>
            </div>
            <div>
                <p className={classes.userName}>
                    {user.firstName} {user.lastName}
                </p>
                <hr />
                <p className={classes.role}>{t(user.role)}</p>
            </div>
        </div>
    );
};

export default memo(UserInfo);
