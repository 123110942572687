import { FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Nation, PowerLines, PowerPlants, Plant, PowerElementTypes, PowerElement } from 'helpers';
import { env } from 'environments';

export const infrastructuresApi = createApi({
    reducerPath: 'infrastructuresApi',
    keepUnusedDataFor: 3600,
    baseQuery: fetchBaseQuery({
        baseUrl: `${env.reactAppSocketUrl}${env.apiUrl}/`,
        prepareHeaders: (headers, api) => {
            if (api.endpoint !== 'getPowerElements') headers.set('Authorization', localStorage.getItem('token')!);
            return headers;
        }
    }),
    endpoints: builder => ({
        getNations: builder.query<Nation[], {}>({
            query: () => `oim/nations`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).nations
        }),
        getSources: builder.query<string[], {}>({
            query: () => `oim/sources`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).sources
        }),
        getPowerLines: builder.query<PowerLines[], number | undefined>({
            query: nationId => (nationId ? `oim/power-lines/${nationId}` : `oim/power-lines`),
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).powerLines
        }),
        getPowerPlants: builder.query<PowerPlants[], number | undefined>({
            query: nationId => `oim/power-plants/${nationId}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).powerPlants
        }),
        getPowerPlantsBySource: builder.query<PowerPlants[], string>({
            query: source => `oim/power-plants/by-source/${source}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).powerPlants
        }),
        getPlants: builder.query<Plant[], number | undefined>({
            query: nationId => `oim/plants/by-nation/${nationId}`,
            transformResponse: baseQueryReturnValue => (baseQueryReturnValue as any).plants
        }),
        getPlant: builder.query<Plant, number | undefined>({
            query: plantId => `oim/plants/${plantId}`
        }),
        getPowerElements: builder.query<
            PowerElement[],
            { objectType: PowerElementTypes | undefined; plantId: number | undefined }
        >({
            query: ({ objectType, plantId }) => {
                const args: FetchArgs = {
                    url: `https://www.openstreetmap.org/api/0.6/${objectType}/${Math.abs(plantId!)}/full.json`,
                    headers: { origin: 'https://www.openstreetmap.org' }
                };
                return args;
            },
            transformResponse: res => (res as any).elements
        }),
        getNominatimResult: builder.query<any[], string>({
            query: queryParam => {
                const args: FetchArgs = {
                    url: `https://nominatim.openstreetmap.org/search`,
                    params: {
                        q: queryParam,
                        format: 'json'
                    }
                };
                return args;
            }
        })
    })
});

export const {
    useGetNationsQuery,
    useGetSourcesQuery,
    useGetPowerLinesQuery,
    useGetPowerPlantsQuery,
    useGetPowerPlantsBySourceQuery,
    useGetPlantsQuery,
    useGetPlantQuery,
    useGetPowerElementsQuery,
    useGetNominatimResultQuery,
    useLazyGetNominatimResultQuery
} = infrastructuresApi;
