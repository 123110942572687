import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const AddFile: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40.964" height="50" viewBox="0 0 40.964 50">
            <g id="add_file" transform="translate(-747.471 -130.659)">
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M752.021,151.2h6.225v6.225h3.012V151.2h6.225v-3.012h-6.225v-6.225h-3.012v6.225h-6.225Z"
                    transform="translate(4.586 11.391)"
                    fill={fill}
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M780.981,130.659h-27.61v3.012h26.1v45.984h3.012v-47.49A1.509,1.509,0,0,0,780.981,130.659Z"
                    transform="translate(5.947)"
                    fill={fill}
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M779.7,134.259H759.82l-.5.042v.068a1.312,1.312,0,0,0-.564.335l-10.839,10.839a1.274,1.274,0,0,0-.335.562h-.109v29.418a1.507,1.507,0,0,0,1.506,1.506H779.7a1.508,1.508,0,0,0,1.506-1.506V135.765A1.509,1.509,0,0,0,779.7,134.259ZM752.612,145.1l5.7-5.7v5.7Zm25.582-7.831v36.747H750.483v-25.9h9.337a1.508,1.508,0,0,0,1.506-1.506v-9.337Z"
                    transform="translate(0 3.629)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
