import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowUp: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.416" height="5.958" viewBox="0 0 10.416 5.958">
            <path
                id="arrow_up"
                d="M5.956,1.062,4.9,0,.22,4.677a.751.751,0,0,0,0,1.062L4.9,10.416,5.958,9.354,1.81,5.208Z"
                transform="translate(10.416 0) rotate(90)"
                fill={fill}
            />
        </svg>
    );
};
