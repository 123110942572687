import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Archive: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28.4" height="21.17" viewBox="0 0 28.4 21.17">
            <path
                id="archive"
                d="M147.426,233.546H132.412l-1.85-3.7a.781.781,0,0,0-.693-.427h-9.3a.774.774,0,0,0-.774.773v19.624a.774.774,0,0,0,.774.773h26.852a.774.774,0,0,0,.774-.773V234.32A.775.775,0,0,0,147.426,233.546Zm-.774,5.679v9.813h-25.3v-9.813Zm0-4.131v2.583h-25.3v-6.715h8.044l1.849,3.7a.781.781,0,0,0,.694.428Z"
                transform="translate(-119.8 -229.415)"
                fill={fill}
            />
        </svg>
    );
};
