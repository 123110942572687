import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantSolar: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="powerPlantSolar" xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="Path_1429"
                data-name="Path 1429"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,1.5v7H1.5v-7ZM1.5,12V10H12v2Z"
                fill={fill}
            />
            <path
                id="Path_1430"
                data-name="Path 1430"
                d="M2.25,6V7.5h3A.755.755,0,0,0,6,6.75v-3H4.5V4.94L2.78,3.22,1.72,4.28,3.44,6Z"
                fill={fill}
            />
            <path
                id="Path_1431"
                data-name="Path 1431"
                d="M7.25,6V7.5h3A.755.755,0,0,0,11,6.75v-3H9.5V4.94L7.78,3.22,6.72,4.28,8.44,6H7.25Z"
                fill={fill}
            />
        </svg>
    );
};
