import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const StarEmpty: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.787" height="25.476" viewBox="0 0 26.787 25.476">
            <path
                id="star_empty"
                d="M1059.08,140.034h-10.232l-3.161-9.731-3.162,9.731h-10.232l8.278,6.014-3.162,9.731,8.278-6.014,8.277,6.014-3.162-9.731Zm-16.746,5.442-5.424-3.942h6.7l2.073-6.377,2.071,6.377h6.7l-5.424,3.942,2.072,6.376-5.424-3.941-5.425,3.941Z"
                transform="translate(-1032.293 -130.303)"
                fill={fill}
            />
        </svg>
    );
};
