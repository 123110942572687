import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Converter: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30.154" height="30.396" viewBox="0 0 30.154 30.396">
            <defs>
                <clipPath id="converterClipPath">
                    <rect
                        id="Rectangle_883"
                        data-name="Rectangle 883"
                        width="30.154"
                        height="30.396"
                        transform="translate(0 0)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g id="converter" transform="translate(0 0)">
                <g id="Group_509" data-name="Group 509" clipPath="url(#converterClipPath)">
                    <path
                        id="Path_1095"
                        data-name="Path 1095"
                        d="M4.911,23.969A13.4,13.4,0,0,1,1.859,15.2,12.729,12.729,0,0,1,3.375,9.175,14.945,14.945,0,0,1,7.68,4.2V9.5H9.539V2.041a.91.91,0,0,0-.909-.91H1.172V2.95H6.265A18.051,18.051,0,0,0,1.637,8.569,14.2,14.2,0,0,0,0,15.2a15.63,15.63,0,0,0,.97,5.537,15.36,15.36,0,0,0,2.668,4.588A14.922,14.922,0,0,0,7.68,28.658,14.468,14.468,0,0,0,12.773,30.4V28.537a12.705,12.705,0,0,1-7.862-4.568"
                        fill={fill}
                    />
                    <path
                        id="Path_1096"
                        data-name="Path 1096"
                        d="M28.517,21.847A14.318,14.318,0,0,0,30.154,15.2,15.05,15.05,0,0,0,26.536,5.072,14.589,14.589,0,0,0,17.381,0V1.859a12.7,12.7,0,0,1,7.862,4.568A13.4,13.4,0,0,1,28.3,15.2a12.725,12.725,0,0,1-1.516,6.022,14.937,14.937,0,0,1-4.3,4.972V20.9H20.615v7.458a.909.909,0,0,0,.909.909h7.458V27.445H23.889a17.551,17.551,0,0,0,4.628-5.6"
                        fill={fill}
                    />
                    <path
                        id="Path_1097"
                        data-name="Path 1097"
                        d="M14.607,11H12.532l-1.66,2.747L9.223,11H7.149l2.624,4.114L7.014,19.55H9.089l1.734-2.941,1.769,2.941H14.68l-2.746-4.3Z"
                        fill={fill}
                    />
                    <path
                        id="Path_1098"
                        data-name="Path 1098"
                        d="M21.175,11l-1.93,3.772L17.318,11H15.352l3.027,5.737-.013,2.808h1.758V16.73L23.14,11Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};
