import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const AddedArea: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30.694" height="25.5" viewBox="0 0 30.694 25.5">
            <defs>
                <clipPath id="addedAreaClipPath">
                    <rect
                        id="Rectangle_936"
                        data-name="Rectangle 936"
                        width="30.694"
                        height="25.5"
                        transform="translate(0 -0.001)"
                        fill={fill}
                    />
                </clipPath>
            </defs>
            <g id="areaa_aggiunta" transform="translate(0 0.001)">
                <path
                    id="Path_1130"
                    data-name="Path 1130"
                    d="M8.25,4.934v8.249H0v1.5H8.25v8.25h1.5v-8.25H18v-1.5H9.749V4.934Z"
                    fill={fill}
                />
                <g id="Group_544" data-name="Group 544">
                    <g id="Group_543" data-name="Group 543" clipPath="url(#addedAreaClipPath)">
                        <path
                            id="Path_1131"
                            data-name="Path 1131"
                            d="M21.373,0a9.332,9.332,0,0,0-9.321,9.321,10.488,10.488,0,0,0,.3,2.363h1.538a9.413,9.413,0,0,1-.341-2.363,7.821,7.821,0,1,1,15.642,0c0,5.37-5.976,12.317-7.821,14.333a44.785,44.785,0,0,1-5.549-7.47H14.108a44.524,44.524,0,0,0,6.73,9.091.749.749,0,0,0,1.07,0c.359-.365,8.786-9.022,8.786-15.954A9.332,9.332,0,0,0,21.373,0"
                            fill={fill}
                        />
                        <path
                            id="Path_1132"
                            data-name="Path 1132"
                            d="M21.373,12.755A3.433,3.433,0,1,0,17.94,9.322a3.437,3.437,0,0,0,3.433,3.433m0-5.366A1.933,1.933,0,1,1,19.44,9.322a1.936,1.936,0,0,1,1.933-1.933"
                            fill={fill}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
