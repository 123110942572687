import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Hamburger: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="hamburger" xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
            <rect
                id="Rectangle_849"
                data-name="Rectangle 849"
                width="14"
                height="1.5"
                transform="translate(3 7.25)"
                fill={fill}
            />
            <rect id="Rectangle_850" data-name="Rectangle 850" width="8" height="1.5" transform="translate(6 14.5)" fill={fill} />
            <rect id="Rectangle_851" data-name="Rectangle 851" width="20" height="1.5" fill={fill} />
        </svg>
    );
};
