import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerGeneratorWind: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.006" height="13.188" viewBox="0 0 10.006 13.188">
            <g id="powerGeneratorWind" transform="translate(0.006 -0.002)">
                <path
                    id="Path_1435"
                    data-name="Path 1435"
                    d="M9.89,9.53,7.43,5.49,8.83.97A.752.752,0,0,0,8.41.06a.758.758,0,0,0-.95.33L5.19,4.54.58,5.58A.74.74,0,0,0,0,6.4a.753.753,0,0,0,.74.66H.76l4.73-.11L8.7,10.42a.751.751,0,0,0,.55.24.738.738,0,0,0,.45-.15.749.749,0,0,0,.19-.99Z"
                    fill={fill}
                />
                <path
                    id="Path_1436"
                    data-name="Path 1436"
                    d="M4.81,7.69,3.77,12.28a.717.717,0,0,0,.15.63.744.744,0,0,0,.59.28h3a.752.752,0,0,0,.74-.91l-.27-1.17L4.82,7.7Zm.63,4L6,9.22l.56,2.47Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
