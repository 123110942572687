import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { User, UserCreation, UserUpdate } from 'models';
import { AxiosContext, DefaultResponse } from './axiosContext';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { noticesActions } from 'store';

interface UserApi {
    createUser: (userCreation: UserCreation) => Promise<DefaultWithUserRes>;
    deleteUser: (usersId: string[]) => Promise<DefaultResponse>;
    getAllUsers: () => Promise<void>;
    getBackofficeUsers: () => Promise<void>;
    getUserProfilePicById: (id: string, hash: string | null) => Promise<AxiosResponse<any, any>>;
    updateUser: (userUpdate: UserUpdate) => Promise<DefaultWithUserRes>;
    users: any[];
}

interface DefaultWithUserRes extends DefaultResponse {
    user: User;
}

const UserContext = createContext<UserApi>({} as UserApi);

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
    const [users, setUsers] = useState<User[]>([]);
    const { getRequest, postRequest, deleteRequest, putRequest, default: axios } = useContext(AxiosContext);
    const dispatch = useDispatch();

    const getAllUsers = useCallback(async () => {
        const { data } = await getRequest(`/users`);
        setUsers(data);
    }, [getRequest]);

    const getBackofficeUsers = useCallback(async () => {
        const { data } = await getRequest('/users/backoffice-users');
        setUsers(data);
    }, [getRequest]);

    const getUserProfilePicById = useCallback(
        async (id: string, md5hash: string | null) => {
            const response = await axios.get(`/users/image/${id}`, {
                params: {
                    hash: md5hash
                },
                responseType: 'arraybuffer'
            });
            return response;
        },
        [axios]
    );

    const createUser = useCallback(
        async (userCreation: UserCreation) => {
            const { data } = await postRequest(`/users`, { userCreation });
            setUsers((prev: User[]) => [...prev, data.user]);
            dispatch(noticesActions.setSuccess(data?.message));

            return data;
        },
        [postRequest, dispatch]
    );

    const deleteUser = useCallback(
        async (usersId: string[]) => {
            const { data } = await deleteRequest(`/users`, { usersId });
            setUsers(users.filter(user => !usersId.includes(user.id!)));
            dispatch(noticesActions.setSuccess(data?.message));

            return data;
        },
        [deleteRequest, dispatch, users]
    );

    const updateUser = useCallback(
        async (userUpdate: UserUpdate) => {
            const { data } = await putRequest(`/users`, { userUpdate });
            setUsers(prev => {
                const index = prev.findIndex(prev => prev.id === userUpdate.id);
                prev.splice(index, 1, data.user);
                return [...prev];
            });
            dispatch(noticesActions.setSuccess(data?.message));

            return data;
        },
        [putRequest, dispatch]
    );

    return (
        <UserContext.Provider
            value={{
                createUser,
                deleteUser,
                getAllUsers,
                getBackofficeUsers,
                getUserProfilePicById,
                updateUser,
                users
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
