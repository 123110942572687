import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ZoomOut: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="1.5" viewBox="0 0 22 1.5">
            <rect id="zoom_out" width="1.5" height="22" transform="translate(0 1.5) rotate(-90)" fill={fill} />
        </svg>
    );
};
