import socketio, { Socket } from 'socket.io-client';
import { env } from 'environments';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialSocketState {
    socketController: Socket | any;
    socketBe: Socket | any;
}

export interface SocketState extends InitialSocketState {
    socketController: Socket;
    socketBe: Socket;
}

export type MessageTopics = 'login' | 'sendEmail' | 'logout';
export type ResponseTopics =
    | 'client'
    | 'fileCreated'
    | 'projectUploaded'
    | 'addNotification'
    | 'updateNotification'
    | 'featureCollectionRenamed'
    | 'featureCollectionDeleted'
    | `profilePictureChanged`;

const name = 'socketIO';
const initialState: InitialSocketState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const socketActions = { ...slice.actions };
export const socketReducer = slice.reducer;

function createInitialState() {
    const socketController = socketio(env.reactAppSocketUrl, { path: '/controller', autoConnect: false, reconnection: true });
    socketController.io.on('reconnect', () => {
        window.dispatchEvent(new Event('socket_reconnection'));
    });
    socketController.connect();
    return {
        socketController,
        socketBe: undefined
    } as InitialSocketState;
}

function createReducers() {
    return {
        login,
        sendMessage,
        createListeners,
        removeListener,
        createTaggedListener,
        removeTaggedListener
    };

    function login(state: SocketState, action: PayloadAction<{ groupName: string; email: string }>) {
        const { email, groupName } = action.payload;
        if (!groupName) return;
        state.socketController.emit('msg', { topic: 'login', payload: { email, groupName: groupName?.toLowerCase() } });
        if (state.socketBe?.active) state.socketBe.close();
        const socketBe = socketio(env.reactAppSocketUrl, { path: `/be/${groupName.toLowerCase()}`, autoConnect: false });
        socketBe.on('connect', () => {
            socketBe.emit('msg', { topic: 'login', payload: { email, groupName: groupName?.toLowerCase() } });
        });
        state.socketBe = socketBe;
        socketBe.connect();
    }

    function sendMessage(
        state: SocketState,
        action: PayloadAction<{ topic: MessageTopics; payload: any; socketType: keyof SocketState }>
    ) {
        state[action.payload.socketType].emit('msg', action.payload);
    }

    function createListeners(
        state: SocketState,
        action: PayloadAction<{
            topics: ResponseTopics[];
            listener: (...args: any[]) => void;
            socketType: keyof InitialSocketState;
        }>
    ) {
        action.payload.topics.forEach(topic => state[action.payload.socketType]?.on(topic, action.payload.listener));
    }

    function removeListener(
        state: SocketState,
        action: PayloadAction<{ responseTopics: ResponseTopics[]; socketType: keyof SocketState }>
    ) {
        action.payload.responseTopics.forEach(listener => {
            state[action.payload.socketType]?.removeListener(listener);
        });
    }

    function createTaggedListener(
        state: SocketState,
        action: PayloadAction<{
            topic: ResponseTopics;
            listener: (...args: any[]) => void;
            socketType: keyof InitialSocketState;
            tag: number;
        }>
    ) {
        const taggedListener = action.payload.listener as any;
        taggedListener.tag = action.payload.tag;
        state[action.payload.socketType]?.on(action.payload.topic, taggedListener);
    }

    function removeTaggedListener(
        state: SocketState,
        action: PayloadAction<{
            topic: ResponseTopics;
            socketType: keyof InitialSocketState;
            tag: number;
        }>
    ) {
        const listeners = state[action.payload.socketType]?.listeners(action.payload.topic);
        const taggedListener = listeners.find((l: any) => l.tag === action.payload.tag);
        if (!taggedListener) return;
        state[action.payload.socketType].off(action.payload.topic, taggedListener);
    }
}
