import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const LongArrow: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.278" height="10" viewBox="0 0 16.278 10">
            <path
                id="longArrow"
                d="M10.036,4.415,5.621,0,4.45,1.171l3,3H-6V5.827H7.452l-3,3L5.621,10l4.415-4.415a.828.828,0,0,0,0-1.171"
                transform="translate(6)"
                fill={fill}
            />
        </svg>
    );
};
