import { FlyToInterpolator } from 'deck.gl/typed';
import { env } from 'environments';
import { map_style } from 'views/map/tileSelector/openInfraMap/oim_tile_map';
import { iconAtlas, iconMapping, projectsIconAtlas, projectsIconMapping } from 'assets';
import { randomColor } from './function';
import { SkyLayer } from 'mapbox-gl';

export const BOTTOM_TOOLTIP = { bottom: '50px' };
export const RIGHT_TOOLTIP = { right: '50px' };
export const LEFT_TOOLTIP = { left: '50px' };
export const TOP_LEFT_TOOLTIP = { top: '0', left: '50px' };
export const BOTTOM_LEFT_TOOLTIP = { bottom: '0', left: '50px' };
export const TOP_RIGHT_TOOLTIP = { top: '0', right: '50px' };
export const Fabbricati = ['ALT'];
export const Geomarketing = [
    'NOME',
    'DUG',
    'DUF',
    'CIVICO',
    'ESPONENTE',
    'P_IVA',
    'ATECO',
    'DESCR_ATEC',
    'FORM_LEGAL',
    'CAPITALE',
    'BILANCIO',
    'UTIL_BIL',
    'n_DIPEN',
    'EMAIL',
    'n_TELEFONO',
    'CEO',
    'CODFIS_CEO',
    'FOGLIO',
    'PARTICELLA',
    'UI_TOTALI',
    'TIPOLOGIA_',
    'CATEGORIA',
    'COMUNE'
];
export const Geocoding = [
    'DUF',
    'DUG',
    'FOG.',
    'NCR2',
    'STAT',
    'PART.',
    'CIVICO',
    'ORIGINE',
    'QUALITA',
    'EGON_CIV',
    'EGON_STR',
    'CATEGORIA',
    'DISTINTA U',
    'U.I. TOTAL',
    'UI_BUSINES',
    'UI_COMMERC',
    'UI_RESIDEN',
    'VERIFICATO'
];
export const COLUMNS_TYPE = { Geocoding, Geomarketing, Fabbricati };
export const EAGLE_PROJECTS = 'EagleProjects S.p.A.';
export const BUILDING_HEIGHT = 'altezzaBuildings';
export const DRAW_LAYER = 'draw-polygon-layer';
export const OIM_LAYER = 'oim-layer';
export const BUILDINGS_LAYER = 'buildings-layer';
export const POWER_PLANTS_LAYER = 'power-plants-layer';
export const SEARCHED_LAYER = 'searched-layer';
export const REGIONI_LAYER = 'regioni-layer';
export const REGIONI_ID = [
    'Abruzzo',
    'Basilicata,',
    'Calabria',
    'Campania',
    'Emilia-Romagna',
    'Friuli Venezia Giulia',
    'Lazio',
    'Liguria',
    'Lombardia',
    'Marche',
    'Molise',
    'Piemonte',
    'Puglia',
    'Sardegna',
    'Sicilia',
    'Toscana',
    'Trentino-Alto Adige',
    'Umbria',
    `Valle d'Aosta`,
    'Veneto'
];
export const SELECTED_FEATURE_LAYER = 'selected-feature-layer';
export const GEOCODING_LAYER = 'geocoding-layer';
export const ITALY_API = env.reactAppSocketUrl + env.apiUrl + '/projects/italy';
export const PROJECT_API = env.reactAppSocketUrl + env.apiUrl + '/projects/by-name/';
export const CTRL_Z_CODE = 'KeyZ';
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiY2l1Y2NpIiwiYSI6ImNsNm5mMm5wZzAwOGMzanQxMGViNmhpZW0ifQ.Kz9xHYpzI8MeIAmLFvRIjQ';
export const MAPBOX_LAYER = `https://a.tiles.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.vector.pbf?access_token=${MAPBOX_TOKEN}`;
export const SUPERCLUSTER_BBOX = [-180, -85, 180, 85];
export const DEFAULT_TAB_ID = 'home';
export const FEATURE_COLLECTION_TYPE = 'FeatureCollection';
export const FEATURE_TYPE = 'Feature';

export const AVAILABLE_LAYER_COLORS = [
    [255, 255, 255],
    [0, 143, 193],
    [0, 87, 107],
    [14, 229, 152],
    [0, 135, 83],
    [237, 237, 21],
    [116, 108, 0],
    [219, 11, 61],
    [97, 3, 2]
];

export const getAvailableColor = () => AVAILABLE_LAYER_COLORS[Math.floor(Math.random() * (AVAILABLE_LAYER_COLORS.length - 0))];

export const DEFAULT_LAYER_VALUE = {
    pickable: true,
    stroked: true,
    filled: true,
    tab: DEFAULT_TAB_ID,
    pointType: 'circle',
    lineWidthMinPixels: 0.5,
    getFillColor: [128, 128, 128, 50],
    getPointRadius: 0.5,
    getLineWidth: 0.1
};
export const DEFAULT_ICONCLUSTER_LAYER_VALUE = {
    pickable: true,
    iconAtlas,
    iconMapping,
    sizeScale: 40,
    getLineWidth: 0.2,
    getPointRadius: 1,
    checked: true,
    tab: DEFAULT_TAB_ID,
    getPosition: (d: any) => d.coordinates
};
export const PROJECTS_CLUSTER_LAYER = 'projects-cluster-layer';
export const DEFAULT_PROJECTS_CLUSTER_LAYER_VALUE = {
    pickable: true,
    projectsIconAtlas,
    projectsIconMapping,
    sizeScale: 40,
    getLineWidth: 0.2,
    getPointRadius: 0.5,
    tab: DEFAULT_TAB_ID,
    color: randomColor,
    getPosition: (d: any) => d.coordinates
};
export const DEFAULT_INITIAL_VIEW_STATE = {
    latitude: 45.35894091,
    longitude: 9.44917897,
    zoom: 6,
    maxPitch: 85,
    pitch: 0,
    bearing: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
    minZoom: 2
};
export const DEFAULT_LAYER_DATA = {
    type: 'FeatureCollection',
    name: 'polygon',
    crs: {
        type: 'name',
        properties: { name: 'urn:ogc:def:crs:EPSG::6706' }
    },
    features: [] as any[]
};
export const MapStyle = {
    MAP_STYLE_STREETS: 'mapbox://styles/mapbox/streets-v12',
    MAP_STYLE_OUT_DOORS: 'mapbox://styles/mapbox/outdoors-v12',
    MAP_STYLE_LIGHT: 'mapbox://styles/ciucci/cleczgflm000501o5nbf7a8n5',
    MAP_STYLE_LIGHT_3D: 'mapbox://styles/ciucci/cleczd1bj001z01s49i9a98v9',
    MAP_STYLE_DARK: 'mapbox://styles/ciucci/cleczhqsn000901pdt46gvqgp',
    MAP_STYLE_DARK_3D: 'mapbox://styles/ciucci/clecza14c000901pgymlufx5w',
    MAP_STYLE_SATELLITE: 'mapbox://styles/mapbox/satellite-v9',
    MAP_STYLE_STREETS_SATELLITE: 'mapbox://styles/mapbox/satellite-streets-v12',
    MAP_STYLE_BUILDINGS_SATELLITE: 'mapbox://styles/ciucci/cldspqktt007401set45ttz65',
    MAP_STYLE_DAY_NAVIGATION: 'mapbox://styles/mapbox/navigation-day-v1',
    MAP_STYLE_NIGHT_NAVIGATION: 'mapbox://styles/mapbox/navigation-night-v1',
    MAP_STYLE_OPEN_INFRA_MAP: map_style as unknown as string
};
export const REJECT_BUTTON_ACTIONS = ['none', 'added', 'renamed', 'deleted', 'expired'];
export const REJECT_BUTTON_STATUS = ['completed', 'rejected'];
export const SKY_LAYER: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15
    }
};

export const REGION_NAMES = [
    'Piemonte',
    'Lombardia',
    'Trentino-Alto Adige',
    'Veneto',
    'Friuli Venezia Giulia',
    'Emilia-Romagna',
    'Toscana',
    'Liguria',
    'Umbria',
    'Marche',
    'Lazio',
    'Molise',
    'Abruzzo',
    'Puglia',
    'Campania',
    'Basilicata',
    'Calabria',
    'Sicilia',
    'Sardegna',
    "Valle d'Aosta"
];

export const DEFAULT_PATTERN = /^[^\\^/^:^*^?^"^<^>^|]+$/;
export const NAME_PATTERN = /^[a-zA-Z èéòàùì]+$/;
export const EMAIL_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const PIVA_PATTERN = /^[0-9]{11}$/;

export const FILE_UPLOAD_SIZE_LIMIT = 45 * 1024 * 1024;
