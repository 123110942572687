import { DEFAULT_PROJECTS_CLUSTER_LAYER_VALUE, PROJECTS_CLUSTER_LAYER, ProjectsClusterLayer } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { DrawState, FeaturesState, LayerState, RootState, featuresActions, layerActions } from 'store';
import { FeatureCollection as GeoJSONFeatureCollection } from 'geojson';
import { useEffect, useState } from 'react';

const useProjectsClusterLayer = () => {
    const { selectedTab, layers } = useSelector<RootState>(x => x.layers) as LayerState;
    const { foundFeatureCollections } = useSelector<RootState>(x => x.draw) as DrawState;
    const { selectedProject } = useSelector<RootState>(x => x.features) as FeaturesState;
    const [prevSelectedProject, setPrevSelectedProject] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        setPrevSelectedProject(selectedProject);
    }, [selectedProject]);

    useEffect(() => {
        const isClusterInLayers = !!layers.getItemById(PROJECTS_CLUSTER_LAYER);
        let createNewLayer = false;
        switch (foundFeatureCollections.length) {
            case 0:
                if (isClusterInLayers) dispatch(layerActions.deleteLayer(PROJECTS_CLUSTER_LAYER));
                break;
            case 1:
                if (isClusterInLayers) dispatch(layerActions.deleteLayer(PROJECTS_CLUSTER_LAYER));
                if (selectedProject === '' && !isClusterInLayers) createNewLayer = true;
                break;
            default:
                createNewLayer = true;
        }

        if (!createNewLayer) return;

        const featureCollection: GeoJSONFeatureCollection = { type: 'FeatureCollection', features: [] };
        for (const v of foundFeatureCollections) {
            if (v.name === selectedProject) continue;
            if (layers.find(l => l.id === v.name)) continue;
            featureCollection.features.push({
                type: 'Feature',
                properties: { name: v.name },
                geometry: v.centroid,
                id: v.id
            });
        }
        const intersecatedProjectsLayer = new ProjectsClusterLayer({
            ...DEFAULT_PROJECTS_CLUSTER_LAYER_VALUE,
            data: featureCollection,
            selectedTab: selectedTab,
            id: PROJECTS_CLUSTER_LAYER,
            dispatch
        });

        dispatch(layerActions.upsertLayer(intersecatedProjectsLayer));
    }, [dispatch, layers.length, selectedProject, foundFeatureCollections, selectedTab]);

    useEffect(() => {
        if (foundFeatureCollections.length !== 1) return;
        const foundProjectName = foundFeatureCollections[0].name;
        if (
            ((prevSelectedProject === '' && selectedProject !== '') ||
                (prevSelectedProject !== '' && selectedProject !== '' && prevSelectedProject !== selectedProject) ||
                prevSelectedProject === selectedProject) &&
            !layers.find(l => l.id === foundProjectName)
        )
            dispatch(featuresActions.setSelectedProject(foundProjectName));
    }, [dispatch, selectedProject, foundFeatureCollections]);

    return;
};

export { useProjectsClusterLayer };
