import Dexie, { Table } from 'dexie';

export interface IProfilePicture {
    id: string;
    data: ArrayBuffer;
}

export class DexieDB extends Dexie {
    profilePictures!: Table<IProfilePicture>;

    constructor() {
        super('sphere');
        this.version(1).stores({
            profilePictures: '++id'
        });
    }
}

export const dexieDB = new DexieDB();
