export interface GroupCreation {
    name: string;
    pIva: string;
    permissions: GroupPermission[];
}

export interface GroupUpdate extends GroupCreation {
    id: string;
}

export interface Group extends GroupUpdate {
    usersNumber: string;
    createdAt: Date;
    updatedAt: Date;
}

export enum GroupPermission {
    BUILDINGS_LAYER = 'BUILDINGS_LAYER',
    COORDINATES_CONVERTER = 'COORDINATES_CONVERTER',
    DELETE_AREAS = 'DELETE_AREAS',
    DRAWING_TOOL = 'DRAWING_TOOL',
    EXPORT_CSV = 'EXPORT_CSV',
    EXPORT_EXCEL = 'EXPORT_EXCEL',
    EXPORT_SHAPE = 'EXPORT_SHAPE',
    FAVORITES = 'FAVORITES',
    GEOCODING = 'GEOCODING',
    MAP_LAYER_REGIONI = 'MAP_LAYER_REGIONI',
    MAP_LAYER_VINCOLI = 'MAP_LAYER_VINCOLI',
    MAP_STYLE_BUILDINGS_SATELLITE = 'MAP_STYLE_BUILDINGS_SATELLITE',
    MAP_STYLE_DARK = 'MAP_STYLE_DARK',
    MAP_STYLE_DARK_3D = 'MAP_STYLE_DARK_3D',
    MAP_STYLE_DAY_NAVIGATION = 'MAP_STYLE_DAY_NAVIGATION',
    MAP_STYLE_LIGHT = 'MAP_STYLE_LIGHT',
    MAP_STYLE_LIGHT_3D = 'MAP_STYLE_LIGHT_3D',
    MAP_STYLE_NIGHT_NAVIGATION = 'MAP_STYLE_NIGHT_NAVIGATION',
    MAP_STYLE_OPEN_INFRA_MAP = 'MAP_STYLE_OPEN_INFRA_MAP',
    MAP_STYLE_OUT_DOORS = 'MAP_STYLE_OUT_DOORS',
    MAP_STYLE_STREETS_SATELLITE = 'MAP_STYLE_STREETS_SATELLITE',
    RENAME_AREA = 'RENAME_AREA',
    TERRAIN_3D = 'TERRAIN_3D',
    UI_INFO = 'UI_INFO',
    UPLOAD_FEATURE_COLLECTION = 'UPLOAD_FEATURE_COLLECTION'
}
