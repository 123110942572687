import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerCompensator: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="powerCompensator" xmlns="http://www.w3.org/2000/svg" width="10" height="14.85" viewBox="0 0 10 14.85">
            <path
                id="Path_1441"
                data-name="Path 1441"
                d="M0,9.25H4.25v3.04L1.93,9.97.87,11.03l3.6,3.6a.748.748,0,0,0,1.06,0l3.6-3.6L8.07,9.97,5.75,12.29V9.25H10V7.75H0v1.5Z"
                fill={fill}
            />
            <path id="Path_1442" data-name="Path 1442" d="M5.75,4.75V0H4.25V4.75H0v1.5H10V4.75Z" fill={fill} />
        </svg>
    );
};
