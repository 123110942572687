import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PowerStatsToggleType } from 'helpers';

export type BreadcrumbElementType = 'stats' | 'nation' | 'source' | 'plant';
export interface InfrastructuresState {
    selectedNation?: number;
    selectedPlant?: number;
    selectedTab?: string;
    selectedSource?: string;
    popupInfo?: any;
    breadcrumb: BreadcrumbElementType[];
}

const name = 'infrastructures';
const initialState: InfrastructuresState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const infrastructuresActions = { ...slice.actions };
export const infrastructuresReducer = slice.reducer;

function createInitialState(): InfrastructuresState {
    return {
        selectedNation: undefined,
        selectedPlant: undefined,
        selectedTab: PowerStatsToggleType.powerPlants,
        selectedSource: undefined,
        popupInfo: undefined,
        breadcrumb: ['stats']
    } as InfrastructuresState;
}

function createReducers() {
    return {
        setSelectedNation,
        setSelectedPlant,
        setSelectedTab,
        setSelectedSource,
        resetState,
        setItems
    };

    function addToBreadcrumb(state: InfrastructuresState, breadcrumbElement: BreadcrumbElementType) {
        !state.breadcrumb.find(elem => elem === breadcrumbElement) && state.breadcrumb.push(breadcrumbElement);
    }
    function removeFromBreadcrumb(state: InfrastructuresState, breadcrumbElement: BreadcrumbElementType) {
        const index = state.breadcrumb.findIndex(elem => elem === breadcrumbElement);
        if (index === -1) return;
        state.breadcrumb.splice(index, 1);
    }

    function setItems(state: InfrastructuresState, action: PayloadAction<{ key: keyof InfrastructuresState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function setSelectedNation(state: InfrastructuresState, action: PayloadAction<number | undefined>): void {
        if (action.payload) addToBreadcrumb(state, 'nation');
        else removeFromBreadcrumb(state, 'nation');
        state.selectedNation = action.payload;
    }

    function setSelectedPlant(state: InfrastructuresState, action: PayloadAction<number | undefined>): void {
        if (action.payload) addToBreadcrumb(state, 'plant');
        else removeFromBreadcrumb(state, 'plant');
        state.selectedPlant = action.payload;
    }

    function setSelectedTab(state: InfrastructuresState, action: PayloadAction<PowerStatsToggleType>): void {
        state.selectedTab = action.payload;
    }

    function setSelectedSource(state: InfrastructuresState, action: PayloadAction<string | undefined>): void {
        if (action.payload) addToBreadcrumb(state, 'source');
        else removeFromBreadcrumb(state, 'source');
        state.selectedSource = action.payload;
    }

    function resetState(state: InfrastructuresState, action: PayloadAction<void>): void {
        state.selectedNation = undefined;
        state.selectedPlant = undefined;
        state.selectedSource = undefined;
        state.selectedTab = PowerStatsToggleType.powerPlants;
        state.breadcrumb = ['stats'];
    }
}

