import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowForward: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.414" height="29.172" viewBox="0 0 15.414 29.172">
            <path
                id="arrow_forward_48x48"
                d="M17.354,87.116l-1.061-1.061,13.6-13.6L16.449,59.005l1.06-1.061L31.487,71.922a.75.75,0,0,1,0,1.061Z"
                transform="translate(-16.293 -57.944)"
                fill={fill}
            />
        </svg>
    );
};
