import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantNuclear: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="powerPlantNuclear" xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="Path_1426"
                data-name="Path 1426"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,12H1.5V1.5H12Z"
                fill={fill}
            />
            <path
                id="Path_1427"
                data-name="Path 1427"
                d="M6.75,10A3.25,3.25,0,1,0,3.5,6.75,3.256,3.256,0,0,0,6.75,10Zm0-5A1.75,1.75,0,1,1,5,6.75,1.758,1.758,0,0,1,6.75,5Z"
                fill={fill}
            />
        </svg>
    );
};
