import { SvgIconProps } from 'helpers';

export const Calendar: SvgIconProps = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.938" viewBox="0 0 18 16.938">
            <defs>
                <clipPath id="calendarClipPath">
                    <rect id="Rectangle_1696" data-name="Rectangle 1696" width="18" height="16.938" fill="#000a0c" />
                </clipPath>
            </defs>
            <g id="calendar" transform="translate(0 0)">
                <g id="Group_565" data-name="Group 565" transform="translate(0 0)" clipPath="url(#calendarClipPath)">
                    <path
                        id="Path_1353"
                        data-name="Path 1353"
                        d="M6.125,14.261a.915.915,0,1,0-.915-.915.914.914,0,0,0,.915.915"
                        transform="translate(-2.031 -4.846)"
                        fill="#000a0c"
                    />
                    <path
                        id="Path_1354"
                        data-name="Path 1354"
                        d="M14.125,14.261a.915.915,0,1,0-.915-.915.914.914,0,0,0,.915.915"
                        transform="translate(-5.15 -4.846)"
                        fill="#000a0c"
                    />
                    <path
                        id="Path_1355"
                        data-name="Path 1355"
                        d="M22.3,14.261a.915.915,0,1,0-.915-.915.914.914,0,0,0,.915.915"
                        transform="translate(-8.335 -4.846)"
                        fill="#000a0c"
                    />
                    <path
                        id="Path_1356"
                        data-name="Path 1356"
                        d="M6.035,22.051a.915.915,0,1,0-.915-.915.914.914,0,0,0,.915.915"
                        transform="translate(-1.996 -7.882)"
                        fill="#000a0c"
                    />
                    <path
                        id="Path_1357"
                        data-name="Path 1357"
                        d="M14.205,20.22a.915.915,0,1,0,.915.915.914.914,0,0,0-.915-.915"
                        transform="translate(-5.181 -7.882)"
                        fill="#000a0c"
                    />
                    <path
                        id="Path_1358"
                        data-name="Path 1358"
                        d="M18,1.593a.461.461,0,0,0-.458-.458H14.327V.458a.458.458,0,0,0-.915,0v.677H4.564V.458a.458.458,0,1,0-.915,0v.677H.458A.461.461,0,0,0,0,1.593V16.481a.461.461,0,0,0,.458.458H17.542A.461.461,0,0,0,18,16.481V1.593ZM.915,2.05H3.649V2.9a.458.458,0,1,0,.915,0V2.05h8.847V2.9a.458.458,0,0,0,.915,0V2.05h2.758V4.8H.915ZM17.085,16.023H.915V5.729H17.085Z"
                        transform="translate(0 0)"
                        fill="#000a0c"
                    />
                </g>
            </g>
        </svg>
    );
};
