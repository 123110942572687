import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const FolderOpen: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.897" height="17.7" viewBox="0 0 24.897 17.7">
            <path
                id="folder_open"
                d="M1251.248,140.962a.744.744,0,0,0-.62-.331h-2.85v-3.75a.75.75,0,0,0-.75-.75h-10.4l-1.579-2.365a.748.748,0,0,0-.623-.335h-7.2a.75.75,0,0,0-.75.75v16.2l.005.091.01.014a.7.7,0,0,0,.116.318v0c.032.045.066.071.067.066a.774.774,0,0,0,.074.069.736.736,0,0,0,.477.19h19.8a.749.749,0,0,0,.7-.471l3.6-9A.759.759,0,0,0,1251.248,140.962Zm-20.42-.331a.749.749,0,0,0-.7.471l-2.154,5.383V134.931h6.048l1.578,2.366a.753.753,0,0,0,.624.334h10.05v3Zm18.693,1.5-3,7.5h-18.184l3-7.5Z"
                transform="translate(-1226.478 -133.431)"
                fill={fill}
            />
        </svg>
    );
};
