import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureCollection } from 'models';
import Spinner from 'components/spinner/Spinner';
import { AxiosContext } from './axiosContext';
import { findShapeFiles, findShapeFilesInZip, loadGeoJSON } from 'helpers';
import { createPortal } from 'react-dom';
import geobuf from 'geobuf';
import pbf from 'pbf';
import { noticesActions } from 'store';
import { useTranslation } from 'react-i18next';

export type CollectionSource = { zipName: string; fileName: string };
export type FeatureCollectionToUpload = FeatureCollection & {
    fileName: string;
    shapeColumns: string[];
    source: File;
};
export type UploadError = { errorType: string; fileName: string };
export type FileToUpload = {
    geojson: any;
    name: string;
};
export type UploadInfo = {
    selectedType: string;
    columnsMapping: Record<string, string>;
};

interface ProjectFeaturesApi {
    getGeoJSON: (acceptedFiles: File[]) => Promise<FileToUpload[]>;
    uploadGeobuf: (file: File, info: UploadInfo) => Promise<any>;
}

const ProjectFeaturesContext = createContext<ProjectFeaturesApi>({} as ProjectFeaturesApi);

const ProjectFeatureProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { postRequest } = useContext(AxiosContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getGeoJSON = async (acceptedFiles: File[]) => {
        setIsLoading(() => true);
        const newFiles: FileToUpload[] = [];
        try {
            const zips = acceptedFiles.filter(file => file.name.toLowerCase().endsWith('.zip'));
            await Promise.all(
                zips.map(async zip => {
                    const zipShapes = await findShapeFilesInZip(zip);
                    zipShapes.errors.forEach(file =>
                        dispatch(
                            noticesActions.setError(`${t('upload:error.missingRelatedShapeFiles')} ${file.name.toUpperCase()}`)
                        )
                    );
                    zipShapes.shapes.forEach(data =>
                        newFiles.push({
                            geojson: data,
                            name: data.fileName
                        })
                    );
                })
            );

            const foundShapeFiles = await findShapeFiles(acceptedFiles);
            foundShapeFiles.errors.forEach(error => {
                const name = error.file.name.toUpperCase();
                if (error.type === 'missingRelated')
                    dispatch(noticesActions.setError(`${t('upload:error.missingRelatedShapeFiles')} ${name}`));
                else dispatch(noticesActions.setError(`${t('upload:error.missingShpFile')} ${name}`));
            });
            foundShapeFiles.shapes.forEach((shape: any) =>
                newFiles.push({
                    geojson: shape,
                    name: shape.fileName
                })
            );

            const jsons = acceptedFiles.filter(
                file => file.name.toLowerCase().endsWith('.json') || file.name.toLowerCase().endsWith('.geojson')
            );
            const jsonData = await Promise.all(jsons.map(json => loadGeoJSON(json)));
            jsonData.forEach(data => {
                if (data.error) {
                    const [elem] = jsonData.splice(jsonData.indexOf(data), 1);
                    dispatch(noticesActions.setError(`${t('upload:error.corruptedFile')} ${elem.source.name.toUpperCase()}`));
                } else {
                    newFiles.push({
                        geojson: data,
                        name: data.fileName
                    });
                }
            });
        } catch (error) {
            console.error(error);
            dispatch(noticesActions.setError((error as any).toString()));
        }
        setIsLoading(() => false);
        return newFiles;
    };

    const uploadGeobuf = async (geojson: any, info: UploadInfo) => {
        const file = new File([geobuf.encode(geojson, new pbf())], 'geobuf.pbf');
        const form = new FormData();
        form.append('file', file);
        form.append('info', JSON.stringify(info));
        const { data } = await postRequest('/project-features/geobuf-upload', form);
        return data;
    };

    return (
        <ProjectFeaturesContext.Provider value={{ getGeoJSON, uploadGeobuf }}>
            <>
                {isLoading && createPortal(<Spinner isLoading={isLoading} />, document.getElementById('spinner-root')!)}
                {children}
            </>
        </ProjectFeaturesContext.Provider>
    );
};

export { ProjectFeaturesContext, ProjectFeatureProvider };
