import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantWaste: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg id="powerPlantWaste" xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="Path_1432"
                data-name="Path 1432"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,12H1.5V1.5H12Z"
                fill={fill}
            />
            <path
                id="Path_1433"
                data-name="Path 1433"
                d="M8,9.25H5.5V6H4v4a.755.755,0,0,0,.75.75h4A.755.755,0,0,0,9.5,10V6H8Z"
                fill={fill}
            />
            <path id="Path_1434" data-name="Path 1434" d="M10.58,3.75H8.75v-1h-4v1H2.92v1.5h7.66Z" fill={fill} />
        </svg>
    );
};
