import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantCoal: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="powerPlantCoal"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,1.5v3H1.5v-3Zm0,6H1.5V6H12ZM1.5,12V9H12v3Z"
                fill={fill}
            />
        </svg>
    );
};
