export {};

declare global {
    interface Array<T> {
        getItemById(id: string): T;
        getIndexById(id: string): number;
        findLastIndex(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): number;
    }
}

if (!Array.prototype.getItemById) {
    Array.prototype.getItemById = function <T>(id: T): T {
        return this.find(e => e.id === id);
    };
}
if (!Array.prototype.getIndexById) {
    Array.prototype.getIndexById = function (id: string): number {
        return this.findIndex(e => e.id === id);
    };
}

