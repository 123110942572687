import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ConfirmedCircle: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="confirmed_circle" transform="translate(0 0)">
                <path
                    id="Subtraction_27"
                    data-name="Subtraction 27"
                    d="M299.007,960.591a7,7,0,1,1,7-7A7,7,0,0,1,299.007,960.591Zm-3.184-7.69-.826.826,2.526,2.523a.581.581,0,0,0,.824,0l4.666-4.666-.824-.824-4.254,4.254Z"
                    transform="translate(-292.007 -946.591)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
