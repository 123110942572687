import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Reset: SvgIconProps = ({ fill = colors.gray900 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.714" height="17.65" viewBox="0 0 12.714 17.65">
            <defs>
                <clipPath id="resetClipPath">
                    <rect id="Rectangle_1702" data-name="Rectangle 1702" width="12.714" height="17.65" fill={fill} />
                </clipPath>
            </defs>
            <g id="reset" transform="translate(0 0)">
                <g id="Group_569" data-name="Group 569" transform="translate(0 0)" clipPath="url(#resetClipPath)">
                    <path
                        id="Path_1364"
                        data-name="Path 1364"
                        d="M10.646,2.5l2.263-1.8L12.356,0,9.272,2.454a.443.443,0,0,0-.071.623l2.454,3.084.695-.553L10.576,3.379A5.473,5.473,0,0,1,13.94,12.7l.627.627A6.36,6.36,0,0,0,10.646,2.5"
                        transform="translate(-3.716 0)"
                        fill={fill}
                    />
                    <path
                        id="Path_1365"
                        data-name="Path 1365"
                        d="M4.776,14.47l-.695.553,1.775,2.229a5.473,5.473,0,0,1-3.365-9.32L1.863,7.3A6.36,6.36,0,0,0,5.784,18.137l-2.261,1.8.552.695,3.083-2.454a.443.443,0,0,0,.071-.624Z"
                        transform="translate(0 -2.982)"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};
