import { FormControl, MenuItem, Select } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classes from './Multiselect.module.scss';
import { GroupPermission } from 'models';

type MultiselectProps = FC<{ value: any; register: UseFormRegister<FieldValues> }>;

const Multiselect: MultiselectProps = ({ value, register }) => {
    const { t } = useTranslation('form');

    const itemsGenerator = useMemo(() => {
        return Object.keys(GroupPermission).map(item => (
            <MenuItem key={`${item}_key`} value={item}>
                {t(`permissionsChoises.${item}` as any)}
            </MenuItem>
        ));
    }, [t]);

    const renderMultiselectValue = useCallback(
        (selected: string[]) => (selected.length === 0 ? t('noPermissions') : `${selected.length} ${t('permissionsActive')}`),
        [t]
    );

    return (
        <FormControl className={classes.matSelect} variant="filled">
            <Select
                id={value.name}
                className={classes.select}
                multiple
                displayEmpty
                disableUnderline={true}
                defaultValue={value.multiSelect}
                renderValue={renderMultiselectValue}
                {...register(value.name, { ...value.validators })}
            >
                {itemsGenerator}
            </Select>
        </FormControl>
    );
};

export default Multiselect;
