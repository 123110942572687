import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerSwitch: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="12" viewBox="0 0 15.5 12">
            <path
                id="powerSwitch"
                d="M14.75,0H.75A.755.755,0,0,0,0,.75v10.5A.755.755,0,0,0,.75,12h14a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,14.75,0ZM1.5,10.5V9.02H5.75a.749.749,0,0,0,.53-.22l4-4L9.22,3.74,5.44,7.52H1.5V1.5H14V7.52H9.75v1.5H14V10.5Z"
                fill={fill}
            />
        </svg>
    );
};
