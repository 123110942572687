import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantBiomass: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="powerPlantBiomass"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,12H7.5V10.39h2.7a.741.741,0,0,0,.69-.46.757.757,0,0,0-.16-.82L8.56,6.94h.89a.741.741,0,0,0,.69-.46.757.757,0,0,0-.16-.82l-2.7-2.7a.754.754,0,0,0-1.06,0l-2.7,2.7a.751.751,0,0,0,.53,1.28h.89L2.77,9.11a.751.751,0,0,0,.53,1.28H6V12H1.5V1.5H12ZM5.86,5.44l.89-.89.89.89H5.87Zm.89,1.81L8.39,8.89H5.11L6.75,7.25Z"
                fill={fill}
            />
        </svg>
    );
};
