import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const NotifyNew: SvgIconProps = ({ fill = colors.gray900 }) => {
    return (
        <svg id="notifiche_recenti" xmlns="http://www.w3.org/2000/svg" width="24.534" height="28" viewBox="0 0 24.534 28">
            <defs>
                <clipPath id="notifyNewClipPath">
                    <rect id="Rectangle_1661" data-name="Rectangle 1661" width="24.534" height="28" fill="#000a0c" />
                </clipPath>
            </defs>
            <g id="Group_549" data-name="Group 549" clipPath="url(#notifyNewClipPath)">
                <path
                    id="Path_1340"
                    data-name="Path 1340"
                    d="M12.979,3.5V1.424h4.005V0H7.55V1.424h4.005V3.5a12.267,12.267,0,1,0,1.424,0m-.712,23.074A10.843,10.843,0,1,1,23.11,15.733,10.856,10.856,0,0,1,12.267,26.576"
                    fill={fill}
                />
                <path
                    id="Path_1341"
                    data-name="Path 1341"
                    d="M13.6,6.941H12.174v9.145a.713.713,0,0,0,.209.5l3.927,3.927,1.007-1.006L13.6,15.792Z"
                    transform="translate(-0.619 -0.353)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
