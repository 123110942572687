import { ShapefileLoader } from '@loaders.gl/shapefile';
import { load } from '@loaders.gl/core';
import * as turf from '@turf/turf';
import proj4 from 'proj4';
import shpjs from 'shpjs';
import { v4 } from 'uuid';

interface ShapeFileProps {
    name: string;
    shp: ArrayBuffer;
    shx: ArrayBuffer;
    dbf: ArrayBuffer;
    prj?: ArrayBuffer;
    cpg?: ArrayBuffer;
}

export class ShapeFile {
    private source: ShapeFileProps;
    private geojson?: any;

    constructor(props: ShapeFileProps) {
        this.source = props;
    }

    private joinProperties(geometries: object[], properties: object[]): any[] {
        const features: any[] = [];
        for (let i = 0; i < geometries.length; i++) {
            const geometry = geometries[i];
            const feature: any = {
                type: 'Feature',
                geometry,
                properties: (properties && properties[i]) || {}
            };
            features.push(feature);
        }

        return features;
    }

    private async loadShapeFile() {
        const shp = await load(this.source.shp, ShapefileLoader, {
            fetch: async (input: RequestInfo | URL, init?: RequestInit | undefined) => {
                return new Response(null, { status: 404 });
            }
        });
        const prj = this.source.prj && new TextDecoder().decode(this.source.prj);
        const properties = (shpjs.parseDbf as any)(this.source.dbf, this.source.cpg);
        const features = this.joinProperties(
            shp.data.map((d: any) => d.geometry),
            properties
        );
        const geojson = {
            type: 'FeatureCollection',
            features
        };
        if (prj) {
            proj4.defs('source', prj);
            const sourceCRSFromPRJ = proj4('source');
            turf.coordEach(geojson as any, coord => {
                const [x, y] = sourceCRSFromPRJ.inverse(coord);
                coord[0] = x;
                coord[1] = y;
            });
        }
        this.geojson = {
            ...geojson,
            id: v4(),
            fileName: this.source.name,
            bbox: (turf.bboxPolygon(turf.bbox(geojson)) as any).geometry,
            centroid: turf.centroid(geojson as any).geometry,
            shapeColumns: Object.keys((geojson as any).features[0].properties)
        };
    }

    get name() {
        return this.source.name;
    }

    async getGeoJSON() {
        if (!this.geojson) await this.loadShapeFile();
        return this.geojson;
    }
}
