import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Work: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.012" height="24.01" viewBox="0 0 24.012 24.01">
            <path
                id="work"
                d="M467.878,18.516a.74.74,0,0,0-.511-.584.73.73,0,0,0-.754.186l-3.137,3.135-3.684-1.078-1.078-3.684,3.134-3.135a.749.749,0,0,0-.394-1.27,7.942,7.942,0,0,0-8.855,10.58L444.967,30.3a3.322,3.322,0,0,0,4.7,4.7l7.631-7.633a7.941,7.941,0,0,0,10.582-8.85m-13.866,4.86a.75.75,0,0,0,.155-.835,6.447,6.447,0,0,1,5.441-9.068l-2.267,2.269a.744.744,0,0,0-.191.742l1.318,4.507a.752.752,0,0,0,.508.51l4.508,1.317a.765.765,0,0,0,.737-.191l2.269-2.271a6.443,6.443,0,0,1-9.062,5.446.745.745,0,0,0-.839.152l-7.984,7.984a1.87,1.87,0,0,1-2.578,0,1.819,1.819,0,0,1,0-2.577Z"
                transform="translate(-443.994 -11.96)"
                fill={fill}
            />
        </svg>
    );
};
