import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { AxiosContext } from './axiosContext';
import { Notification, UserNotificationStatus } from 'helpers';

interface NotificationsApi {
    getSuperAdminNotifications: () => Promise<Notification[]>;
    getUserNotifications: () => Promise<Notification[]>;
    rejectNotification: (featureCollectionId: string, notificationId: string) => Promise<string>;
    updateUsersNotification: (notificationsId: string[], status: UserNotificationStatus) => Promise<string>;
    deleteNotifications: (notificationsId: string[]) => Promise<string>;
}

const NotificationsContext = createContext<NotificationsApi>({} as NotificationsApi);

const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
    const { getRequest, postRequest, putRequest, deleteRequest } = useContext(AxiosContext);

    const getSuperAdminNotifications = useCallback(async () => {
        const { data } = await getRequest(`/notifications`);
        return data;
    }, [getRequest]);

    const getUserNotifications = useCallback(async () => {
        const { data } = await getRequest(`/notifications/by-user`);
        return data;
    }, [getRequest]);

    const rejectNotification = useCallback(
        async (featureCollectionId: string, notificationId: string) => {
            const { data } = await postRequest(`/notifications`, { featureCollectionId, notificationId });
            return data;
        },
        [postRequest]
    );

    const updateUsersNotification = useCallback(
        async (notificationsId: string[], status: UserNotificationStatus) => {
            const { data } = await putRequest(`/notifications`, { notificationsId, status });
            return data;
        },
        [putRequest]
    );

    const deleteNotifications = useCallback(
        async (notificationsId: string[]) => {
            const { data } = await deleteRequest('/notifications', { notificationsId });
            return data?.message;
        },
        [deleteRequest]
    );

    return (
        <NotificationsContext.Provider
            value={{
                getSuperAdminNotifications,
                getUserNotifications,
                rejectNotification,
                updateUsersNotification,
                deleteNotifications
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export { NotificationsContext, NotificationsProvider };
