import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PickingInfo } from 'deck.gl/typed';
import { FeatureCollection } from 'models';

export interface PopupInfoState {
    info: { [x: string]: any } | undefined;
    object: FeatureCollection | undefined;
}

const name = 'popupInfo';
const initialState: PopupInfoState = { info: undefined, object: undefined };
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const popupInfoActions = { ...slice.actions };
export const popupInfoReducer = slice.reducer;

function createReducers() {
    return { resetPopupInfo, updatePopupInfo };
}

function updatePopupInfo(state: PopupInfoState, action: PayloadAction<PickingInfo>) {
    const object = action.payload.object;
    const properties = object.properties;
    if (properties && Object.keys(properties).length > 0) {
        state.object = object;
        state.info = properties;
        return;
    }
    if (Object.keys(object).length > 0) state.info = object;
}

function resetPopupInfo(state: PopupInfoState) {
    state.info = undefined;
}
