import epsg from 'epsg-index/all.json';
import proj4 from 'proj4';
import {
    EPSGInterface,
    FEATURE_COLLECTION_TYPE,
    FEATURE_TYPE,
    PROJECTS_CLUSTER_LAYER,
    REGIONI_LAYER,
    REGION_NAMES,
    SELECTED_FEATURE_LAYER
} from 'helpers';
import { centerOfMass, points, center, point } from '@turf/turf';

export const generateColor = () => Math.floor(Math.random() * (255 - 0 + 1));

export const transformation = (from: string, to: string) => {
    const leadingEPSG = /^epsg:/i;
    if ('string' !== typeof from) throw new Error('from must be a string');
    from = from.replace(leadingEPSG, '');
    const fromEPSG = (epsg as any)[from];
    if (!fromEPSG) throw new Error(from + ' is not a valid EPSG coordinate system');

    if ('string' !== typeof to) throw new Error('to must be a string');
    to = to.replace(leadingEPSG, '');
    const toEPSG = (epsg as any)[to];
    if (!toEPSG) throw new Error(to + ' is not a valid EPSG coordinate system');

    return proj4(fromEPSG.proj4, toEPSG.proj4);
};

export const leadingEPSG: EPSGInterface[] = Object.keys(epsg).map(elm => ({
    code: (epsg as any)[elm].code,
    name: (epsg as any)[elm].name,
    unit: (epsg as any)[elm].unit,
    area: (epsg as any)[elm].area,
    accuracy: (epsg as any)[elm].accuracy
}));

export const randomColor = () => [generateColor(), generateColor(), generateColor(), 100];

export const setInitialColor = (d: any, color: any) => {
    if (isBuilding(d)) return [...color, 250];
    if (d.geometry.type.includes('Polygon')) return [...color, 100];
    if (!d.properties.UI_BUSINES) return color;
    let count = +d.properties.UI_BUSINES > 0 ? 1 : 0;
    count += +d.properties.UI_COMMERC > 0 ? 2 : 0;
    count += +d.properties.UI_RESIDEN > 0 ? 4 : 0;
    switch (count) {
        case 1:
            return [0, 213, 132];
        case 2:
            return [219, 11, 61];
        case 4:
            return [0, 143, 193];
        default:
            return [0, 10, 12];
    }
};

export const getGeometryAnchor = (feature: any) => {
    const { type, coordinates } = feature.geometry;
    switch (type) {
        case 'Point':
            return point(coordinates);
        case 'MultiPoint':
            return center(points(coordinates));
        case 'Polygon':
            return centerOfMass(feature);
        case 'MultiPolygon':
        case 'LineString':
        case 'MultiLineString':
            return center(feature);
        default:
            return feature;
    }
};

export const featureCollectionFormatter = (featureCollection: any) => {
    if (!featureCollection) return;
    const newFeatures = featureCollection.features.map((feature: any) => {
        return { ...feature, type: FEATURE_TYPE };
    });
    return { ...featureCollection, features: newFeatures, type: FEATURE_COLLECTION_TYPE };
};

export const formatRgbaColor = (color: number[]) => {
    if (!color) return;
    return `rgba(${color.join(', ')})`;
};

export const LAYERS_TO_EXCLUDE = (layer: any) =>
    layer.id !== REGIONI_LAYER &&
    layer.id !== SELECTED_FEATURE_LAYER &&
    layer.id !== PROJECTS_CLUSTER_LAYER &&
    !REGION_NAMES.includes(layer.id);

export const getFileExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
};

export const megaWattStringComparator = (v1: string, v2: string) => {
    const formatString = (mwStr: string) => {
        return mwStr.replace(' MW', '').replaceAll(',', '');
    };
    return +formatString(v1) > +formatString(v2) ? 1 : -1;
};

export const isBuilding = (d: any) => {
    return ['MultiPolygon', 'Polygon'].includes(d.geometry.type) && (d.properties?.height || d.properties?.ALT);
};
