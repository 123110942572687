import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const SeeOnMap: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg
            id="vedi_su_mappa"
            data-name="vedi su mappa"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
        >
            <defs>
                <clipPath id="seeOnMapClipPath">
                    <rect id="Rectangle_1681" data-name="Rectangle 1681" width="20" height="20" fill={fill} />
                </clipPath>
            </defs>
            <g id="Group_552" data-name="Group 552" clipPath="url(#seeOnMapClipPath)">
                <path
                    id="Path_1344"
                    data-name="Path 1344"
                    d="M3.913,11.092a.607.607,0,0,0,0,.63,7.994,7.994,0,0,0,13.74,0,.607.607,0,0,0,0-.63,7.994,7.994,0,0,0-13.74,0m12.52.315a6.8,6.8,0,0,1-11.3,0,6.8,6.8,0,0,1,11.3,0"
                    transform="translate(-0.784 -1.407)"
                    fill={fill}
                />
                <path
                    id="Path_1345"
                    data-name="Path 1345"
                    d="M14.79,11.7a2.982,2.982,0,1,0-2.982,3.015A3,3,0,0,0,14.79,11.7m-2.982,1.809A1.809,1.809,0,1,1,13.6,11.7a1.8,1.8,0,0,1-1.789,1.809"
                    transform="translate(-1.808 -1.701)"
                    fill={fill}
                />
                <path
                    id="Path_1346"
                    data-name="Path 1346"
                    d="M18.778.616,13.8,2.6,6.52.05a.887.887,0,0,0-.58,0L.607,1.88A.9.9,0,0,0,0,2.736V18.515a.9.9,0,0,0,1.221.842L6.23,17.37l7.279,2.579a.878.878,0,0,0,.584,0l5.3-1.83A.9.9,0,0,0,20,17.265V1.457A.9.9,0,0,0,18.778.616m-.089,16.392-4.322,1.417V14.312a9.084,9.084,0,0,1-1.311.576v3.537l-6.11-2.141v-1.4a9.094,9.094,0,0,1-1.312-.576v1.972L1.311,17.942V2.992L5.633,1.516V5.687a9.094,9.094,0,0,1,1.312-.576v-3.6l6.11,2.17V5.112a8.993,8.993,0,0,1,1.311.575v-2l4.322-1.657Z"
                    transform="translate(0 0.001)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
