import ColorHash from 'color-hash';
import { Buffer } from 'buffer';

export const getRandomColor = (seed: string = '') => {
    return new ColorHash().hex(seed);
};

const getInitials = (name: string, lastName: string) => {
    if (!name || !lastName) return '';
    const initials: string = name.substring(0, 1) + lastName.substring(0, 1);
    return initials.toUpperCase();
};

export const createImageFromInitials = (size: any, name: string, lastName: string, color: any) => {
    if (!name || !lastName) return '';
    name = getInitials(name, lastName);

    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');
    canvas.width = canvas.height = size;

    context.fillStyle = '#ffffff';
    context.fillRect(0, 0, size, size);

    context.fillStyle = `${color}50`;
    context.fillRect(0, 0, size, size);

    context.fillStyle = color;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `${size / 2}px Montserrat`;
    context.fillText(name, size / 2, size / 2);

    return canvas.toDataURL();
};

export const getImageFromArrayBuffer = (data: ArrayBuffer) => {
    const base64 = Buffer.from(data).toString('base64');
    return `data:image/*;base64,${base64}`;
};

export const getArrayBufferFromBase64 = async (base64: string) => {
    var dataUrl = base64.replace(/data:image\/(.)+;base64,/, 'data:application/octet-binary;base64,');
    return (await fetch(dataUrl)).arrayBuffer();
};
