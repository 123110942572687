import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Info: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="info" transform="translate(-312.449 -226.449)">
                <path
                    id="Path_277"
                    data-name="Path 277"
                    d="M321.449,226.449a9,9,0,1,0,9,9A9.01,9.01,0,0,0,321.449,226.449Zm0,17.008a8.008,8.008,0,1,1,8.008-8.008A8.017,8.017,0,0,1,321.449,243.457Z"
                    fill={fill}
                />
                <path
                    id="Path_278"
                    data-name="Path 278"
                    d="M325.565,238.543a.522.522,0,0,0-.521-.521h-2.2v1.042h1.678v3.763h-1.678v1.042h4.4v-1.042h-1.678Z"
                    transform="translate(-3.595 -4.057)"
                    fill={fill}
                />
                <path
                    id="Path_279"
                    data-name="Path 279"
                    d="M325.624,235.316a.864.864,0,1,0-.864-.863A.865.865,0,0,0,325.624,235.316Z"
                    transform="translate(-4.175 -2.421)"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
