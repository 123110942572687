import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { AxiosContext } from './axiosContext';
import { layerActions, RootState } from 'store';
import { useOnClickLayerHandler } from 'hook';
import { POWER_PLANTS_LAYER, PowerNode } from 'helpers';
import { Feature, polygon, lineString, featureCollection, bboxPolygon, bbox, point } from '@turf/turf';
import { useDispatch, useSelector } from 'react-redux';

interface InfrastructuresApi {
    putPlantCollectionOnMap: (nationId: number, selectedSource: string) => Promise<any>;
}

const InfrastructuresContext = createContext<InfrastructuresApi>({} as InfrastructuresApi);

const InfrastructuresProvider: FC<PropsWithChildren> = ({ children }) => {
    const { selectedTab } = useSelector((x: RootState) => x.layers);
    const { postRequest } = useContext(AxiosContext);
    const dispatch = useDispatch();
    const onClickHandler = useOnClickLayerHandler();

    const putPlantCollectionOnMap = async (nationId: number, selectedSource: string) => {
        let sourceFilter = `["plant:source"="${selectedSource}"]`;
        if (selectedSource === '[unspecified]') sourceFilter = `[!"plant:source"]`;
        if (selectedSource === 'All') sourceFilter = ``;
        let query = `way["power"="plant"]${sourceFilter}(area.boundaryarea);`;

        const baseId = 3600000000;

        const params = new URLSearchParams();

        params.append(
            'data',
            `[out:json];area(id:${baseId + nationId})->.boundaryarea;
            (${query});
            out body;
            >;
            out skel qt;`
        );
        const res = await postRequest('https://overpass-api.de/api/interpreter', params);

        const nodes = new Map<number, PowerNode>();

        const lines: Feature[] = [];
        const polygons: Feature[] = [];
        const points: Feature[] = [];
        const matchingPoints = new Set<number>();

        res.data.elements?.filter((el: any) => el.type === 'node').forEach((el: any) => nodes.set(el.id, el));

        for (const elem of res.data.elements?.filter((el: any) => el.type === 'way')) {
            const wayNodes: PowerNode[] = [];
            for (const nodeId of elem.nodes) {
                if (!nodes.has(nodeId)) continue;
                wayNodes.push(nodes.get(nodeId)!);
            }
            wayNodes.forEach(({ id }) => matchingPoints.add(id));
            if (wayNodes[0].id === wayNodes[wayNodes.length - 1].id)
                polygons.push({
                    ...polygon([wayNodes.map(n => [n.lon, n.lat])]),
                    properties: { osm_id: elem.id, layerName: 'power_plant', ...elem.tags }
                });
            else
                lines.push({
                    ...lineString(wayNodes.map(n => [n.lon, n.lat])),
                    properties: { osm_id: elem.id, layerName: 'power_plant', ...elem.tags }
                });
        }

        nodes.forEach(node => {
            if (matchingPoints.has(node.id)) return;
            points.push(point([node.lon, node.lat]));
        });

        const collection = featureCollection([...lines, ...polygons, ...points]);
        collection.bbox = bboxPolygon(bbox(collection)).geometry as any;

        dispatch(layerActions.deleteLayer(POWER_PLANTS_LAYER));
        dispatch(
            layerActions.addIconClusterLayer({
                id: POWER_PLANTS_LAYER,
                tab: selectedTab,
                data: collection,
                onClick: onClickHandler
            })
        );
    };

    return <InfrastructuresContext.Provider value={{ putPlantCollectionOnMap }}>{children}</InfrastructuresContext.Provider>;
};

export { InfrastructuresContext, InfrastructuresProvider };
