import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPlantOilgas: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="powerPlantOilgas"
                d="M12.75,0H.75A.755.755,0,0,0,0,.75v12a.755.755,0,0,0,.75.75h12a.755.755,0,0,0,.75-.75V.75A.755.755,0,0,0,12.75,0ZM12,8.44l-2.5,2.5V1.5H12V8.44ZM8,12H5.5V1.5H8ZM2.94,1.5,1.5,2.94V1.5ZM1.5,5.06,4,2.56V12H1.5ZM10.56,12,12,10.56V12H10.56Z"
                fill={fill}
            />
        </svg>
    );
};
