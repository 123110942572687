import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Building: SvgIconProps = ({ isFull }) => {
    return (
        <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 28.28">
            <path
                fill={isFull ? colors.primary200 : 'transparent'}
                id="buildings_emptyfill"
                data-name="buildings emptyfill"
                d="m17,11.32c0-1.24-1.01-2.25-2.25-2.25h-5.75V.75c0-.27.15-.52.38-.65.23-.13.52-.13.75,0l9.99,5.77c.24.13.38.38.38.65v20.26h4v1.5h-7.5V11.32ZM0,28.27v-1.5h4v-15.45c0-.41.34-.75.75-.75h10c.41,0,.75.34.75.75v16.95H0Z"
            />
            <path
                fill={isFull ? 'transparent' : colors.gray100}
                id="Path_1379"
                data-name="Path 1379"
                d="m20.5,26.77V6.52c0-.27-.14-.52-.38-.65L10.13.1c-.23-.13-.52-.13-.75,0-.23.13-.38.38-.38.65v8.32h1.5V2.05l8.5,4.91v19.82h-2v1.5h7.5v-1.5h-4Z"
            />
            <path
                fill={isFull ? 'transparent' : colors.gray100}
                id="Path_1380"
                data-name="Path 1380"
                d="m14.75,10.57H4.75c-.41,0-.75.34-.75.75v15.45H0v1.5h15.5V11.32c0-.41-.34-.75-.75-.75Zm-.75,16.2H5.5v-14.7h8.5v14.7Z"
            />
        </svg>
    );
};
