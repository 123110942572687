import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MVTLayer } from 'deck.gl/typed';
import { env } from 'environments';
import { MVTLoader } from '@loaders.gl/mvt';
import { DEFAULT_TAB_ID } from '../helpers';

interface OimMapInitialState {
    oimLayer: MVTLayer | any;
}

export interface OimMapState {
    oimLayer: MVTLayer;
}

const name = 'oim';
const initialState: OimMapInitialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const oimActions = { ...slice.actions };
export const oimReducer = slice.reducer;

function createInitialState() {
    const oimLayer = new MVTLayer({
        id: 'openinframap',
        data: env.reactAppSocketUrl + env.apiUrl + '/oim/config.json',
        pickable: true,
        opacity: 0,
        tab: DEFAULT_TAB_ID,
        visible: false,
        loaders: [MVTLoader],
        loadOptions: { worker: false, fetch: { headers: { origin: 'https://openinframap.org' } } }
    });
    return { oimLayer } as OimMapInitialState;
}

function createReducers() {
    return {
        updateLayer,
        setVisibility,
        toggleVisibility
    };

    function toggleVisibility(state: OimMapState) {
        state.oimLayer = state.oimLayer.clone({ visible: !state.oimLayer.props.visible });
    }

    function setVisibility(state: OimMapState, action: PayloadAction<boolean>) {
        state.oimLayer = state.oimLayer.clone({ visible: action.payload });
    }

    function updateLayer(state: OimMapState, action: PayloadAction<any>) {
        state.oimLayer = state.oimLayer.clone({ ...action.payload });
    }
}

