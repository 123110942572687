import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const ArrowDown: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.061" height="8.281" viewBox="0 0 15.061 8.281">
            <path
                id="arrow_down_28x28"
                d="M49.53,10.92l-7,7a.75.75,0,0,1-1.061,0l-7-7L35.53,9.859,42,16.329l6.469-6.47Z"
                transform="translate(-34.469 -9.859)"
                fill={fill}
            />
        </svg>
    );
};
