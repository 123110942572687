import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const PowerPole: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
            <path
                id="powerPole"
                d="M6.75,13.5A6.75,6.75,0,1,1,13.5,6.75,6.76,6.76,0,0,1,6.75,13.5Zm0-12A5.25,5.25,0,1,0,12,6.75,5.262,5.262,0,0,0,6.75,1.5Z"
                fill={fill}
            />
        </svg>
    );
};
