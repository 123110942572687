export const NotifyDelete = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="notifica_elimina" transform="translate(-174.543 -836.787)">
                <rect
                    id="Rectangle_1688"
                    data-name="Rectangle 1688"
                    width="16"
                    height="16"
                    rx="6"
                    transform="translate(174.543 836.787)"
                    fill="#96092b"
                />
                <path
                    id="delete_full"
                    d="M99.462,1.828h-2.7v-1A.332.332,0,0,0,96.427.5H93.768a.332.332,0,0,0-.332.332v1H90.6v.665h1v8.53a.332.332,0,0,0,.332.332h6.2a.332.332,0,0,0,.332-.332V2.493h1ZM94.1,1.164h1.994v.665H94.1Z"
                    transform="translate(87.512 838.86)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};
