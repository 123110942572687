import { FC, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classes from './Navbar.module.scss';
import { authActions, AuthState, RootState, socketActions } from 'store';
import { IconButton } from 'components/buttons';
import { Close, Logout, Hamburger } from 'components/icons';
import UserEdit from './userEdit/UserEdit';
import ChangeLanguage from './changeLanguage/ChangeLanguage';
import { useNavbarReducer } from './Navbar.reducer';
import AdminButtons from './adminButtons/AdminButtons';
import UserInfo from './userInfo/UserInfo';
import { RIGHT_TOOLTIP, TOP_RIGHT_TOOLTIP } from 'helpers';

const Navbar: FC = () => {
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const navbarReducer = useNavbarReducer();
    const dispatch = useDispatch();

    const logout = useCallback(() => {
        dispatch(
            socketActions.sendMessage({
                topic: 'logout',
                payload: { groupName: user?.groupName },
                socketType: 'socketController'
            })
        );
        dispatch(authActions.logout());
        navbarReducer.toggle('isNavbarExtended');
    }, [dispatch, navbarReducer]);

    return (
        <nav className={classes.navbar}>
            {user && (
                <div className={classes.container}>
                    <UserInfo />
                    <div className={classes.actions}>
                        <IconButton
                            tooltipMessage={navbarReducer.isNavbarExtended ? 'closeMenu' : 'openMenu'}
                            tooltipPosition={TOP_RIGHT_TOOLTIP}
                            className="transparent"
                            onClick={() => navbarReducer.toggle('isNavbarExtended')}
                            icon={navbarReducer.isNavbarExtended ? <Close /> : <Hamburger />}
                        />
                        {navbarReducer.isNavbarExtended && (
                            <>
                                <UserEdit
                                    isOpen={navbarReducer.isEditUserModalOpen}
                                    toggle={() => navbarReducer.toggle('isEditUserModalOpen')}
                                />
                                <ChangeLanguage
                                    isOpen={navbarReducer.isEditLanguageModalOpen}
                                    toggle={() => navbarReducer.toggle('isEditLanguageModalOpen')}
                                />
                                <AdminButtons reducer={navbarReducer} />
                                <IconButton
                                    tooltipMessage={'logout'}
                                    tooltipPosition={RIGHT_TOOLTIP}
                                    className="transparent"
                                    onClick={logout}
                                    icon={<Logout />}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default memo(Navbar);
