import { FC, PropsWithChildren, memo } from 'react';
import { Button } from 'components/buttons';
import { HTMLButtonType, CSSPosition } from 'helpers';

type CollapsibleButtonProps = FC<
    PropsWithChildren<{
        className: string;
        disabled?: boolean;
        icon: JSX.Element;
        onClick: (event?: any) => any;
        type?: HTMLButtonType;
        isCollapsed: boolean;
        tooltipMessage?: string;
        tooltipPosition?: CSSPosition | undefined;
    }>
>;

const CollapsibleButton: CollapsibleButtonProps = ({
    isCollapsed,
    icon,
    className,
    onClick,
    children,
    disabled,
    type,
    tooltipMessage,
    tooltipPosition
}) => {
    return (
        <Button
            isTooltipNecessary={isCollapsed}
            tooltipMessage={tooltipMessage!}
            tooltipPosition={tooltipPosition}
            type={type}
            onClick={onClick}
            className={`${!isCollapsed ? 'text' : 'icon'} ${className}`}
            disabled={disabled}
        >
            {icon}
            {!isCollapsed ? children : null}
        </Button>
    );
};

export default memo(CollapsibleButton);
