import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeoJsonLayer, WebMercatorViewport } from 'deck.gl/typed';
import {
    BUILDING_HEIGHT,
    DEFAULT_ICONCLUSTER_LAYER_VALUE,
    DEFAULT_INITIAL_VIEW_STATE,
    DEFAULT_LAYER_VALUE,
    DEFAULT_TAB_ID,
    featureCollectionFormatter,
    getAvailableColor,
    IconCLusterLayer,
    MapStyleType
} from 'helpers';
import 'helpers/arrayExtension';

interface UpdateViewState {
    lat: number;
    lon: number;
    zoom: number;
    pitch: number;
    bearing: number;
}

export enum LayersActions {
    isEditTabName = 'isEditTabName'
}

export interface LayerState {
    tabs: Tab[];
    layers: any[];
    selectedTab: string;
    isEditTabName: boolean;
}

export interface Tab {
    id: string;
    name: string;
    initialViewState: {
        latitude: number;
        longitude: number;
        zoom: number;
        transitionDuration: number;
        transitionInterpolator: any;
        minZoom: number;
        pitch: number;
        bearing: number;
    };
    mapStyle: MapStyleType;
}

const name = 'layers';
const initialState: LayerState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const layerActions = { ...slice.actions };
export const layerReducer = slice.reducer;

function createInitialState() {
    return {
        isEditTabName: false,
        tabs: [
            {
                id: DEFAULT_TAB_ID,
                name: DEFAULT_TAB_ID,
                initialViewState: { ...DEFAULT_INITIAL_VIEW_STATE },
                mapStyle: 'MAP_STYLE_STREETS'
            }
        ],
        layers: [],
        selectedTab: DEFAULT_TAB_ID
    } as LayerState;
}

function createReducers() {
    return {
        handleOSMBuildings,
        addLayer,
        updateLayer,
        resetLayers,
        getLayerByName,
        toggleVisibilityLayer,
        upsertLayer,
        replaceLayers,
        addGeoJsonLayer,
        addIconClusterLayer,
        deleteLayer,
        updateViewState,
        zoomDispatch,
        createNewTab,
        changeTab,
        updateMapStyle,
        removeTab,
        zoomOnLayer,
        updateLayerName,
        toggleEdit
    };

    function handleOSMBuildings(state: LayerState, action: PayloadAction<any>) {
        const layerIndex = state.layers.getIndexById(BUILDING_HEIGHT);
        if (layerIndex === -1) {
            state.layers.push(
                new GeoJsonLayer({
                    ...action.payload
                })
            );
        } else
            state.layers.splice(
                layerIndex,
                1,
                new GeoJsonLayer({
                    ...action.payload
                })
            );
    }

    function toggleEdit(state: LayerState, action: PayloadAction<LayersActions>) {
        state[action.payload] = !state[action.payload];
    }

    function addLayer(state: LayerState, action: PayloadAction<any>) {
        state.layers.push(action.payload);
    }

    function addGeoJsonLayer(state: LayerState, action: PayloadAction<any>) {
        const Authorization = localStorage.getItem('token');
        zoomOnBbox(state, action.payload.data.bbox, action.payload.tab);
        const layer = new GeoJsonLayer({
            ...DEFAULT_LAYER_VALUE,
            ...action.payload,
            loadOptions: { fetch: { headers: { Authorization } } }
        });
        if (action.payload.isBackground) {
            const index = state.layers.findLastIndex((layer: any) => layer.props.isBackground);
            if (index === -1) state.layers.unshift(layer);
            else state.layers.splice(index + 1, 0, layer);
        } else state.layers.push(layer);
    }

    function addIconClusterLayer(state: LayerState, action: PayloadAction<any>) {
        if (state.layers.getIndexById(action.payload.id) !== -1) return;
        zoomOnBbox(state, action.payload.data.bbox, action.payload.tab ?? DEFAULT_TAB_ID);
        const color = getAvailableColor();
        state.layers.push(
            new IconCLusterLayer({
                ...DEFAULT_ICONCLUSTER_LAYER_VALUE,
                ...action.payload,
                data: featureCollectionFormatter(action.payload.data),
                getInitialColor: color,
                color
            })
        );
    }

    function upsertLayer(state: LayerState, action: PayloadAction<any>) {
        const layerIndex = state.layers.getIndexById(action.payload.id);
        if (layerIndex === -1) state.layers.push(action.payload);
        else state.layers[layerIndex] = action.payload;
    }

    function replaceLayers(state: LayerState, action: PayloadAction<any[]>) {
        state.layers = action.payload;
    }

    function zoomOnLayer(state: LayerState, action: PayloadAction<{ id: string; tab: string }>) {
        const layerIndex = state.layers.getIndexById(action.payload.id);
        if (layerIndex === -1) return;
        state.layers[layerIndex] = state.layers[layerIndex].clone({ visible: true });
        zoomOnBbox(state, state.layers[layerIndex].props.data.bbox, action.payload.tab);
    }

    function toggleVisibilityLayer(state: LayerState, action: PayloadAction<string>) {
        const layerIndex = state.layers.getIndexById(action.payload);
        if (layerIndex === -1) return;
        state.layers[layerIndex] = state.layers[layerIndex].clone({
            visible: !state.layers[layerIndex].props.visible
        });
    }

    function getLayerByName(state: LayerState, action: PayloadAction<{ name: string; callback: (...data: any) => any }>) {
        const layer = state.layers.getItemById(action.payload.name);
        action.payload.callback(layer);
    }

    function updateLayer(state: LayerState, action: PayloadAction<{ id: string; data: any }>) {
        const { id, data } = action.payload;
        const layerIndex = state.layers.getIndexById(id);
        if (layerIndex !== -1) state.layers[layerIndex] = state.layers[layerIndex].clone({ ...data });
    }

    function resetLayers(state: LayerState) {
        const updatedLayers = state.layers.map(layer => {
            const updatedLayer = layer.clone({
                color: layer.props.getInitialColor,
                getPointRadius: 1,
                checked: true,
                getLineColor: layer.props.getInitialColor
            });
            return updatedLayer;
        });
        state.layers = updatedLayers;
    }

    function deleteLayer(state: LayerState, action: PayloadAction<string>) {
        const layerIndex = state.layers.getIndexById(action.payload);
        if (layerIndex === -1) return;
        window.dispatchEvent(new CustomEvent<{ id: string }>('layerDeleted', { detail: { id: action.payload } }));
        state.layers.splice(layerIndex, 1);
    }

    function updateViewState(state: LayerState, action: PayloadAction<UpdateViewState>) {
        const index = getTabIndex(state, state.selectedTab);
        const lat =
            action.payload.lat === state.tabs[index].initialViewState.latitude
                ? action.payload.lat + 0.0000001
                : action.payload.lat;
        state.tabs[index].initialViewState.latitude = lat;
        state.tabs[index].initialViewState.longitude = action.payload.lon;
        const zoom =
            action.payload.zoom === state.tabs[index].initialViewState.zoom
                ? action.payload.zoom + 0.0000001
                : action.payload.zoom;
        state.tabs[index].initialViewState.zoom = zoom;
        state.tabs[index].initialViewState.pitch = action.payload.pitch;
        state.tabs[index].initialViewState.bearing = action.payload.bearing;
    }

    function zoomDispatch(state: LayerState, action: PayloadAction<{ bbox: any; tab: string; zoomOffset: number }>) {
        const { bbox, tab, zoomOffset } = action.payload;
        zoomOnBbox(state, bbox, tab, zoomOffset);
    }

    function createNewTab(state: LayerState, action: PayloadAction<{ tabId: string; tabName: string; geojson: any }>) {
        const color = getAvailableColor();
        state.layers.push(
            new IconCLusterLayer({
                ...DEFAULT_ICONCLUSTER_LAYER_VALUE,
                ...action.payload.geojson,
                data: featureCollectionFormatter(action.payload.geojson.data),
                tab: action.payload.tabId,
                color,
                getInitialColor: color
            })
        );
        state.tabs.push({
            id: action.payload.tabId,
            name: action.payload.tabName,
            initialViewState: { ...DEFAULT_INITIAL_VIEW_STATE },
            mapStyle: 'MAP_STYLE_STREETS'
        });
        zoomOnBbox(state, action.payload.geojson.data.bbox, action.payload.tabId);
    }

    function changeTab(state: LayerState, action: PayloadAction<{ coords: UpdateViewState; tabId: string }>) {
        const currentTab = state.tabs.find(tab => tab.id === state.selectedTab) as Tab;
        currentTab.initialViewState = {
            ...currentTab.initialViewState,
            latitude: action.payload.coords.lat,
            longitude: action.payload.coords.lon,
            zoom: action.payload.coords.zoom,
            pitch: action.payload.coords.pitch,
            bearing: action.payload.coords.bearing
        };
        state.selectedTab = action.payload.tabId;
    }

    function updateMapStyle(state: LayerState, action: PayloadAction<{ tabId: string; mapStyle: MapStyleType }>) {
        const index = getTabIndex(state, action.payload.tabId);
        state.tabs[index].mapStyle = action.payload.mapStyle;
    }

    function removeTab(state: LayerState, action: PayloadAction<string>) {
        const tabIndex = getTabIndex(state, action.payload);
        state.tabs.splice(tabIndex, 1);
        const layerToDelete = state.layers.filter(layer => layer.props.tab === action.payload).map(layer => layer.id);
        layerToDelete.forEach(layerId => deleteLayer(state, { type: 'layer/deleteLayer', payload: layerId }));
        state.selectedTab = DEFAULT_TAB_ID;
    }

    function zoomOnBbox(state: LayerState, bbox: any, tabId: string, zoomOffset = 10) {
        if (!bbox || (bbox.coordinates && bbox.coordinates[0][0][0] === Infinity)) return;
        const viewport = new WebMercatorViewport();
        const index = getTabIndex(state, tabId);
        let newViewport;
        try {
            newViewport = viewport.fitBounds(
                [
                    [bbox.coordinates[0][0][0], bbox.coordinates[0][0][1]],
                    [bbox.coordinates[0][2][0], bbox.coordinates[0][2][1]]
                ],
                { maxZoom: 20 }
            );
        } catch {
            newViewport = viewport.fitBounds(
                [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[3]]
                ],
                { maxZoom: 20 }
            );
        }
        state.tabs[index].initialViewState.latitude =
            newViewport.latitude === state.tabs[index].initialViewState.latitude
                ? newViewport.latitude + 0.000001
                : newViewport.latitude;
        state.tabs[index].initialViewState.longitude = newViewport.longitude;
        state.tabs[index].initialViewState.zoom = newViewport.zoom + zoomOffset;
    }

    function getTabIndex(state: LayerState, tabId: string) {
        return state.tabs.findIndex(tab => tab.id === tabId);
    }

    function updateLayerName(state: LayerState, action: PayloadAction<{ tabId: string; newTabName: string }>) {
        const tabIndex = state.tabs.findIndex(tab => tab.id === action.payload.tabId);
        const layerIndex = state.layers.findIndex(layer => layer.props.tab === action.payload.tabId);
        if (layerIndex === -1 || tabIndex === -1) return;
        state.tabs[tabIndex].name = action.payload.newTabName;
        state.layers[layerIndex].props.data.name = action.payload.newTabName;
        state.layers[layerIndex].props.data.fileName = action.payload.newTabName;
    }
}
