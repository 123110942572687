import { useLocation, useNavigate } from 'react-router-dom';
import 'styles/main.scss';
import { history } from 'helpers';
import Routing from './Routing';
import Navbar from 'views/navbar/Navbar';
import { Suspense, useEffect, useContext } from 'react';
import Spinner from 'components/spinner/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RootState, socketActions } from 'store';
import { DockerContext } from './context/dockerContext';
import { useMount } from './hook';
import Notice from 'components/notice/Notice';

const App = () => {
    history.navigate = useNavigate();
    history.location = useLocation();
    const { startContainer, resetContainerUp } = useContext(DockerContext);
    const { user, groupName } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (!groupName || groupName === undefined) return resetContainerUp();
            await startContainer(groupName);
        })();
    }, [groupName, startContainer, resetContainerUp]);

    useEffect(() => {
        dispatch(
            socketActions.login({
                email: user?.email as string,
                groupName: groupName as string
            })
        );
    }, [dispatch, groupName, user?.email]);

    useMount(() => {
        window.addEventListener('socket_reconnection', () => {
            dispatch(
                socketActions.login({
                    email: user?.email as string,
                    groupName: groupName as string
                })
            );
        });
    });

    return (
        <>
            <Notice />
            <Suspense fallback={<Spinner />}>
                <Navbar />
                <Routing />
            </Suspense>
        </>
    );
};

export default App;

