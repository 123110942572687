import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RenameAreaRequest } from 'context';
import { FeatureCollection, FeatureCollectionProject } from 'models';

export interface AreasState {
    areas: FeatureCollectionProject[];
    areaToEdit: FeatureCollection;
    foundAreas: FeatureCollection[];
    isBackofficeShareModalOpen: boolean;
    isAreasModalOpen: boolean;
    selectedAreas: string[];
    areaUpdate: RenameAreaRequest;
}

export enum AreasActions {
    isBackofficeShareModalOpen = 'isBackofficeShareModalOpen',
    isAreasModalOpen = 'isAreasModalOpen'
}

const name = 'areas';
const initialState: AreasState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const areasActions = { ...slice.actions };
export const areasReducer = slice.reducer;

function createInitialState() {
    return {
        isBackofficeShareModalOpen: false,
        areas: [],
        areaToEdit: {} as FeatureCollection,
        foundAreas: [],
        isAreasModalOpen: false,
        selectedAreas: [],
        areaUpdate: {} as RenameAreaRequest
    } as AreasState;
}

function createReducers() {
    return {
        clearAreas,
        toggleModal,
        setItems,
        initializeAreas,
        deleteArea,
        renameArea,
        setAreaUpdate
    };

    function setAreaUpdate(state: AreasState, action: PayloadAction<any>) {
        state.areaUpdate = action.payload;
    }

    function clearAreas(state: AreasState) {
        state.areaToEdit = {} as FeatureCollection;
        state.selectedAreas = [];
    }

    function toggleModal(state: AreasState, action: PayloadAction<AreasActions>) {
        state[action.payload] = !state[action.payload];
    }

    function setItems(state: AreasState, action: PayloadAction<{ key: keyof AreasState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function initializeAreas(state: AreasState, action: PayloadAction<{ areas: FeatureCollectionProject[]; userId: string }>) {
        state.areas = action.payload.areas.map(area => ({
            ...area,
            projectName: area.projectName?.length ? area.projectName : 'noProjects'
        }));
    }

    function deleteArea(state: AreasState, action: PayloadAction<string>) {
        state.areas = state.areas.filter(area => action.payload !== area.id);
    }

    function renameArea(state: AreasState, action: PayloadAction<{ id: string; newName: string }>) {
        const areaName = state.areas.find(area => area.id === action.payload.id);
        if (!areaName) return;
        areaName.name = action.payload.newName;
    }
}
