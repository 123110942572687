import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Person: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32.814" height="39" viewBox="0 0 32.814 39">
            <path
                id="person"
                d="M0,38a15.907,15.907,0,0,1,31.814,0ZM5.907,10a10,10,0,1,1,10,10A10,10,0,0,1,5.907,10Z"
                transform="translate(0.5 0.5)"
                fill={fill}
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
            />
        </svg>
    );
};
