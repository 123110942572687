import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { AxiosContext } from './axiosContext';
import { env } from 'environments';

export type GeoserverOutputFormat = 'SHAPE-ZIP' | 'csv' | 'excel2007';
export type ExportType = 'create-shape' | 'create-csv' | 'create-excel';

export const ExportTypeExtensions: Record<GeoserverOutputFormat, string> = {
    'SHAPE-ZIP': '.zip',
    csv: '.csv',
    excel2007: '.xlsx'
};

export const ExportTypes: Record<ExportType, GeoserverOutputFormat> = {
    'create-shape': 'SHAPE-ZIP',
    'create-csv': 'csv',
    'create-excel': 'excel2007'
};

interface ExportFileApi {
    downloadFile: (shapeId: string, notificationId?: string) => Promise<any>;
    downloadFileFromGeoserver: (
        collectionName: string,
        workspace: string,
        layerName: string,
        outputFormat: GeoserverOutputFormat,
        cql_filter: string
    ) => Promise<any>;
    createFile: (items: any[], shapeName: string, exportType: string) => Promise<string>;
    uploadFile: (file: File) => Promise<any>;
}

const ExportFileContext = createContext<ExportFileApi>({} as ExportFileApi);

const ExportFileProvider: FC<PropsWithChildren> = ({ children }) => {
    const { postRequest, default: axios } = useContext(AxiosContext);

    const downloadFile = useCallback(
        async (fileId: string, notificationId = 'none') => {
            const res: any = await axios.get(`/export-files/download/${fileId}/${notificationId}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return res;
        },
        [axios]
    );

    const downloadFileFromGeoserver = useCallback(
        async (
            collectionName: string,
            workspace: string,
            layerName: string,
            outputFormat: GeoserverOutputFormat,
            cql_filter: string
        ) => {
            const res: any = await axios.get(`${env.reactAppSocketUrl}/geoserver/${workspace}/ows`, {
                responseType: 'arraybuffer',
                params: {
                    service: 'WFS',
                    version: '1.0.0',
                    request: 'GetFeature',
                    typeName: `${workspace}:${layerName}`,
                    collectionName,
                    cql_filter,
                    outputFormat
                }
            });
            return res;
        },
        [axios]
    );

    const createFile = useCallback(
        async (items: any[], fileName: string, exportType: string) => {
            const { data } = await postRequest(`/export-files/${exportType}`, {
                data: items,
                fileName
            });
            return data?.message;
        },
        [postRequest]
    );

    const uploadFile = useCallback(
        async (file: File) => {
            const form = new FormData();
            form.append('file', file);
            const { data } = await postRequest('/project-features/file-upload', form);
            return data;
        },
        [postRequest]
    );

    return (
        <ExportFileContext.Provider value={{ createFile, downloadFile, downloadFileFromGeoserver, uploadFile }}>
            {children}
        </ExportFileContext.Provider>
    );
};

export { ExportFileContext, ExportFileProvider };
