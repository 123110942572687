export const NotifyRename = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <defs>
                <clipPath id="notifyRenameClipPath">
                    <rect id="Rectangle_1749" data-name="Rectangle 1749" width="11.206" height="10.279" fill="none" />
                </clipPath>
            </defs>
            <g id="notifica_download" transform="translate(-174.543 -735)">
                <rect
                    id="Rectangle_1688"
                    data-name="Rectangle 1688"
                    width="16"
                    height="16"
                    rx="6"
                    transform="translate(174.543 735)"
                    fill="#16a3cf"
                />
                <g id="download_-_full" data-name="download - full" transform="translate(176.94 737.86)">
                    <g id="Group_578" data-name="Group 578" transform="translate(0 0)" clipPath="url(#notifyRenameClipPath)">
                        <path
                            id="Path_1366"
                            data-name="Path 1366"
                            d="M11.189,5.521h-.008A2.545,2.545,0,0,0,9.575,3.43,3.588,3.588,0,0,0,6.011,0,3.545,3.545,0,0,0,2.627,2.434H2.62A2.569,2.569,0,0,0,.9,3.1,2.715,2.715,0,0,0,0,5.113v.9A1.124,1.124,0,0,0,1.123,7.133H5.1V2.993h1.01v4.14h3.974a1.124,1.124,0,0,0,1.123-1.123V5.6Z"
                            transform="translate(0 0)"
                            fill="#fff"
                        />
                        <path
                            id="Path_1367"
                            data-name="Path 1367"
                            d="M11.783,23.648l-1.322-.88-.559.839,2.1,1.4a.491.491,0,0,0,.28.086.5.5,0,0,0,.279-.085l2.107-1.4-.559-.839-1.322.88v-1.7h-1.01Z"
                            transform="translate(-6.685 -14.816)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
