import { createContext, FC, PropsWithChildren, ReactElement, useCallback, useContext, useState } from 'react';
import { TextButton } from 'components/buttons';
import { AxiosContext } from './axiosContext';
import Modal from 'components/modal/Modal';
import { FavoritePolygon, FeatureCollection, SharableUser } from 'models';
import { Geometry } from 'helpers';
import { useDispatch } from 'react-redux';
import { noticesActions } from 'store';

interface FavoritePolygonsApi {
    getFavoritesByUser: () => Promise<FavoritePolygon[]>;
    getSharableUsers: () => Promise<SharableUser[]>;
    addFavorite: (favorite: AddFavoriteReqBody) => Promise<FavoritePolygon>;
    shareFavorite: (favorite: string, emails: string[]) => Promise<{ usersNotInvited: string[]; message: string }>;
    acceptShareFavorite: (favoriteId: string, notificationId?: string) => Promise<string>;
    rejectShareFavorite: (notificationId: string) => Promise<void>;
    updateFavorite: (id: string, name: string) => Promise<FavoritePolygon>;
    deleteFavorites: (favoritesId: string[]) => Promise<string>;
    getFavoritesByPolygon: (polygon: Geometry) => Promise<FeatureCollection>;
}

interface AddFavoriteReqBody {
    name: string;
    geometry: Geometry;
}

const FavoritePolygonsContext = createContext<FavoritePolygonsApi>({} as FavoritePolygonsApi);

const FavoritePolygonsProvider: FC<PropsWithChildren> = ({ children }) => {
    const { getRequest, postRequest, putRequest, deleteRequest } = useContext(AxiosContext);
    const [error, setError] = useState<ReactElement | undefined>();
    const dispatch = useDispatch();

    const getFavoritesByUser = useCallback(async () => {
        const { data } = await getRequest('/favorite-polygons/user');
        return data;
    }, [getRequest]);

    const getSharableUsers = useCallback(async () => {
        const { data } = await getRequest('/favorite-polygons/sharable-users');
        return data;
    }, [getRequest]);

    const addFavorite = useCallback(
        async (favoriteCreation: AddFavoriteReqBody) => {
            const { data } = await postRequest('/favorite-polygons', { favoriteCreation });
            dispatch(noticesActions.setSuccess(data?.message));
            return data?.favorite;
        },
        [postRequest, dispatch]
    );

    const shareFavorite = useCallback(
        async (favoriteId: string, emails: string[]) => {
            const { data } = await postRequest('/favorite-polygons/share', { favoriteId, emails });

            if (data?.usersNotInvited?.length) {
                const list = data?.usersNotInvited.map((email: string) => <p key={email}>{email}</p>);
                setError(<div>Utenti non invitati{list}</div>);
            }
            dispatch(noticesActions.setSuccess(data?.message));
            return data;
        },
        [postRequest, dispatch]
    );

    const acceptShareFavorite = useCallback(
        async (favoriteId: string, notificationId?: string) => {
            const { data } = await postRequest('/favorite-polygons/accept', { favoriteId, notificationId });
            dispatch(noticesActions.setSuccess(data?.message));
            return data?.message;
        },
        [postRequest, dispatch]
    );

    const rejectShareFavorite = useCallback(
        async (notificationId: string) => {
            const { data } = await postRequest('/favorite-polygons/reject', { notificationId });
            dispatch(noticesActions.setSuccess(data?.message));
        },
        [postRequest, dispatch]
    );

    const updateFavorite = useCallback(
        async (id: string, name: string) => {
            const { data } = await putRequest('/favorite-polygons', { id, name });
            dispatch(noticesActions.setSuccess(data?.message));
            return data?.favorite;
        },
        [putRequest, dispatch]
    );

    const deleteFavorites = useCallback(
        async (favoritesId: string[]) => {
            const { data } = await deleteRequest('favorite-polygons', { favoritesId });
            dispatch(noticesActions.setSuccess(data?.message));
            return data?.message;
        },
        [deleteRequest, dispatch]
    );

    const getFavoritesByPolygon = useCallback(
        async (polygon: Geometry) => {
            const { data } = await getRequest('/project-features/by-polygon-area', { polygon: JSON.stringify(polygon) });
            return data;
        },
        [getRequest]
    );

    return (
        <FavoritePolygonsContext.Provider
            value={{
                getFavoritesByUser,
                addFavorite,
                updateFavorite,
                deleteFavorites,
                getFavoritesByPolygon,
                getSharableUsers,
                shareFavorite,
                acceptShareFavorite,
                rejectShareFavorite
            }}
        >
            {children}
            {error && (
                <Modal isOpen={!!error} handleClose={() => setError(undefined)} title="">
                    {error}
                    <TextButton className="dark" onClick={() => setError(undefined)}>
                        OK
                    </TextButton>
                </Modal>
            )}
        </FavoritePolygonsContext.Provider>
    );
};
export { FavoritePolygonsContext, FavoritePolygonsProvider };
