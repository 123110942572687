import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Color: SvgIconProps = ({ fill = colors.gray900 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="colors" transform="translate(-0.002 0)">
                <g id="Group_326" data-name="Group 326" transform="translate(0.002 0)">
                    <path
                        id="Path_753"
                        data-name="Path 753"
                        d="M7.788.458A10.788,10.788,0,0,0,.356,8.175,11.026,11.026,0,0,0,11,22a.6.6,0,0,0,.553-.371.6.6,0,0,0-.13-.654,2.822,2.822,0,0,1,.829-4.589,2.733,2.733,0,0,1,1.171-.234h3.607a4.93,4.93,0,0,0,4.957-4.637A11.029,11.029,0,0,0,7.788.458Zm13,11.031a3.791,3.791,0,0,1-3.75,3.46H13.463a4.013,4.013,0,0,0-3.672,5.772A9.829,9.829,0,0,1,1.625,8.088a9.546,9.546,0,0,1,6.42-6.452A9.83,9.83,0,0,1,20.79,11.489Z"
                        transform="translate(-0.002 0)"
                        fill={fill}
                    />
                    <ellipse
                        id="Ellipse_10"
                        data-name="Ellipse 10"
                        cx="1.6"
                        cy="1.6"
                        rx="1.6"
                        ry="1.6"
                        transform="translate(15.88 9.398)"
                        fill={fill}
                    />
                    <path
                        id="Path_758"
                        data-name="Path 758"
                        d="M1.6,0A1.6,1.6,0,1,1,0,1.6,1.6,1.6,0,0,1,1.6,0Z"
                        transform="translate(2.591 11.864) rotate(-67.5)"
                        fill={fill}
                    />
                    <path
                        id="Path_754"
                        data-name="Path 754"
                        d="M45.8,15.458a1.6,1.6,0,1,0,0,2.263A1.6,1.6,0,0,0,45.8,15.458Z"
                        transform="translate(-29.063 -10.115)"
                        fill={fill}
                    />
                    <path
                        id="Path_759"
                        data-name="Path 759"
                        d="M1.6,0A1.6,1.6,0,1,1,0,1.6,1.6,1.6,0,0,1,1.6,0Z"
                        transform="translate(8.819 4.598) rotate(-45)"
                        fill={fill}
                    />
                    <path
                        id="Path_755"
                        data-name="Path 755"
                        d="M15.708,15.458a1.6,1.6,0,1,0,2.264,0A1.6,1.6,0,0,0,15.708,15.458Z"
                        transform="translate(-10.284 -10.115)"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
};
